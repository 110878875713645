export enum BeneficialOwnerStep {
  IntetionWithGoods,
  CompanyDetails,
  OtherDetails,
  VatNumber,
  Ownership,
  CompanyConfirm,
  CompanySearch,
  NewCompany,
}
