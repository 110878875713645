import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AddPartsResponseItem } from '@tecex-api/data';
import { DialogRef } from '../../../dialog/dialog-ref';
import { DialogService } from '../../../dialog/dialog.service';
import { DialogShowMode } from '../../../dialog/enums/dialog-show-mode.enum';
import { AuthService } from '../../../../services/auth.service';
import { RePollService } from '../../../../../../../projects/tecex/src/app/shared/services/re-poll.service';
import { LoadingIndicatorService } from '../../../loading-indicator/services/loading-indicator.service';
import { SerialNumbersDialogPayload } from './serial-numbers-dialog-payload.interface';
import { SerialNumbersDialogComponent } from './serial-numbers-dialog.component';

@Injectable()
export class SerialNumbersDialogService {
  constructor(
    private readonly injector: Injector,
    private readonly dialogService: DialogService,
    private readonly authService: AuthService,
    private readonly rePollService: RePollService,
    private readonly loadingIndicatorService: LoadingIndicatorService
  ) {}

  public open(payload: SerialNumbersDialogPayload): DialogRef<any> {
    return this.dialogService.open<SerialNumbersDialogPayload, boolean>(SerialNumbersDialogComponent, payload, {
      showMode: DialogShowMode.Side,
      closeButton: false,
      closeOnNavigation: true,
      width: '800px',
      injector: this.injector,
    });
  }

  public updateParts$(
    parts: { id: string; name: string; serialNumber: string; description: string; quantity: number; unitPrice: number }[],
    soId
  ): Observable<AddPartsResponseItem[]> {
    this.loadingIndicatorService.open();
    return this.authService.getUser$().pipe(
      switchMap((user) => {
        const mappedParts = parts.map((part) => {
          return {
            partId: part.id,
            SerialNumber: part.serialNumber,
            PartDescription: part.description,
            PartNumber: part.name,
            Quantity: part.quantity,
            UnitPrice: part.unitPrice,
          };
        });
        return this.rePollService.updatePartsRetry$({
          accesstoken: user.accessToken,
          accountId: user.accountId,
          parts: mappedParts,
          shipmentOrderId: soId,
        });
      })
    );
  }
}
