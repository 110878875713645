import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgIf } from '@angular/common';
import { BREAKPOINTS } from '../../constants/breakpoints.constants';

export type Breakpoint = keyof typeof BREAKPOINTS;

/**
 * Renders content only when the query is matched.
 * You can supply a media query or array of media queries
 * ```
 * <app-breakpoint query="('min-width: 600px)">Content</app-breakpoint>
 * ```
 * or use one of the named Angular Material breakpoints
 * ```
 * <app-breakpoint query="Medium">Content</app-breakpoint>
 * ```
 */
@Component({
  selector: 'app-breakpoint',
  templateUrl: './breakpoint.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf],
})
export class BreakpointComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly breakpointObserver: BreakpointObserver;
  public matches = false;
  @Input() public query: Breakpoint | string | Array<string>;

  constructor(
    private readonly ref: ChangeDetectorRef,
    breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver = breakpointObserver;
  }

  public ngOnInit(): void {
    this.startObserver();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private startObserver(): void {
    this.breakpointObserver
      .observe(BREAKPOINTS?.[this.query as Breakpoint] || this.query)
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.onBreakPointChange.bind(this));
  }

  private onBreakPointChange(result: BreakpointState): void {
    this.matches = result.matches;
    this.ref.markForCheck();
  }
}
