import { Injectable } from '@angular/core';
import { Buyer, BuyersDataService } from '@tecex-api/data';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { InputDataVM } from '../../../../interfaces/input-data.vm';
import { AuthService } from '../../../../services/auth.service';
import { ErrorNotificationService } from '../../../../services/error-notification.service';
import { LoadingIndicatorService } from '../../../loading-indicator/services/loading-indicator.service';
import { BuyerAccountVM } from './buyer-account.vm';
import { mapBuyersListResponse, mapBuyersResponse } from './map-buyers-response.helper';

@Injectable()
export class BuyerService {
  private readonly buyers$: Observable<InputDataVM<string, string>[]> = this.authService.getUser$().pipe(
    switchMap((user) =>
      this.buyersDataService
        .getBuyers({
          Accesstoken: user.accessToken,
          AccountID: user.accountId,
          searchaccountName: '',
        })
        .pipe(
          catchError((error) => {
            this.errorNotificationService.notifyAboutError(error, 'ERROR.FAILED_TO_LOAD_BUYERS');

            return of([]);
          })
        )
    ),
    map(mapBuyersResponse)
  );

  private readonly buyersList$: Observable<BuyerAccountVM[]> = this.authService.getUser$().pipe(
    switchMap((user) =>
      this.buyersDataService
        .getBuyers({
          Accesstoken: user.accessToken,
          AccountID: user.accountId,
          searchaccountName: '',
        })
        .pipe(
          catchError((error) => {
            this.errorNotificationService.notifyAboutError(error, 'ERROR.FAILED_TO_LOAD_BUYERS');

            return of([]);
          })
        )
    ),
    map(mapBuyersListResponse)
  );

  constructor(
    private readonly authService: AuthService,
    private readonly buyersDataService: BuyersDataService,
    private readonly errorNotificationService: ErrorNotificationService,
    private readonly loadingIndicatorService: LoadingIndicatorService
  ) {}

  public getBuyers$(): Observable<InputDataVM<string, string>[]> {
    return this.buyers$;
  }

  public getBuyersList$(): Observable<BuyerAccountVM[]> {
    return this.buyersList$;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity,@typescript-eslint/explicit-module-boundary-types
  public createBuyer$(values: any): Observable<Buyer> {
    return this.authService.getUser$().pipe(
      // eslint-disable-next-line sonarjs/cognitive-complexity
      switchMap((user) =>
        this.buyersDataService
          .createBuyer({
            Accesstoken: user.accessToken,
            AccountID: user.accountId,
            ClientBuyeraccountName: values.name,
            Country: values.country.value,
            StreetAddress: values.streetAddress,
            City: values.city,
            PostalCode: values.postalCode,
            NLBuyer: true,
          })
          .pipe(map((response) => response))
      )
    );
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity,@typescript-eslint/explicit-module-boundary-types
  public updateBuyer$(values: any, vatNumber: string): any {
    return this.authService.getUser$().pipe(
      switchMap((user) =>
        this.buyersDataService.updateBuyer({
          Accesstoken: user.accessToken,
          AccountID: values.id,
          Country: values.country,
          StreetAddress: values.address,
          city: values.city,
          PostalCode: values.postalCode,
          VATNumber: vatNumber ? vatNumber : '',
          NLBuyer: true,
        })
      )
    );
  }
}
