import { sortByDate } from '@global/helpers/sort-by-date.helper';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from 'app/reducers';
import * as fromThirdParty from './third-party.reducer';

export const thirdPartyFeatureKey = 'third-party';

export interface ThirdPartyState {
  [fromThirdParty.thirdPartyKey]: fromThirdParty.State;
}

export interface AppState extends State {
  [thirdPartyFeatureKey]: ThirdPartyState;
}

export const reducers: ActionReducerMap<ThirdPartyState> = {
  [fromThirdParty.thirdPartyKey]: fromThirdParty.reducer,
};

export const selectFeatureThirdPartyState = createFeatureSelector<ThirdPartyState>(thirdPartyFeatureKey);

export const selectThirdPartyState = createSelector(selectFeatureThirdPartyState, (state) => state[thirdPartyFeatureKey]);

export const selectAreMessagesLoading = createSelector(selectThirdPartyState, (state) => state.messageThreads.isLoading);

export const selectMessageThreads = createSelector(selectThirdPartyState, (state) => state.messageThreads.data);
export const selectSortedMessageThreads = createSelector(selectMessageThreads, (messageThreads) =>
  [...messageThreads].sort((a, b) => sortByDate(b.lastMessage, a.lastMessage))
);
export const selectMessageThreadCount = createSelector(selectMessageThreads, (messageThreads) => messageThreads.length);

export const selectAreTasksLoading = createSelector(selectThirdPartyState, (state) => state.tasks.isLoading);
export const selectTasks = createSelector(selectThirdPartyState, (state) => state.tasks.data);
export const selectOutstandingTasksCount = createSelector(selectTasks, (tasks) => tasks.length);

export const selectSelectedShipmentId = createSelector(selectThirdPartyState, (state) => state.selectedShipmentId);
export const selectShipmentAccountId = createSelector(selectThirdPartyState, (state) => state.selectSOsAccountId);
export const selectTaskAccountId = createSelector(selectThirdPartyState, (state) => state.selectedTaskShipmentAccountId);
export const selectSelectedShipmentTab = createSelector(selectThirdPartyState, (state) => state.selectedTab);

export const selectShipment = createSelector(selectThirdPartyState, (state) => state.shipment.data);
export const selectShipmentLoading = createSelector(selectThirdPartyState, (state) => state.shipment.isLoading);
export const selectShipmentContacts = createSelector(selectShipment, (shipment) =>
  shipment.contacts.map((contact) => ({ value: contact.id, viewValue: contact.name }))
);

export const selectSendbirdUnreadChannelsMessagesLoading = createSelector(
  selectThirdPartyState,
  (state) => state.SendbirdUnreadChannels.isLoading
);

export const selectSendbirdUnreadChannels = createSelector(selectThirdPartyState, (state) => state.SendbirdUnreadChannels?.data);

export const selectSelectedShipmentTasks = createSelector(selectThirdPartyState, (state) => state.selectedTasks);

// For getting the compliance and tracking counts : Start
export const selectCountOfComplianceAndTracking = createSelector(selectThirdPartyState, (state) => state.countOfComplianceAndTracking.data);
export const selectCountOfComplianceAndTrackingLoading = createSelector(
  selectThirdPartyState,
  (state) => state.countOfComplianceAndTracking.isLoading
);
// For getting the compliance and tracking counts : End

// For getting the total unread count of all messages : Start
export const selectCountOfUnreadAllMessages = createSelector(selectThirdPartyState, (state) => state.countOfAllMessages.data);
// For getting the total unread count of all messages : End

export const selectSendbirdUnreadChannelsForFlagChange = createSelector(
  selectThirdPartyState,
  (state) => state.SendbirdUnreadChannels?.data
);
