import { ValidatorFn } from '@angular/forms';
import { convertTimeString } from '../helpers/time.helper';

export const validateWorkHours: () => ValidatorFn =
  () =>
  ({ value }) => {
    if (value) {
      const time: [number, number] = convertTimeString(value);
      const decimalTime = time[0] + time[1] / 60;
      if (decimalTime < 8 || decimalTime > 17) {
        return { nonWorkHour: true };
      }
    }
    return null;
  };
