/**
 * Rounds a float number to specified number of digits after decimal point
 * digits should be at least 1 otherwise number will be returned as is
 *
 * @param num - number to be rounded
 * @param digits - number of digits after decimal point
 */
export const roundDecimal = (num: number, digits = 2): number => {
  if (digits < 1) {
    return num;
  }
  // tslint:disable-next-line: no-magic-numbers
  const factor = Math.pow(10, digits);
  return Math.round((num + Number.EPSILON) * factor) / factor;
};

export const replaceItem =
  <T>(predicate: (item: T) => boolean, replacement: T): ((item: T) => T) =>
  (item: T): T =>
    predicate(item) ? replacement : item;

export const isSameDay = (dateA: Date, dateB: Date): boolean =>
  dateA.getFullYear() === dateB.getFullYear() && dateA.getMonth() === dateB.getMonth() && dateA.getDate() === dateB.getDate();

export const isToday = (date: Date): boolean => {
  const today = new Date();

  return isSameDay(date, today);
};

export const isYesterday = (date: Date): boolean => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  return isSameDay(date, yesterday);
};

export const getWeekNumber = (date: Date): number => {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1).getTime();
  const currentDay = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
  const dayOfYear = (currentDay - firstDayOfYear + 86_400_000) / 86_400_000; // tslint:disable-line: no-magic-numbers
  return Math.ceil(dayOfYear / 7); // tslint:disable-line: no-magic-numbers
};

export const isSameWeek = (date: Date): boolean => {
  const today = new Date();
  return getWeekNumber(date) === getWeekNumber(today);
};

export const loadScript = (url: string): Promise<void> =>
  new Promise((resolve, reject) => {
    const st = document.createElement('script');
    st.addEventListener('load', () => resolve());
    st.addEventListener('error', () => reject());
    st.async = true;
    st.defer = true;
    st.src = url;
    document.body.append(st);
  });

export const isWithinDaysLimit = (date: Date): boolean => {
  const today = new Date();
  const daysBetween = Math.floor(
    (Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) - Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) /
      (1000 * 60 * 60 * 24)
  );
  return daysBetween < 15 ? true : false;
};
