import { Injectable } from '@angular/core';
import { ThirdPartyDialogPayload, ThirdPartyDialogResult } from '../../../interfaces/third-party-dialog.vm';
import { DialogRef } from '../../dialog/dialog-ref';
import { DialogService } from '../../dialog/dialog.service';
import { ThirdPartyDialogComponent } from '../components/third-party-dialog/third-party-dialog.component';

@Injectable()
export class ThirdPartyDialogService {
  constructor(private readonly dialogService: DialogService) {}

  public open(dialogData?: ThirdPartyDialogPayload): DialogRef<ThirdPartyDialogResult> {
    return this.dialogService.open<ThirdPartyDialogPayload, ThirdPartyDialogResult>(ThirdPartyDialogComponent, dialogData);
  }
}
