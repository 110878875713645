import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, NgZone, TemplateRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { ArrowPosition, TooltipData } from './tooltip-data.interface';
import { TooltipRef } from './tooltip-ref';
import { TOOLTIP_DATA } from './tooltip.tokens';

@Component({
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf, MatButtonModule, SvgIconComponent, NgTemplateOutlet],
})
export class TooltipComponent {
  public arrowPosition?: ArrowPosition;

  @HostBinding('class') public get colorClass(): string {
    if (!this.tooltipRef.color) {
      return undefined;
    }

    return this.tooltipRef.color;
  }

  constructor(
    @Inject(TOOLTIP_DATA) public data: TooltipData,
    private readonly tooltipRef: TooltipRef,
    private readonly cdr: ChangeDetectorRef,
    private readonly zone: NgZone
  ) {
    this.data.arrowPosition$.pipe(takeUntil(tooltipRef.afterClosed$())).subscribe((arrowPosition) => {
      this.zone.run(() => {
        this.arrowPosition = arrowPosition;
        this.cdr.markForCheck();
      });
    });
  }

  public get arrowClass(): string {
    if (!this.arrowPosition) {
      return '';
    }

    return this.data.tooltip === 'You do not have permission to create a quote.'
      ? `horizontal-end vertical-${this.arrowPosition.verticalPosition}`
      : `vertical-${this.arrowPosition.verticalPosition} horizontal-${this.arrowPosition.horizontalPosition}`;
  }

  public isTemplate(tooltip: string | TemplateRef<any>): tooltip is TemplateRef<any> {
    return tooltip instanceof TemplateRef;
  }

  public onCloseClicked(): void {
    this.tooltipRef.close();
  }
}
