<!--  HEADER -->
<div class="header">
  <div class="mb-2">
    <app-sendbird-subject
      [sendbirdchannel]="sendbirdchannel?.subject"
      [subjectControl]="subjectControl"
      [editable]="!sendbirdchannel || sendbirdchannel?.Subject !== undefined || sendbirdchannel?.name === '-= DRAFT =-'"
      [shipment]="messageThread?.shipment ? messageThread?.shipment : sendBirdChannelInfo"
      [recordType]="messageThread?.recordType"
      [editableSubjectValue]="flagForSubjectEnableDisable"
    ></app-sendbird-subject>
  </div>
  <div class="participants-container d-flex align-items-center">
    <img class="profile-picture picture mr-2" [src]="messageThread?.photoUrl | profilePicture" />
    <!-- TODO: Based on Zeplin, there should be a name here which is not coming from the participant list
     but from somewhere else: https://zpl.io/bJyqRd6 -->
    <div class="text-reduced-bold color-text-primary mr-2">
      {{
        sendbirdparticiapant && sendbirdparticiapant.length > 0
          ? (sendbirdparticiapant | sendbirdMessageThreadParticipants)
          : (allParticipants | messageThreadParticipants)
      }}
    </div>
    <a
      [matMenuTriggerFor]="menu"
      class="participants text-link sm-dark font-weight-bold mr-2"
      (click)="clickForEditParticipants()"
      *ngIf="!isStandbyUser"
      [class.disabled]="!isUserJoined"
    >
      {{ 'MESSAGES.EDIT_PARTICIPANTS' | translate }}
    </a>
    <a class="participants text-link sm-dark font-weight-bold mr-2" (click)="clickAddMyself()" *ngIf="isStandbyUser && !isUserJoined">
      {{ 'MESSAGES.ADD_MYSELF' | translate }}
    </a>
    <svg-icon
      *ngIf="!isUserJoined && !isStandbyUser"
      class="text-link md-dark font-weight-bold"
      key="info"
      appTooltipStrategy="hover"
      [appTooltip]="'MESSAGES.EDIT_TOOLTIP' | translate"
    ></svg-icon>
    <svg-icon
      *ngIf="!isUserJoined && isStandbyUser"
      class="text-link md-dark font-weight-bold"
      key="info"
      appTooltipStrategy="hover"
      [appTooltip]="'MESSAGES.STANDBY_USER_TOOLTIP' | translate"
    ></svg-icon>

    <mat-menu
      #menu="matMenu"
      (close)="onParticipantMenuClose()"
      xPosition="before"
      backdropClass="context-menu_backdrop"
      class="mat-menu-custom-changes"
    >
      <div
        (click)="fireEventForStopPropagation($event)"
        *ngIf="
          ((isLoadingForAccountParticipants$ | async) ||
            (isLoadingForshipmentOrderParticipants$ | async) ||
            (loadingStateForChannelInfoDetail$ | async)) === false;
          else loading
        "
      >
        <app-sendbird-message-participant-list
          [teamMembers]="teamMembers?.taggable"
          [participants]="allParticipants"
          [taggedRoles]="taggedRoles"
          [title]="'MESSAGES.ADD_PARTICIPANTS'"
          [sendbirdParticipants]="sendbirdparticiapant"
          [messageDialogDetail]="payloadOfDialog"
          [guestUserDisableFlag]="guestUserDisableViaFlag"
          [messageThreadDetail]="messageThread"
          [lengthOfMessageForUpdateParticipants]="lengthOfMessageWhichSend"
          [messageChannelType]="messageChannelType"
          (add)="onParticipantAdd($event)"
          (remove)="onParticipantRemove($event)"
          (taggedTeamMembersList)="onTaggeedTeamMemberChanges($event)"
          (guestUsersList)="onGuestUsersChanges($event)"
          (listOfACCUsers)="onListOfACCUsers($event)"
          (guestUserValueChange)="getGuestUserValue($event)"
          (saveButtonFlagChanges)="getSaveButtonFlagValue($event)"
        ></app-sendbird-message-participant-list>
        <div class="request-cancel-btn-wrapper">
          <div class="d-flex align-items-center justify-content-end mt-4">
            <button mat-button type="button" appDialogClose class="secondary-button mr-2" (click)="cancelButtonClick()">Cancel</button>
            <div class="d-inline-block" *ngIf="user$ | async as user">
              <ng-container *ngIf="caseCreatedFlag || sendbirdchannel?.id || sendbirdchannel?.url; else createMessageChannelCase">
                <button
                  mat-button
                  type="button"
                  class="primary-button"
                  [disabled]="!listOfTaggedTeamMembers.length || !saveButtonEnableDisableFlagValue"
                  (click)="
                    caseCreatedFlag || sendbirdchannel?.url?.split('-').length === 1 || sendbirdchannel?.id
                      ? updateChannel(user, sendbirdchannel?.url?.split('-')[1] || sendbirdchannel?.id)
                      : createMessageChannelCaseClick($event, user)
                  "
                >
                  {{ caseCreatedFlag || sendbirdchannel?.url || sendbirdchannel?.id ? 'Save' : 'Next' }}
                </button>
              </ng-container>
              <ng-template #createMessageChannelCase>
                <button
                  mat-button
                  type="button"
                  class="primary-button"
                  [disabled]="!listOfTaggedTeamMembers.length || flagForSpinner"
                  (click)="createMessageChannelCaseClick($event, user)"
                >
                  <span [ngClass]="flagForSpinner ? 'next-button' : ''" *ngIf="!flagForSpinner"> Next </span>
                  <span class="mat-spinner-custom-changes" *ngIf="flagForSpinner"><mat-spinner diameter="25"></mat-spinner></span>
                </button>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <ng-template #loading>
        <div class="d-flex align-items-center justify-content-center loader container container-custom-changes">
          <app-loading-indicator></app-loading-indicator>
        </div>
      </ng-template>
    </mat-menu>
  </div>
</div>

<!--  CONTENT -->
<div
  class="content"
  (dragenter)="onDragEnter($event)"
  (dragstart)="onDragStart($event)"
  (drop)="onDrop($event)"
  (dragover)="dragOver($event)"
>
  <div *ngIf="isDraggingOver" (dragleave)="onDragLeave()" class="drop-overlay">
    <div>
      <svg-icon key="drag-and-drop" [fontSize]="50"></svg-icon>
      <span class="drop-text">{{ 'MESSAGES.DRAG_AND_DROP' | translate }}</span>
    </div>
  </div>
  <div #scrollableMessages class="messages-container" (scroll)="onThreadScroll()">
    <app-sendbird-message
      *ngFor="let message of sendbirdMessage"
      [sendbirdmessage]="message"
      [userId]="userDetail.id"
      [sendbirdMessageArray]="sendbirdMessage"
      (S3fileMessageDownload)="onS3fileMessageDownloadClick($event)"
      [isUserJoined]="isUserJoined"
    ></app-sendbird-message>
  </div>
  <button type="button" *ngIf="showNewMessage" (click)="scrollToBottom(); showNewMessage = false" class="btn btn-primary arrow-button">
    <svg-icon key="message-down-arrow"></svg-icon>
    <span class="button-text">New message</span>
  </button>
</div>

<div class="p-1" (dragenter)="onDragLeave()">
  <div
    *ngIf="isLastMessageSentByCurrentUser"
    class="ghost-message text-micro-regular"
    [innerHtml]="'MESSAGES.MESSAGE_SENT_HINT' | translate"
  ></div>
</div>

<!-- FOOTER -->
<div [hidden]="isCompleted">
  <mat-progress-bar mode="determinate" [value]="valueOfLoader"></mat-progress-bar>
</div>

<div class="footer p-4" *ngIf="accountParticipants$ | async as people">
  <div *ngIf="checkOutOfOffice$(people) | async" class="out-of-office">{{ generateOutOfOfficeMessage() }}</div>
  <div class="d-flex align-items-end" *ngIf="user$ | async as user">
    <div
      class="input-field d-flex flex-grow-1"
      [class.empty]="!messageControl.value && !hasAttachments"
      [class.disabled]="messageControl.disabled"
    >
      <div class="uploaded-file">
        <p *ngFor="let attachment of attachments; let index = index">
          {{ attachment.name }}
          <a class="cancel" (click)="onDismissAttachmentClick(attachment)">
            <svg-icon key="close" class="delete-attachment-btn"></svg-icon>
          </a>
        </p>
      </div>
      <div class="text-area-container" (focusout)="onBlur()">
        <ckeditor
          #cke
          type="inline"
          [editor]="ckEditor"
          [config]="editorConfig"
          (ready)="onReady($event)"
          (input)="onTextAreaInput()"
          (change)="onChange($event)"
          class="message-input text-tiny-regular color-text-primary ck-editor-message-input"
          [ngClass]="attachments && attachments.length ? 'attachment-display' : 'message-input-width'"
          [formControl]="messageControl"
        ></ckeditor>

        <div
          *ngIf="!hasAttachments"
          class="d-flex align-items-center"
          [class.drop-zone]="isDraggingOver"
          (dragenter)="onDragEnter($event)"
          (drop)="onDrop($event)"
          (dragleave)="onDragLeave()"
          (dragover)="dragOver($event)"
        >
          <button type="button" mat-icon-button class="attachment-button color-secondary" (click)="attachmentInput.click()">
            <svg-icon key="attachment"></svg-icon>
          </button>
        </div>
      </div>
    </div>
    <button
      id="sendMessageBtn"
      type="button"
      mat-icon-button
      class="send-message-button mb-2 ml-2"
      [disabled]="!canSendMessage || !isCompleted"
      (click)="onSendMessageClick(user)"
    >
      <app-loading-indicator *ngIf="sendingMessage; else sendButton" [diameter]="24"></app-loading-indicator>
      <ng-template #sendButton>
        <svg-icon key="send2"></svg-icon>
      </ng-template>
    </button>
  </div>
  <ng-container *ngIf="filesExceededSizeLimit?.length">
    <div
      *ngFor="let file of filesExceededSizeLimit"
      class="d-flex justify-content-end align-items-center text-micro-regular color-error mt-1 file-size-hint"
    >
      <div [innerHTML]="'ERROR.FILE_SIZE_LIMIT_EXCEEDED' | translate: { fileName: file.name, maxFileSize: maxFileSizeInMb }"></div>
      <button mat-icon-button type="button" class="text-reduced-bold" (click)="removeInvalidFileClick(file.name)">
        <svg-icon key="close"></svg-icon>
      </button>
    </div>
  </ng-container>
</div>
<input
  #attachmentInput
  type="file"
  hidden
  [formControl]="attachmentControl"
  (change)="handleFileInputChange($event)"
  data-hj-allow
  multiple
/>
