<div [formGroup]="form">
  <ng-container *ngIf="field.isEditable || field.type === 'checkbox' || field.type === 'lookup'; else readonlyView">
    <div [ngSwitch]="field.type">
      <!-- Text and number inputs -->

      <app-form-control *ngSwitchCase="'text'" class="px-0" [error]="assessError()">
        <input
          [formControlName]="field.fieldApiName"
          [ngClass]="!field.isEditable ? 'readonlyClass' : ''"
          [id]="field.fieldApiName"
          type="text"
          class="form-control-input"
          appFormControlInput
        />
      </app-form-control>

      <app-form-control *ngSwitchCase="'number'" class="px-0" [error]="assessError()">
        <input
          [formControlName]="field.fieldApiName"
          [ngClass]="!field.isEditable ? 'readonlyClass' : ''"
          [id]="field.fieldApiName"
          type="number"
          class="form-control-input"
          appFormControlInput
        />
      </app-form-control>

      <app-form-control *ngSwitchCase="'textarea'" class="px-0" [error]="assessError()">
        <textarea
          [formControlName]="field.fieldApiName"
          [ngClass]="!field.isEditable ? 'readonlyClass' : ''"
          [id]="field.fieldApiName"
          type="text"
          class="form-control-input"
          appFormControlInput
        ></textarea>
      </app-form-control>

      <!-- Select -->
      <app-form-control *ngSwitchCase="'select'" class="col-12 px-0" [error]="assessError()">
        <app-dropdown
          [formControlName]="field.fieldApiName"
          [data]="field.picklistValues"
          appFormControlInput
          class="form-control-input"
        ></app-dropdown>
      </app-form-control>

      <!-- Checkbox -->
      <div *ngIf="field.isEditable">
        <app-form-control *ngSwitchCase="'checkbox'" class="px-0" [error]="assessError()">
          <mat-checkbox
            class="checkbox form-control-input"
            appFormControlInput
            [formControlName]="field.fieldApiName"
            [disabled]="!field.isEditable"
          ></mat-checkbox>
        </app-form-control>
      </div>
      <div *ngIf="!field.isEditable">
        <mat-checkbox *ngSwitchCase="'checkbox'" [checked]="field.value" [disabled]="!field.isEditable"></mat-checkbox>
      </div>

      <!-- Date time picker -->
      <div *ngSwitchCase="'datetime'">
        <app-form-control *ngIf="field.isEditable" class="px-0" [error]="assessError()">
          <input class="form-control-input" appFormControlInput matInput type="datetime-local" [formControlName]="field.fieldApiName" />
        </app-form-control>
      </div>

      <div *ngSwitchCase="'lookup'">
        <input
          *ngIf="field.isEditable"
          [formControlName]="field.fieldApiName"
          class="readonlyClass"
          [id]="field.fieldApiName"
          type="text"
          readonly
        />
        <p *ngIf="!field.isEditable">{{ form.controls[this.field.fieldApiName].value }}</p>
      </div>
    </div>
  </ng-container>

  <ng-template #readonlyView>
    <p>{{ form.controls[this.field.fieldApiName].value }}</p>
  </ng-template>
</div>
