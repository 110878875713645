import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../../../reducers/index';
import * as fromRolloutWAitForCalculation from '../reducers/rollout-wait-for-calculation.reducer';
import * as fromNewQuoteBasics from './new-quote-basics.reducer';
import * as fromNewQuoteFinalCosts from './new-quote-final-costs.reducer';
import * as fromNewQuoteLineItems from './new-quote-line-items.reducer';

export const newQuoteFeatureKey = 'newQuote';

export interface NewQuoteState {
  [fromNewQuoteBasics.newQuoteBasicsFeatureKey]: fromNewQuoteBasics.State;
  [fromNewQuoteLineItems.newQuoteLineItemsFeatureKey]: fromNewQuoteLineItems.State;
  [fromNewQuoteFinalCosts.newQuoteFinalCostsFeatureKey]: fromNewQuoteFinalCosts.State;
  [fromRolloutWAitForCalculation.rolloutWaitForCalculationFeatureKey]: fromRolloutWAitForCalculation.State;
}

export interface AppState extends State {
  [newQuoteFeatureKey]: NewQuoteState;
}

export const reducers: ActionReducerMap<NewQuoteState> = {
  [fromNewQuoteBasics.newQuoteBasicsFeatureKey]: fromNewQuoteBasics.reducer,
  [fromNewQuoteLineItems.newQuoteLineItemsFeatureKey]: fromNewQuoteLineItems.reducer,
  [fromNewQuoteFinalCosts.newQuoteFinalCostsFeatureKey]: fromNewQuoteFinalCosts.reducer,
  [fromRolloutWAitForCalculation.rolloutWaitForCalculationFeatureKey]: fromRolloutWAitForCalculation.reducer,
};

export const selectNewQuoteFeatureState = createFeatureSelector<NewQuoteState>(newQuoteFeatureKey);

export const selectNewQuoteBasicsState = createSelector(
  selectNewQuoteFeatureState,
  (state) => state[fromNewQuoteBasics.newQuoteBasicsFeatureKey]
);

export const selectNewQuoteBasicsValuesData = createSelector(selectNewQuoteBasicsState, (state) => state.values.data);
export const selectNewQuoteBasicsValuesLoading = createSelector(
  selectNewQuoteBasicsState,
  (state) => state.values.isLoading || state.defaults.isLoading
);

export const selectNewQuoteLineItemsState = createSelector(
  selectNewQuoteFeatureState,
  (state) => state[fromNewQuoteLineItems.newQuoteLineItemsFeatureKey]
);
export const selectNewQuoteLineItems = createSelector(selectNewQuoteLineItemsState, (state) => state.lineItems);

export const selectNewQuoteFinalCostsState = createSelector(
  selectNewQuoteFeatureState,
  (state) => state[fromNewQuoteFinalCosts.newQuoteFinalCostsFeatureKey]
);

export const selectNewQuoteDefaults = createSelector(selectNewQuoteBasicsState, (state) => state.defaults.data);
export const selectShipmentOrderIds = createSelector(selectNewQuoteBasicsState, (state) => state.shipmentOrderIds);

export const selectRolloutWaitForCalculationState = createSelector(
  selectNewQuoteFeatureState,
  (state) => state[fromRolloutWAitForCalculation.rolloutWaitForCalculationFeatureKey]
);

export const selectRolloutWaitingForCalculationData = createSelector(
  selectRolloutWaitForCalculationState,
  (state) => state.rolloutQuote.data
);

export const selectRolloutIdFromRolloutCreation = createSelector(selectNewQuoteBasicsState, (state) => state.userWithRODetail);

export const selectRollOutDetailFromSelectedSoId = createSelector(selectNewQuoteLineItemsState, (state) => state.RollOutDetail);

export const selectShipmentsWhichStoredViaAddToShipment = createSelector(
  selectNewQuoteLineItemsState,
  (state) => state.shipmentOrderIdsWhichAdded
);

export const selectRolloutWaitForCalculationLoading = createSelector(
  selectRolloutWaitForCalculationState,
  (state) => state.rolloutQuote.isLoading
);

export const selectRollOutDetailForNewQuote = createSelector(selectNewQuoteBasicsState, (state) => state.rollOutDetailForNewQuote);

export const selectRollOutDetailForSummary = createSelector(
  selectNewQuoteBasicsState,
  (state) => state.loadExistingRoDetailForSummary.data
);
