import { LineItemsColumn } from '@global/enums/line-items-column.enum';
import { LineItem } from '@global/modules/common-quote/interfaces/line-item.interface';
import { UpdatePartsRequestParts } from '@tecex-api/data/model/updatePartsRequestParts';

export const mapUpdatePartsPayload = (lineItems: LineItem[]): UpdatePartsRequestParts[] =>
  lineItems.map((lineItem) => ({
    PartNumber: lineItem[LineItemsColumn.ProductCode],
    PartDescription: lineItem[LineItemsColumn.Description],
    Quantity: lineItem[LineItemsColumn.Quantity],
    UnitPrice: lineItem[LineItemsColumn.UnitPrice],
    partId: lineItem[LineItemsColumn.Id],
  }));
