import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatSelectModule } from '@angular/material/select';
import { GlobalModule } from '../../global.module';
import { SvgIconsModule } from '../svg-icons/svg-icons.module';
import { MultiAutocompleteOptionDirective } from './multi-autocomplete-option.directive';
import { MultiAutocompleteComponent } from './multi-autocomplete.component';

@NgModule({
  imports: [
    GlobalModule,
    SvgIconsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule,
    MultiAutocompleteComponent,
    MultiAutocompleteOptionDirective,
  ],
  exports: [MultiAutocompleteComponent, MultiAutocompleteOptionDirective],
})
export class MultiAutocompleteModule {}
