import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import { RecordType } from '@tecex-api/data';
import { Observable } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIf, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { RouteSegment } from '../../../../enums/route-segment.enum';
import { MessageThreadShipment } from '../../../../interfaces/messages/message-thread-shipment.interface';
import { ValidatorHelperService } from '../../../../services/validator-helper.service';
import { FormControlInputDirective } from '../../../form-control/directives/form-control-input.directive';
import { FormControlComponent } from '../../../form-control/components/form-control/form-control.component';

@Component({
  selector: 'app-subject',
  templateUrl: './subject.component.html',
  styleUrls: ['./subject.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    FormControlComponent,
    FormsModule,
    FormControlInputDirective,
    ReactiveFormsModule,
    NgTemplateOutlet,
    RouterLink,
    AsyncPipe,
    TranslateModule,
  ],
})
export class SubjectComponent implements OnInit {
  @Input() public subjectControl: FormControl<string>;
  @Input() public shipment?: MessageThreadShipment;
  @Input() public editable: boolean;
  @Input() public recordType: RecordType;
  @Output() public subjectChange = new EventEmitter<string>();

  public subjectError$: Observable<string | null>;

  constructor(private readonly validatorHelperService: ValidatorHelperService) {}

  public ngOnInit(): void {
    this.subjectError$ = this.validatorHelperService.getError$(this.subjectControl);
  }

  public get shipmentRouterLink(): string[] {
    if (this.recordType === RecordType.COST_ESTIMATE) {
      return [RouteSegment.Root, RouteSegment.QuoteList, this.shipment.id];
    }
    return [RouteSegment.Root, RouteSegment.ShipmentsList, this.shipment.id];
  }

  public get subject(): string {
    return this.subjectControl.value;
  }
}
