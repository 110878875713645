<!-- HEADER -->
<div class="header">
  <span class="title">
    {{ title }}
  </span>
  <span class="sub-title ml-md-3">{{ 'COMMON.N_ADDED' | translate: { n: selectedAddressList.length } }}</span>
</div>

<div *ngIf="isRollout">
  <app-alert-bar [message]="'SELECT_ADDRESS.ROLLOUT_ADDRESS_ALERT' | translate"></app-alert-bar>
</div>

<!-- FILTER SECTION -->
<div class="container mt-3 filter">
  <div class="row align-items-end">
    <div class="col-6">
      <input
        [ngModel]="searchText"
        type="text"
        [placeholder]="'COMMON.SEARCH' | translate"
        class="form-control-input"
        (ngModelChange)="searchAddresses($event)"
      />
    </div>
    <div class="d-flex flex-column col-6 align-items-end">
      <div class="button-hint">{{ 'SELECT_ADDRESS.DONT_HAVE_IN_THE_LIST' | translate }}</div>
      <button mat-button class="secondary-button mt-1" (click)="onAddNewAddressClick()">
        {{ 'SELECT_ADDRESS.ADD_NEW_ADDRESS' | translate }}
      </button>
    </div>
  </div>
</div>

<div *ngIf="hasAddresses; else noAddresses" class="addresses">
  <!-- SELECTED ADDRESSES -->
  <div>
    <app-address-card
      *ngFor="let address of selectedAddressList"
      (selectAddressWithCheckbox)="onSelectAddress($event)"
      (selectAddressWithRadio)="onToggleAddress($event)"
      (editAddressCard)="onEditAddressCard($event)"
      (deleteAddressCard)="onDeleteAddressCard()"
      [address]="address"
      [isMultipleChoiceEnabled]="type === SelectAddressDialogType.LocationAddresses"
      [isSelected]="true"
      [canDelete]="type === SelectAddressDialogType.PickUpAddress"
    ></app-address-card>
  </div>

  <!-- ADDRESS LIST -->
  <div>
    <app-address-card
      *ngFor="let address of filteredAddressList"
      (selectAddressWithCheckbox)="onSelectAddress($event)"
      (selectAddressWithRadio)="onToggleAddress($event)"
      (editAddressCard)="onEditAddressCard($event)"
      [address]="address"
      [isMultipleChoiceEnabled]="type === SelectAddressDialogType.LocationAddresses"
    ></app-address-card>
  </div>
</div>
<ng-template #noAddresses>
  <div class="no-addresses">
    {{ 'SELECT_ADDRESS.NO_ADDRESSES_YET' | translate }}
    <button class="text-link md-dark" (click)="onAddNewAddressClick()">{{ 'SELECT_ADDRESS.CREATE_ONE' | translate }}</button>
  </div>
</ng-template>
<div class="gradient"></div>
