import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormArray, FormControl } from '@ngneat/reactive-forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { from } from 'rxjs';
import { map, switchMap, toArray } from 'rxjs/operators';
import { NgFor, AsyncPipe } from '@angular/common';
import { LineItemsColumn } from '../../../../enums/line-items-column.enum';
import { BlockComponent } from '../block/block.component';
import { SelectComponent } from '../../../select/select.component';
import { PartSubCategoryTableBlockPayload } from './part-sub-category-table-block-payload.interface';

export const NOT_PROVIDED_CATEGORY = `I don't know`;
export const OTHER_CATEGORY = 'other';

@Component({
  selector: 'app-part-sub-category-table-block',
  templateUrl: './part-sub-category-table-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, SelectComponent, FormsModule, ReactiveFormsModule, AsyncPipe, TranslateModule],
})
export class PartSubCategoryTableBlockComponent extends BlockComponent<PartSubCategoryTableBlockPayload, string[]> implements OnInit {
  public productCategoriesControl = new FormArray([]);

  // tslint:disable no-magic-numbers
  public readonly productCategories$ = from([
    ['DONT_HAVE_INFORMATION', "I don't have this information"],
    ['SPEAKERS', 'Speakers'],
    ['CAMERAS', 'Cameras'],
    ['CELLPHONES', 'Cellphones'],
    ['GAMING_CONSOLES', 'Gaming Consoles'],
    ['POWER_EQUIPMENT', 'Power Equipment'],
    ['PRINTERS_SCANNERS_AND_COPIERS', 'Multifunctional Printers (scan, copy, fax)'],
    ['EMPTY_RACKS', 'Empty Racks'],
    ['SERVERS', 'Servers'],
    ['STORAGE_DEVICES', 'Storage Devices'],
    ['FREE_STANDING_MONITORS', 'Free Standing Monitors'],
    ['TRANSCEIVERS', 'Transceivers'],
    ['ROUTERS', 'Routers'],
    ['SWITCHES', 'Switches'],
    ['SD_WANS', 'SD-WANs'],
    ['KEYBOARDS_AND_PERIPHERALS', 'Keyboards and Peripherals'],
    ['POWER_DISTRIBUTION_EQUIPMENT', "Power Distribution Equipment (PDU's)"],
    ['CONTROLLERS_AND_ADAPTERS', 'Controllers and Adapters'],
    ['SERVER_PARTS', 'Server Parts'],
    ['BRACKETS', 'Brackets'],
    ['TOOLS', 'Tools'],
    ['COMMUNICATION_CABLES', 'Communication Cables'],
    ['LAPTOPS', 'Laptops'],
    ['SSDS_AND_FLASH_DRIVES', "SSD's and Flash Drives"],
    ['FIBER_OPTIC_CABLES', 'Fiber Optic Cables'],
    ['POWER_AND_OTHER_CABLES', 'Power and Other Cables'],
    ['PARTS_OF_ROUTERS_SWITCHES_AND_ACCESS_POINTS', 'Parts of Routers, Switches and Access Points'],
    ['SOFTWARE_ON_A_FLASH_OR_SSD', 'Software on a Flash or SSD'],
    ['RAM_OR_MEMORY', 'RAM or Memory'],
    ['PLASTICS', 'Plastics'],
    ['MANUALS_AND_TECHNICAL_PAPERS', 'Manuals and Technical Papers'],
    ['OTHER', 'Other'],
  ]).pipe(
    switchMap(([translationKey, hsCode]) =>
      this.translateService.get(`TASKS.PART_SUB_CATEGORY_TABLE.PRODUCT_CATEGORIES.${translationKey}`).pipe(
        map((translation) => ({
          value: hsCode,
          viewValue: translation,
        }))
      )
    ),
    toArray()
  );
  // tslint:enable no-magic-numbers

  public readonly LineItemsColumn = LineItemsColumn;

  constructor(private readonly translateService: TranslateService) {
    super();
  }

  public ngOnInit(): void {
    this.payload.lineItems.forEach(() => this.productCategoriesControl.push(new FormControl(undefined, [Validators.required])));
    this.register.next(this.productCategoriesControl);
  }
}
