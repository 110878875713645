<div class="dialog-container">
  <div class="dialog-content">
    <img [src]="imgPath" class="logo" alt="TecEx client portal" width="80" height="32" />

    <div class="text-center" *ngIf="payload">
      <svg-icon key="exclamation-mark" class="icon"></svg-icon>
      <div class="text-increased-bold color-text-primary text-uppercase mt-4">
        {{ 'ACCOUNT_CHANGE_DIALOG.DIFFERENT_ACCOUNT' | translate }}
      </div>

      <div
        class="text-reduced-regular"
        [innerHTML]="
          'ACCOUNT_CHANGE_DIALOG.ACCOUNT_NOTE'
            | translate
              : {
                  objectType: payload.objectType,
                  objectAccount: payload.objectAccount.accountName,
                  currentAccount: payload.currentAccount.accountName
                }
        "
      ></div>
    </div>
  </div>

  <div class="dialog-footer d-flex align-items-center justify-content-center py-3">
    <button mat-button class="secondary-button" (click)="currentAccount()">
      {{ 'COMMON.NO' | translate }}
    </button>
    <button mat-button class="primary-button ml-4" (click)="switchAccounts()">
      {{ 'COMMON.YES' | translate }}
    </button>
  </div>
</div>
