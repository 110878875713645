import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TasksDataService } from '@tecex-api/data';
import { Success } from '@tecex-api/data/model/success';
import moment from 'moment';
import { DialogRef } from '../../../dialog/dialog-ref';
import { DialogService } from '../../../dialog/dialog.service';
import { DialogShowMode } from '../../../dialog/enums/dialog-show-mode.enum';
import { AuthService } from '../../../../services/auth.service';
import { RePollService } from '../../../../../../../projects/tecex/src/app/shared/services/re-poll.service';
import { LoadingIndicatorService } from '../../../loading-indicator/services/loading-indicator.service';
import { SourceApp } from '../../../../enums/source-app.enum';
import { ChildRecordsDialogPayload } from './child-records-dialog-payload.interface';
import { ChildRecordsDialogComponent } from './child-records-dialog.component';

@Injectable()
export class ChildRecordsDialogService {
  constructor(
    private readonly injector: Injector,
    private readonly dialogService: DialogService,
    private readonly authService: AuthService,
    private readonly rePollService: RePollService,
    private readonly loadingIndicatorService: LoadingIndicatorService,
    private readonly tasksDataService: TasksDataService
  ) {}

  public open(payload: ChildRecordsDialogPayload): DialogRef<any> {
    return this.dialogService.open<ChildRecordsDialogPayload, boolean>(ChildRecordsDialogComponent, payload, {
      showMode: DialogShowMode.Side,
      closeButton: false,
      closeOnNavigation: true,
      width: '800px',
      injector: this.injector,
    });
  }

  public updateChildRecords$(form, initialData): Observable<Success> {
    this.loadingIndicatorService.open();
    const updatedRecords = [];
    const records = initialData.records.map((record, index) => {
      const recordFields = [];
      record.forEach((field) => {
        if (field.isEditable) {
          recordFields.push({
            recordId: field.recordId,
            fieldLabel: field.fieldLabel,
            fieldApiName: field.fieldApiName,
            value:
              field.type === 'datetime'
                ? moment(form.controls[index].controls[field.fieldApiName].value).utc().format()
                : field.type === 'select'
                ? form.controls[index].controls[field.fieldApiName].value.value
                : form.controls[index].controls[field.fieldApiName].value,
            type: field.type,
          });
        }
      });
      updatedRecords.push(recordFields);
    });
    return this.authService.getUser$().pipe(
      switchMap((user) => {
        return this.tasksDataService
          .updateChildRecords({
            AccessToken: user.accessToken,
            AppName: SourceApp.TecEx,
            sobjectName: initialData.sobject,
            records: updatedRecords,
          })
          .pipe(
            map((resp) => {
              return resp;
            })
          );
      })
    );
  }
}
