<div class="select-address-dialog-container">
  <app-select-address
    [type]="payload.dialogType"
    [title]="payload.title"
    [initialAddresses]="payload.addresses"
    [initialSelectedAddresses]="payload.selectedAddresses"
    [onCreate]="payload.onCreate"
    [onEdit]="payload.onEdit"
    (selectedAddressesChange)="onSelectedAddressChange($event)"
  ></app-select-address>
  <div class="dialog-footer">
    <div class="cross"></div>
    <div class="d-flex justify-content-center align-items-center button-container">
      <button mat-button type="button" class="primary-button" [disabled]="!canProceed" (click)="onAddAddressesClick()">
        {{ isNewPickupAddress ? ('COMMON.SAVE' | translate) : payload.buttonText }}
      </button>
    </div>
  </div>
</div>
