import { Injectable } from '@angular/core';
import { ShipmentOrderDataService } from '@tecex-api/data';
import { Observable } from 'rxjs';
import { finalize, mapTo, switchMap } from 'rxjs/operators';
import { RePollService } from '../../../../../../projects/tecex/src/app/shared/services/re-poll.service';
import { roundDecimal } from '../../../helpers/utils.helper';
import { AuthService } from '../../../services/auth.service';
import { LoadingIndicatorService } from '../../loading-indicator/services/loading-indicator.service';
import { mapEstimatedWeight } from '../helpers/map-estimated-weight.helper';
import { mapShipmentOrderToUpdateRequest } from '../helpers/map-shipment-order-to-update-request.helper';
import { UpdateQuotePayload } from '../interfaces/update-quote-payload.interface';
import { ServiceType } from '../../../enums/service-type.enum';

@Injectable({
  providedIn: 'root',
})
export class UpdateQuoteService {
  constructor(
    private readonly authService: AuthService,
    private readonly loadingIndicatorService: LoadingIndicatorService,
    private readonly shipmentOrderDataService: ShipmentOrderDataService,
    private readonly rePollService: RePollService
  ) {}

  /**
   * In order to update a quote, we first have to fetch the current state of the quote from the API,
   * and then we have to pass every field with every update, otherwise the updateShipmentOrder call
   * will potentially erase some data from Salesforce. ¯\_(ツ)_/¯
   */
  public updateQuote$(id: string, payload: UpdateQuotePayload, disableLoadingIndicatior?: boolean): Observable<void> {
    return this.authService.getUser$().pipe(
      // eslint-disable-next-line sonarjs/cognitive-complexity
      switchMap((user) => {
        if (!disableLoadingIndicatior) {
          this.loadingIndicatorService.open();
        }
        return this.rePollService
          .pollSoDetails$({
            accessToken: user.accessToken,
            accountId: user.accountId,
            contactId: user.contactId,
            shipmentOrderId: id,
          })
          .pipe(
            switchMap((shipmentOrder) =>
              this.shipmentOrderDataService.updateShipmentOrder({
                Accesstoken: user.accessToken,
                ID: id,
                ...mapShipmentOrderToUpdateRequest(shipmentOrder),
                ...(payload.status && { ShippingStatus: payload.status }),
                ...(payload.cancelQuoteReason && { NCPCancelQuoteReason: payload.cancelQuoteReason }),
                ...(payload.reasonForCancel && { ReasonforCancel: payload.reasonForCancel }),
                ...(payload.clientContactForShipment && { ClientContactForShipment: payload.clientContactForShipment }),
                ...(payload.clientNote && { NCPClientNotes: payload.clientNote }),
                ...(payload.preferredFreight && { preferredFreight: payload.preferredFreight }),
                ...(payload.intentionWithGoods && { Intention_with_Goods: payload.intentionWithGoods }),
                ...(payload.intentionWithGoodsOther && { Intention_with_Goods_Other: payload.intentionWithGoodsOther }),
                ...(payload.contactName && { Contact_name: payload.contactName }),
                ...(payload.beneficialOwnerCountry && { Beneficial_Owner_Country: payload.beneficialOwnerCountry }),
                ...(payload.beneficialOwnerAddress && { Beneficial_Owner_Company_Address: payload.beneficialOwnerAddress }),
                ...(payload.buyerOrBoVatNumber && { Buyer_or_BO_VAT_Number: payload.buyerOrBoVatNumber }),
                ...(payload.buyerRetainsOwnership && { Buyer_Retains_Ownership: payload.buyerRetainsOwnership }),
                ...(payload.buyerBeneficialOwnerPostalCode && {
                  BuyerBeneficialOwnerPostalCode: payload.buyerBeneficialOwnerPostalCode,
                }),
                ...(payload.buyerBeneficialOwnerCity && { BuyerBeneficialOwnerCity: payload.buyerBeneficialOwnerCity }),
                ...(payload.serviceType && { ServiceType: shipmentOrder.EOR_IOR_Linked_SO__c ? ServiceType.IOR : payload.serviceType }),
                ...(payload.typeOfGoods && { TypeOfGoods: payload.typeOfGoods }),
                ...(payload.estimatedWeight && {
                  ChargeableWeight: roundDecimal(mapEstimatedWeight(payload.estimatedWeight, payload.estimatedWeightUnit)),
                }),
                ...(payload.numberOfLocations && { FinalDeliveries: payload.numberOfLocations }),
                ...(payload.projectReferences?.[0] && { Reference1: payload.projectReferences?.[0] }),
                ...(payload.projectReferences?.[1] && { Reference2: payload.projectReferences?.[1] }),
                ...(payload.nameOfAddressedEntity?.id && { BuyerAccount: payload.nameOfAddressedEntity?.id }),
                ...(payload.localVatRegistration?.vatNumber && { Buyer_or_BO_VAT_Number: payload.localVatRegistration?.vatNumber }),
                ...(payload.shipmentValue && {
                  Shipment_Value_USD: payload.shipmentValue,
                }),
                ThirdPartyCompliance: payload.thirdPartyCompliance,
                ThirdPartyFreightForwarder: payload.thirdPartyFreightForwarder,
                ThirdPartyEndUser: payload.thirdPartyEndUser,
              })
            ),
            finalize(() => {
              if (!disableLoadingIndicatior) {
                this.loadingIndicatorService.dispose();
              }
            }),
            // eslint-disable-next-line unicorn/no-useless-undefined
            mapTo(undefined)
          );
      })
    );
  }
}
