import { QuoteListSideFilterDialogVM } from '@modules/quote-list/components/quote-list-side-filter-dialog/quote-list-side-filter-dialog.vm';
import { LinkedQuoteVM } from '@modules/quote-list/interfaces/linked-quote.vm';
import { TecexQuoteVM } from '@modules/quote-list/interfaces/tecex-quote.vm';
import { QuoteType } from '@shared/enums/quote-type.enum';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { RolloutQuoteVM } from '../interfaces/rollout-quote.vm';

export const filterQuoteList = (
  quotes: (TecexQuoteVM | RolloutQuoteVM | LinkedQuoteVM)[],
  filters: QuoteListSideFilterDialogVM
): (TecexQuoteVM | RolloutQuoteVM | LinkedQuoteVM)[] => {
  if (isEmpty(filters)) {
    return quotes;
  }
  return quotes
    .filter((quote) =>
      [
        checkCreatedDateMatch,
        checkAssigneeMatch,
        checkReference1Match,
        checkReference2Match,
        checkCountryMatch,
        checkServiceTypeMatch,
        checkQuoteTypeMatch,
      ].every((matchFunction) =>
        quote.type === QuoteType.Rollout || quote.type === QuoteType.LinkedGroup
          ? quote.quotes.some((subQuote) => matchFunction(filters, subQuote))
          : matchFunction(filters, quote)
      )
    )
    .map((quote) => {
      if (quote.type !== QuoteType.Rollout && quote.type !== QuoteType.LinkedGroup) {
        return quote;
      }

      const newSubQuotes = quote.quotes.filter((subQuote) =>
        [
          checkCreatedDateMatch,
          checkAssigneeMatch,
          checkReference1Match,
          checkReference2Match,
          checkCountryMatch,
          checkServiceTypeMatch,
          checkQuoteTypeMatch,
        ].every((matchFunction) => matchFunction(filters, subQuote))
      );

      return { ...quote, quotes: newSubQuotes };
    });
};

export const checkOwnerMatch = (quote: TecexQuoteVM, ownerId: string): boolean => !isNil(quote.owner) && quote.owner.id === ownerId;

const checkCreatedDateMatch = (filters: QuoteListSideFilterDialogVM, quote: TecexQuoteVM | RolloutQuoteVM | LinkedQuoteVM): boolean => {
  if (isEmpty(filters.createdDateRange)) {
    return true;
  }
  const isAfterStartDate =
    isEmpty(filters.createdDateRange.start) || new Date(quote.createdDate).valueOf() >= new Date(filters.createdDateRange.start).valueOf();
  const isBeforeEndDate =
    isEmpty(filters.createdDateRange.end) || new Date(quote.createdDate).valueOf() <= new Date(filters.createdDateRange.end).valueOf();
  return isAfterStartDate && isBeforeEndDate;
};
export const checkAssigneeMatch = (filters: QuoteListSideFilterDialogVM, quote: TecexQuoteVM | RolloutQuoteVM | LinkedQuoteVM): boolean =>
  isEmpty(filters.assignees) || (!isEmpty(quote.owner) && filters.assignees.includes(quote.owner.id));
const checkReference1Match = (filters: QuoteListSideFilterDialogVM, quote: TecexQuoteVM | RolloutQuoteVM | LinkedQuoteVM): boolean =>
  isEmpty(filters.clientReference1) ||
  (!isEmpty(quote.reference1) && quote.reference1.toLowerCase().includes(filters.clientReference1.trim().toLowerCase()));
const checkReference2Match = (filters: QuoteListSideFilterDialogVM, quote: TecexQuoteVM | RolloutQuoteVM | LinkedQuoteVM): boolean =>
  isEmpty(filters.clientReference2) ||
  (!isEmpty(quote.reference2) && quote.reference2.toLowerCase().includes(filters.clientReference2.trim().toLowerCase()));
const checkCountryMatch = (filters: QuoteListSideFilterDialogVM, quote: TecexQuoteVM | RolloutQuoteVM | LinkedQuoteVM): boolean =>
  isEmpty(filters.shipToCountry) || filters.shipToCountry.includes(quote.destinationCountry);
const checkServiceTypeMatch = (filters: QuoteListSideFilterDialogVM, quote: TecexQuoteVM | RolloutQuoteVM | LinkedQuoteVM): boolean =>
  isNil(filters.serviceType) || quote.serviceType === filters.serviceType;
const checkQuoteTypeMatch = (filters: QuoteListSideFilterDialogVM, quote: TecexQuoteVM | RolloutQuoteVM | LinkedQuoteVM): boolean =>
  isNil(filters.quoteType) || quote.quoteType === filters.quoteType;
