import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AuthService } from '@global/services/auth.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThirdPartyGuard {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService
  ) {}

  public canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Get this from api call :
    const isThirdPartyUser = this.authService.thirdPartyFlag();

    // For load third-party dashboard :
    if (isThirdPartyUser) {
      this.router.navigate(['/third-party']);
      return of(false);
    }

    return of(true);
  }
}
