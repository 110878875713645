<div class="dialog-footer">
  <div>
    <div class="message-thread align-items-center">
      <div class="inner d-flex align-items-center">
        <div class="container request-approval-container">
          <svg-icon key="email" class="email-icon"></svg-icon>

          <div class="dialog-title-section">
            <h3 class="dialog-title">{{ 'QUOTE_DETAILS.REQUEST_QUOTE_APPROVAL' | translate }}</h3>
          </div>

          <div class="dropdown-description-section">
            <span class="dropdown-header"> {{ 'QUOTE_DETAILS.QUOTE_APPROVAL_REQUEST_DESCRIPTION' | translate }} </span>
          </div>

          <div>
            <div class="container dialog-dropdown-section">
              <select class="form-control-input" id="inputGroupSelect01" (change)="contactData($event)">
                <option selected></option>
                <ng-container *ngFor="let contactDetail of contactList; let i = index">
                  <option value="{{ contactDetail.contactId }}">{{ contactDetail.name }}</option>
                </ng-container>
              </select>
            </div>

            <div class="request-cancel-btn-wrapper">
              <div class="d-flex align-items-center justify-content-center">
                <button mat-button type="button" appDialogClose class="secondary-button mr-5">
                  {{ 'QUOTE_DETAILS.CANCEL_REQUEST' | translate }}
                </button>
                <div class="d-inline-block">
                  <button
                    mat-button
                    type="button"
                    [disabled]="!contactDetail"
                    appDialogClose
                    class="primary-button"
                    (click)="createRequestForApproval()"
                  >
                    {{ 'QUOTE_DETAILS.CREATE_REQUEST' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
