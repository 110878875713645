<div *ngFor="let block of blocks; let index = index" class="d-flex align-items-start block">
  <div *ngIf="$any(block.payload).displayNumber" class="display-number mr-4">{{ $any(block.payload).displayNumber }}</div>
  <div class="content">
    <div
      *ngIf="$any(block.payload).description"
      class="text-small-regular color-text-secondary mb-4 description"
      [innerHTML]="$any(block.payload).description"
    ></div>
    <ng-container [ngSwitch]="block.type">
      <app-instructions-block
        *ngSwitchCase="BlockType.Instruction"
        [payload]="$any(block.payload)"
        (register)="onRegister(index, $event)"
      ></app-instructions-block>
      <app-input-block
        *ngSwitchCase="BlockType.Input"
        [payload]="$any(block.payload)"
        (register)="onRegister(index, $event)"
      ></app-input-block>
      <app-download-block *ngSwitchCase="BlockType.Download" [payload]="$any(block.payload)" (register)="onRegister(index, $event)">
      </app-download-block>
      <app-upload-block *ngSwitchCase="BlockType.Upload" [payload]="$any(block.payload)" (register)="onRegister(index, $event)">
      </app-upload-block>
      <app-switch-block *ngSwitchCase="BlockType.Switch" [payload]="$any(block.payload)" (register)="onRegister(index, $event)">
      </app-switch-block>
      <app-select-block
        *ngSwitchCase="BlockType.Select"
        [payload]="$any(block.payload)"
        (register)="onRegister(index, $event)"
      ></app-select-block>
      <app-multi-select-block
        *ngSwitchCase="BlockType.MultiSelect"
        [payload]="$any(block.payload)"
        (register)="onRegister(index, $event)"
      ></app-multi-select-block>
      <app-pick-up-address-block
        *ngSwitchCase="BlockType.PickUpAddress"
        [payload]="$any(block.payload)"
        (register)="onRegister(index, $event)"
      >
      </app-pick-up-address-block>
      <app-ship-to-address-block
        *ngSwitchCase="BlockType.ShipToAddress"
        [payload]="$any(block.payload)"
        (register)="onRegister(index, $event)"
      >
      </app-ship-to-address-block>
      <app-packages-block *ngSwitchCase="BlockType.Packages" [payload]="$any(block.payload)" (register)="onRegister(index, $event)">
      </app-packages-block>
      <app-battery-table-block
        *ngSwitchCase="BlockType.Battery"
        [payload]="$any(block.payload)"
        (register)="onRegister(index, $event)"
      ></app-battery-table-block>
      <app-datepicker-block
        *ngSwitchCase="BlockType.Datepicker"
        [payload]="$any(block.payload)"
        (register)="onRegister(index, $event)"
      ></app-datepicker-block>
      <app-part-sub-category-table-block
        *ngSwitchCase="BlockType.PartSubCategoryTable"
        [payload]="$any(block.payload)"
        (register)="onRegister(index, $event)"
      >
      </app-part-sub-category-table-block>
      <app-pickup-preference-block
        *ngSwitchCase="BlockType.PickupPreference"
        [payload]="$any(block.payload)"
        (register)="onRegister(index, $event)"
      >
      </app-pickup-preference-block>
      <app-exporter-address-block
        *ngSwitchCase="BlockType.ExporterAddress"
        [payload]="$any(block.payload)"
        (register)="onRegister(index, $event)"
      >
      </app-exporter-address-block>
    </ng-container>
  </div>
</div>
