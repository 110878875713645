import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
//@ts-ignore
import { AbstractControl, FormArray } from '@ngneat/reactive-forms';
import { asyncScheduler, Subject } from 'rxjs';
import { observeOn, takeUntil } from 'rxjs/operators';
import { NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { BlockType } from '../../types/block-type.enum';
import { Block } from '../../types/block.type';
import { ExporterAddressBlockComponent } from '../exporter-address-block/exporter-address-block.component';
import { PickupPreferenceBlockComponent } from '../pickup-preference-block/pickup-preference-block.component';
import { PartSubCategoryTableBlockComponent } from '../part-sub-category-table-block/part-sub-category-table-block.component';
import { DatepickerBlockComponent } from '../datepicker-block/datepicker-block.component';
import { BatteryTableBlockComponent } from '../battery-table-block/battery-table-block.component';
import { PackagesBlockComponent } from '../packages-block/packages-block.component';
import { ShipToAddressBlockComponent } from '../ship-to-address-block/ship-to-address-block.component';
import { PickUpAddressBlockComponent } from '../pick-up-address-block/pick-up-address-block.component';
import { MultiSelectBlockComponent } from '../multi-select-block/multi-select-block.component';
import { SelectBlockComponent } from '../select-block/select-block.component';
import { SwitchBlockComponent } from '../switch-block/switch-block.component';
import { UploadBlockComponent } from '../upload-block/upload-block.component';
import { DownloadBlockComponent } from '../download-block/download-block.component';
import { InputBlockComponent } from '../input-block/input-block.component';
import { InstructionsBlockComponent } from '../instruction-block/instructions-block.component';

@Component({
  selector: 'app-task-blocks',
  templateUrl: './task-blocks.component.html',
  styleUrls: ['./task-blocks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    InstructionsBlockComponent,
    InputBlockComponent,
    DownloadBlockComponent,
    UploadBlockComponent,
    SwitchBlockComponent,
    SelectBlockComponent,
    MultiSelectBlockComponent,
    PickUpAddressBlockComponent,
    ShipToAddressBlockComponent,
    PackagesBlockComponent,
    BatteryTableBlockComponent,
    DatepickerBlockComponent,
    PartSubCategoryTableBlockComponent,
    PickupPreferenceBlockComponent,
    ExporterAddressBlockComponent,
  ],
})
export class TaskBlocksComponent implements OnInit {
  @Input() public blocks: Block[];
  @Output() public validityChange = new EventEmitter<boolean>();

  public readonly formArray = new FormArray([]);
  public readonly BlockType = BlockType;

  private readonly destroyed$ = new Subject<void>();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.formArray.statusChanges.pipe(observeOn(asyncScheduler), takeUntil(this.destroyed$)).subscribe(() => {
      this.cdr.markForCheck();
    });

    this.formArray.statusChanges.subscribe(() => {
      this.validityChange.next(this.formArray.controls.length === 0 ? true : this.formArray.valid);
    });

    if (this.formArray.controls.length === 0) {
      this.formArray.updateValueAndValidity();
      this.validityChange.next(true);
    }
  }

  public onRegister(index: number, control: AbstractControl): void {
    this.formArray.setControl(index, control);
    this.formArray.updateValueAndValidity();
  }

  public getValue(): any {
    return this.formArray.getRawValue();
  }
}
