import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, DatePipe } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { MiscDataService } from '@tecex-api/data';
import { Subject } from 'rxjs';
import { AuthService } from '../../../../services/auth.service';
import { DIALOG_DATA } from '../../../dialog/dialog.tokens';
import { DialogData } from '../../../dialog/interfaces/dialog-data.interface';
import { Announcment } from '../../types/announcment.type';
import { CONFIG_TOKEN } from '../../../../config/config.token';
import { GlobalConfig } from '../../../../config/global-config.interface';
import { LoadingIndicatorService } from '../../../loading-indicator/services/loading-indicator.service';
import { AnnouncmentService } from '../../services/announcment.service';
import { DialogService } from '../../../dialog/dialog.service';
import { TERMS_CLOSED } from '../../helpers/announcment-closed-session-storage.helper';
import { termsAndConditionsDialogPayload } from './terms-and-conditions-dialog-payload.interface';
@Component({
  selector: 'terms-and-conditions-dialog',
  templateUrl: './terms-and-conditions-dialog.component.html',
  styleUrls: ['./terms-and-conditions-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SvgIconComponent, NgIf, MatButtonModule, DatePipe, TranslateModule],
})
export class TermsAndConditionsDialogComponent {
  public readonly announcment: Announcment;

  public readonly imgPath = `assets/icons/dark_logo.svg`;
  private readonly destroyed$ = new Subject<void>();
  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: GlobalConfig,
    @Inject(DIALOG_DATA) private readonly data: DialogData<termsAndConditionsDialogPayload>,
    private readonly authService: AuthService,
    private readonly miscDataService: MiscDataService,
    private readonly loadingIndicatorService: LoadingIndicatorService,
    private readonly announcementService: AnnouncmentService,
    private readonly dialogService: DialogService
  ) {}
  public readonly termLink = this.config.termsAndConditionsUrl;
  public readonly privacyLink = this.config.privacyPolicyUrl;
  public readonly cutOffDate = this.config.conditionCutoff;
  public readonly textDate = this.formatEpochTime(Number.parseInt(this.cutOffDate));
  public readonly gracePeriod = this.data.payload.gracePeriod;
  public onDismissClick(): void {
    sessionStorage.setItem(TERMS_CLOSED, JSON.stringify(true));
    this.dialogService.closeAll();
  }

  public onAcceptClick() {
    this.announcementService.acceptTerms$().subscribe(() => {
      this.data.dialogRef.close();
      this.dialogService.closeAll();
    });
  }

  // Converts the epoch time into a human readable format
  public formatEpochTime(epochTime): string {
    // Need to multiply here as the epoch time in the config is in Seconds not Milliseconds
    const date = new Date(epochTime * 1000);
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };
    return date.toLocaleDateString('en-GB', options);
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
