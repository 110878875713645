import { HttpErrorResponse } from '@angular/common/http';
import { QuoteDataService } from '@tecex-api/data';
import { AddPartsResponse } from '@tecex-api/data/model/addPartsResponse';
import { GetPartsDetailsResponse } from '@tecex-api/data/model/models';
import { Observable, throwError, timer } from 'rxjs';
import { map, mergeMap, retryWhen } from 'rxjs/operators';
import { LineItem } from '../../common-quote/interfaces/line-item.interface';
import { LineItemsData } from '../../common-quote/interfaces/line-items-data.interface';

export abstract class BaseLineItemsDataService {
  protected readonly POLL_INTERVAL = 2000;
  protected readonly POLL_TIMEOUT = 150; // seconds / 2
  public abstract saveNewLineItems$(data: LineItemsData): Observable<LineItem[] | AddPartsResponse>;
  public abstract editLineItems$(data: LineItemsData): Observable<LineItem[]>;
  public abstract getLineItems$(SOID: string, accessToken: string): Observable<LineItem[]>;

  constructor(protected readonly quoteDataService: QuoteDataService) {}

  protected _getLineItems$(SOID: string, accessToken: string): Observable<GetPartsDetailsResponse> {
    return this.quoteDataService
      .getPartsDetails({
        SOID,
        Accesstoken: accessToken,
      })
      .pipe(
        map((resp) => {
          if (!!resp?.Part?.length) {
            return resp;
          }
          throw new Error(resp?.Message?.AWSResponseStatus);
        }),
        retryWhen((attempts$: Observable<Error>) =>
          attempts$.pipe(
            // @ts-ignore
            mergeMap((error, i) => {
              if (error instanceof HttpErrorResponse) {
                return throwError(() => error);
              }
              if (error.message.includes('400')) {
                throw new Error('Failed to save line items');
              }
              if (i + 1 < this.POLL_TIMEOUT) {
                return timer(this.POLL_INTERVAL);
              }
            })
          )
        )
      );
  }
}
