import { UpdatePartsRequestParts } from '@tecex-api/data/model/updatePartsRequestParts';
import { AddPartsRequestParts } from '@tecex-api/data/model/addPartsRequestParts';

export const sanitizePartPayload = (
  payloadParts: Array<UpdatePartsRequestParts> | Array<AddPartsRequestParts>
): Array<UpdatePartsRequestParts> | Array<AddPartsRequestParts> => {
  const indexArr: number[] = [];
  payloadParts.forEach((part, index) => {
    if (!part.Quantity || !part.UnitPrice) {
      indexArr.push(index);
    }
  });

  for (let i = indexArr.length - 1; i >= 0; i--) payloadParts.splice(indexArr[i], 1);

  return payloadParts;
};
