import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder } from '@ngneat/reactive-forms';
import { TranslateModule } from '@ngx-translate/core';
import { RecordBase } from '../record-base';
import { QuoteState } from '../../../../../enums/quote-list/quote-state.enum';
import { FormControlInputSuffixDirective } from '../../../../form-control/directives/form-control-input-suffix.directive';
import { CurrencyInputDirective } from '../../../../../directives/currency-input.directive';
import { FormControlComponent } from '../../../../form-control/components/form-control/form-control.component';
import { FormControlHintDirective } from '../../../../form-control/directives/form-control-hint.directive';
import { FormControlIconDirective } from '../../../../form-control/directives/form-control-icon.directive';
import { FormControlInputDirective } from '../../../../form-control/directives/form-control-input.directive';
import { FormControlLabelDirective } from '../../../../form-control/directives/form-control-label.directive';
import { DropdownComponent } from '../../../../dropdown/dropdown.component';
import { FormControlReadonlyValueDirective } from '../../../../form-control/directives/form-control-readonly-value.directive';

@Component({
  standalone: true,
  selector: 'app-question',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form-question.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    FormControlInputSuffixDirective,
    MatDatepickerModule,
    SvgIconComponent,
    MatFormFieldModule,
    MatInputModule,
    CurrencyInputDirective,
    FormControlComponent,
    FormControlHintDirective,
    FormControlIconDirective,
    FormControlInputDirective,
    FormControlLabelDirective,
    TranslateModule,
    DropdownComponent,
    FormControlReadonlyValueDirective,
  ],
})
export class DynamicFormQuestionComponent implements OnInit {
  @Input() field!: RecordBase;
  @Input() form!: any;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly datePipe: DatePipe
  ) {}

  public ngOnInit() {
    if (this.field.type === 'datetime' && !this.field.isEditable) {
      this.form = this.formBuilder.group({
        [this.field.fieldApiName]: [this.datePipe.transform(this.field.value, 'medium')],
      });
    } else if (this.field.type === 'datetime' && this.field.isEditable && this.field.value) {
      const dateISO = new Date(this.field.value).toISOString();
      const formatedDate = dateISO.slice(0, -1);
      this.form.controls[this.field.fieldApiName].setValue(formatedDate);
    }
    if (this.field.type === 'select' && this.field.isEditable) {
      this.field.picklistValues = this.field.picklistValues.map((item) => {
        return {
          viewValue: item.label,
          value: item.value,
        };
      });
    }
  }

  get isValid() {
    return this.form.controls[this.field.fieldApiName].valid;
  }

  public assessError(): string {
    return this.form.controls[this.field.fieldApiName].errors && this.form.controls[this.field.fieldApiName].touched
      ? 'This field is required'
      : null;
  }

  protected readonly QuoteState = QuoteState;
}
