import { ValidatorFn } from '@angular/forms';

export const phoneNumberValidator: () => ValidatorFn =
  () =>
  ({ value }) => {
    // valid e.g. (+34)3453212, +3677263646734, 20/3454556, 70-123-23-32
    const phoneRegexp = /^(?:\+?\(\d{1,3}\)|(?:\(\+?\d{1,3}\))|(?:\+?\d{1,3}))\/?(-? *\d)+$/;

    if (!value || phoneRegexp.test(value)) {
      // eslint-disable-next-line unicorn/no-useless-undefined
      return undefined;
    }

    return { phoneFormat: true };
  };

export const addressPhoneNumberValidator: () => ValidatorFn =
  () =>
  ({ value }) => {
    // Updated sc-85464: Only allow numbers
    const phoneRegexp = /\D/;

    if (!value || !phoneRegexp.test(value)) {
      // eslint-disable-next-line unicorn/no-useless-undefined
      return undefined;
    }

    return { phoneNumbersOnly: true };
  };
