import { ValidatorFn } from '@angular/forms';

// tslint:disable:no-null-keyword

interface Options {
  maxFractionalDigits?: number;
}

const defaultOptions: Options = {
  maxFractionalDigits: Number.POSITIVE_INFINITY,
};

export const numberValidator: (options?: Options) => ValidatorFn =
  (options = {}) =>
  ({ value }) => {
    const optionsWithDefault = { ...defaultOptions, ...options };

    return validateNumber(value, optionsWithDefault);
  };

// tslint:disable-next-line: cyclomatic-complexity
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validateNumber = (value: any, options: Options) => {
  if (value === null || value === undefined || value.length === 0) {
    return null;
  }

  if (typeof value !== 'number' || Number.isNaN(value)) {
    // eslint-disable-next-line id-blacklist
    return { number: true };
  }

  const optionsWithDefault = { ...defaultOptions, ...options };

  const fractionalPart = value.toString().split('.')[1];
  if (fractionalPart && fractionalPart.length > optionsWithDefault.maxFractionalDigits) {
    return { maxFractionalDigits: 2 };
  }

  return null;
};
