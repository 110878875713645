import { TrackingState } from '../enums/tracking-state.enum';

export const mapTrackerTitle = (state: TrackingState): string => {
  switch (state) {
    case TrackingState.InTransit: {
      return 'TRACKING.STATES.IN_TRANSIT';
    }
    case TrackingState.ExportComplete: {
      return 'TRACKING.STATES.EXPORT_COMPLETE';
    }
    case TrackingState.ArrivedInCountry: {
      return 'TRACKING.STATES.ARRIVED_IN_COUNTRY';
    }
    case TrackingState.ClearedCustoms: {
      return 'TRACKING.STATES.CLEARED_CUSTOMS';
    }
    case TrackingState.FinalDeliveryInProgress: {
      return 'TRACKING.STATES.FINAL_DELIVERY';
    }
    case TrackingState.Delivered: {
      return 'TRACKING.STATES.DELIVERED';
    }
    case TrackingState.Cancelled: {
      return 'TRACKING.STATES.CANCELLED';
    }
    default: {
      return state;
    }
  }
};
