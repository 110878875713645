import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import isNil from 'lodash/isNil';
import mixpanel from 'mixpanel-browser';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { MixpanelEvent } from '../enums/mixpanel-event.enum';
import { RouteSegment } from '../enums/route-segment.enum';
import { SourceApp } from '../enums/source-app.enum';
import { AuthService } from './auth.service';
import { TokenConfigService } from './token-config.service';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  private initialized = false;
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly tokenConfigService: TokenConfigService
  ) {
    const tokenUser$ = combineLatest([
      this.tokenConfigService.getTokens$(),
      this.authService.getUser$().pipe(
        distinctUntilChanged(),
        filter((user) => !isNil(user))
      ),
    ]);

    tokenUser$.subscribe((response) => {
      if (!this.initialized) {
        mixpanel.init(response[0].mixpanelToken, { debug: false });
        mixpanel.register({
          flavour: SourceApp.TecEx.toUpperCase(),
          platform: 'web',
        });
        this.initialized = true;
      }
      mixpanel.identify(response[1].id);
    });

    this.authService.logout$.subscribe(() => mixpanel.reset());

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        filter(
          (event) =>
            !(event as NavigationEnd).url.includes(RouteSegment.Logout) &&
            !(event as NavigationEnd).url.includes(RouteSegment.LoginCallback)
        )
      )
      .subscribe((routerEvent) => {
        const url = (routerEvent as NavigationEnd).url;
        if (url.includes('/quotes/')) {
          this.track(MixpanelEvent.ScreenView, {
            screen_name: url,
            screen_name_base: url.includes('roll-out') ? '/quotes/roll-out/' : '/quotes/shipment-order',
          });
        } else if (url.includes('quoteIdToReuse') || url.includes('rolloutIdToReuse') || url.includes('rolloutIdToAddNewShipments')) {
          const screen_name_base = url.slice(0, -19);
          this.track(MixpanelEvent.ScreenView, {
            screen_name: url,
            screen_name_base,
          });
        } else if (!url.includes('dashboard') && !url.includes('/shipments-list/')) {
          this.track(MixpanelEvent.ScreenView, { screen_name: url, screen_name_base: url });
        }
      });
  }

  public track(action: MixpanelEvent, properties?: { [key: string]: any }): void {
    if (this.initialized) {
      mixpanel.track(action, properties);
    }
  }

  public timeEvent(action: MixpanelEvent): void {
    mixpanel.time_event(action);
  }
}
