import { UpdateQuotePayload } from '../../../common-quote/interfaces/update-quote-payload.interface';
import { BeneficialOwnerDialogResult } from './beneficial-owner-dialog-result.interface';

export const mapBeneficialOwnerResult = (result: BeneficialOwnerDialogResult): Partial<UpdateQuotePayload> => ({
  intentionWithGoods: result.intentionWithGoods,
  intentionWithGoodsOther: result.otherDetails,
  contactName: result.companyDetails.name,
  beneficialOwnerCountry: result.companyDetails.country,
  beneficialOwnerAddress: result.companyDetails.address,
  buyerOrBoVatNumber: result.vatNumber,
  buyerRetainsOwnership: result.ownership,
  buyerBeneficialOwnerPostalCode: result.companyDetails.postalCode,
  buyerBeneficialOwnerCity: result.companyDetails.city,
  nameOfAddressedEntity: { id: result.companyDetails.id, name: result.companyDetails.name },
});
