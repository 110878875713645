import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgIf, AsyncPipe } from '@angular/common';
import { UpdateUserDetailsRequest } from '@tecex-api/data';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AnnouncmentService } from '../../services/announcment.service';
import { User } from '../../../../interfaces/user.interface';
import { OutOfOfficeSettingsService } from '../../../out-of-office-settings/out-of-office-settings.service';
import { ToastMessageService } from '../../../toast-message/toast-message.service';

@Component({
  selector: 'app-out-of-office-bar',
  templateUrl: './out-of-office-bar.component.html',
  styleUrls: ['./out-of-office-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, SvgIconComponent, AsyncPipe, TranslateModule],
})
export class OutOfOfficeBarComponent implements OnDestroy {
  @Input() public user: User;

  private readonly destroyed$ = new Subject<void>();

  constructor(
    private readonly announcmentService: AnnouncmentService,
    private readonly outOfOfficeSettingsService: OutOfOfficeSettingsService,
    private readonly toastMessageService: ToastMessageService,
    private readonly translateService: TranslateService
  ) {}

  public onTurnOff(): void {
    const payload: UpdateUserDetailsRequest = {
      OOOStartDate: '',
      OOOEndDate: '',
      OOOMessage: '',
      StandbyUser: '',
    };
    this.outOfOfficeSettingsService.updateOOOSettings$(payload).subscribe(() => {});
  }

  public onOpenSettings() {
    this.outOfOfficeSettingsService
      .openOOOSettings$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.toastMessageService.open(this.translateService.instant('PROFILE.OUT_OF_OFFICE.SUCCESSFULLY_UPDATED_OOO'));
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
