import { createAction, props } from '@ngrx/store';
import { QuoteBasicsForm } from '../../quote/interfaces/quote-basics-form.interface';

export const enter = createAction(
  '[New Quote Basics] Enter',
  props<{
    quoteIdToReuse?: string | null;
    rolloutIdToReuse?: string | null;
    rolloutIdToAddNewShipments?: string | null;
    quoteIdToDuplicate?: string | null;
  }>()
);

export const submit = createAction('[New Quote Basics] Submit', props<{ values: any; existingRolloutId?: string; analyticsData?: any }>());
export const submitSuccess = createAction(
  '[New Quote Basics] SubmitSuccess',
  props<{
    values: any;
    shipmentOrderIds: string[];
    id: string;
    freightId?: string;
    quoteReference: string;
    rolloutId: string;
    clientContact?: string;
  }>()
);
export const submitError = createAction('[New Quote Basics] SubmitError', props<{ error: string }>());

export const edit = createAction('[New Quote Basics] Edit');
export const editSuccess = createAction('[New Quote Basics] EditSuccess', props<{ values: QuoteBasicsForm }>());
export const editError = createAction('[New Quote Basics] EditError', props<{ error: string }>());

export const startNew = createAction('[New Quote Basics] startNew');

// For Roll-out detail getting with interval : Start
export const rollOutDetailIdSuccess = createAction(
  '[New Quote Basics] rollOutDetailIdSuccess',
  props<{ detailsOfUserWithRollout: { rollOutId: any; userDetail: any } }>()
);

export const rollOutDetail = createAction('[New Quote Basics] rollOutDetail');
export const rollOutDetailSuccess = createAction('[New Quote Basics] rollOutDetailSuccess');
export const rollOutDetailError = createAction('[New Quote Basics] rollOutDetailError', props<{ error: string }>());
// For Roll-out detail getting with interval : End

export const update = createAction('[New Quote Basics] update', props<{ values: QuoteBasicsForm }>());
