import { Injectable } from '@angular/core';
import isNil from 'lodash/isNil';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { ProfileDataService, UpdateUserDetailsRequest } from '@tecex-api/data';
import { EMPTY, Observable } from 'rxjs';
import { DialogService } from '../dialog/dialog.service';
import { LoadingIndicatorService } from '../loading-indicator/services/loading-indicator.service';
import { AuthService } from '../../services/auth.service';
import { ErrorNotificationService } from '../../services/error-notification.service';
import { SourceApp } from '../../enums/source-app.enum';
import { OutOfOfficeSettingsComponent } from './out-of-office-settings.component';

@Injectable({
  providedIn: 'root',
})
export class OutOfOfficeSettingsService {
  constructor(
    private readonly dialogService: DialogService,
    private readonly profileDataService: ProfileDataService,
    private readonly loadingIndicatorService: LoadingIndicatorService,
    private readonly authService: AuthService,
    private readonly errorNotificationService: ErrorNotificationService
  ) {}

  public openOOOSettings$(): any {
    return this.dialogService
      .open(OutOfOfficeSettingsComponent)
      .afterClosed$()
      .pipe(
        switchMap((result: any) => {
          if (isNil(result)) {
            return EMPTY;
          }
          return this.updateOOOSettings$(result.payload);
        })
      );
  }

  public updateOOOSettings$(data: UpdateUserDetailsRequest): Observable<void> {
    this.loadingIndicatorService.open();

    return this.authService.getUser$().pipe(
      switchMap((user) =>
        this.profileDataService
          .updateUserDetails({
            Accesstoken: user.accessToken,
            UserID: user.id,
            AppName: SourceApp.TecEx,
            ...data,
          })
          .pipe(
            switchMap((resp) => {
              return this.authService.updateProfile$();
            })
          )
      ),
      finalize(() => {
        window.location.reload();
        this.loadingIndicatorService.dispose();
      }),
      catchError((error) => {
        this.errorNotificationService.notifyAboutError(error, 'ERROR.FAILED_TO_UPDATE_PROFILE');

        return EMPTY;
      })
    );
  }
}
