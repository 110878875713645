<div class="grid-container">
  <div class="grid-container main-nav">
    <div class="main-nav-list">
      <mat-list role="navigation" [disableRipple]="false">
        <mat-list-item
          *ngFor="let menuItem of menuItems"
          [routerLink]="menuItem.link"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: menuItem.exact }"
          class="app-nav-drawer-item"
        >
          <svg-icon matListItemIcon [key]="menuItem.icon"></svg-icon>
          <span matListItemTitle>
            {{ menuItem.title | translate }}
          </span>
        </mat-list-item>
        <mat-list-item
          class="app-nav-drawer-item"
          (click)="toggleSettingsMenu()"
          [ngClass]="{ open: isSettingsMenuOpen, active: isSettingsRouteActive$ | async }"
        >
          <svg-icon matListItemIcon key="settings"></svg-icon>
          <span matListItemTitle>{{ 'NAVIGATION.SETTINGS' | translate }}</span>
          <svg-icon class="menu-expand-icon" [key]="isSettingsMenuOpen ? 'arrow-up' : 'arrow-down'"></svg-icon>
        </mat-list-item>
        <div *ngIf="isSettingsMenuOpen" class="secondary-menu" [@detailExpand]="isSettingsMenuOpen ? 'expanded' : 'collapsed'">
          <mat-list role="navigation" [disableRipple]="false">
            <mat-list-item
              mat-list-item
              *ngFor="let settingsMenuItem of settingsMenuItems"
              [routerLink]="settingsMenuItem.link"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: false }"
              class="app-nav-drawer-item"
            >
              <svg-icon matListItemIcon [key]="settingsMenuItem.icon"></svg-icon>
              <span matListItemTitle>
                {{ settingsMenuItem.title | translate }}
              </span>
            </mat-list-item>
          </mat-list>
        </div>
        <mat-list-item *ngIf="!isThirdParty" mat-list-item class="app-nav-drawer-item" (click)="showHelp()">
          <svg-icon matListItemIcon [key]="helpMenuItem.icon"></svg-icon>
          <span matListItemTitle>{{ helpMenuItem.title | translate }}</span>
        </mat-list-item>
      </mat-list>
    </div>
    <div class="version">{{ 'COMMON.VERSION' | translate }}: {{ version }}</div>
  </div>

  <div class="container profile">
    <div class="row align-items-center">
      <div class="col">
        <ng-container *ngIf="profileLink && user$ | async as user">
          <a [routerLink]="profileLink.link" class="d-flex align-items-center profile-link">
            <img class="picture" [src]="user.profilePicture | profilePicture" />
            <span class="text-small-regular user-name ml-2">{{ user.name }}</span>
          </a>
        </ng-container>
      </div>
      <ng-container *ngIf="logOutLink">
        <div class="col-auto">
          <div class="log-out">
            <a [routerLink]="logOutLink.link" class="d-flex align-items-center app-nav-drawer-item log-out-link">
              <svg-icon [key]="logOutLink.icon"></svg-icon>
              <span>{{ logOutLink.title | translate }}</span>
            </a>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
