import { FreightCosts } from '@global/interfaces/freight-costs.interface';
import { ChargeableWeightDialogPackageVM } from '@global/interfaces/package.vm';
import { FinalCostsState } from '@global/modules/common-quote/enums/final-costs-state.enum';
import { createAction, props } from '@ngrx/store';
import { FinalCosts } from '../interfaces/final-costs.interface';

export const enter = createAction('[New Quote Final Costs] enter');

export const loadFinalCosts = createAction('[New Quote Final Costs] loadFinalCosts');
export const loadFinalCostsSuccess = createAction(
  '[New Quote Final Costs] loadFinalCostsSuccess',
  props<{ finalCosts: FinalCosts; state: FinalCostsState }>()
);
export const loadFinalCostsError = createAction('[New Quote Final Costs] loadFinalCostsError', props<{ error: string }>());

export const updateFinalCostsState = createAction('[New Quote Final Costs] updateFinalCostsState', props<{ state: FinalCostsState }>());

export const addPackages = createAction('[New Quote Final Costs] addPackages');

export const addPackagesSuccess = createAction(
  '[New Quote Final Costs] addPackagesSuccess',
  props<{ packages: ChargeableWeightDialogPackageVM[] }>()
);
export const addPackagesError = createAction('[New Quote Final Costs] addPackagesError', props<{ error: string }>());

export const acceptQuote = createAction('[New Quote Final Costs] acceptQuote');

export const acceptIORQuote = createAction('[New Quote Final Costs] acceptIORQuote', props<{ quoteId: string }>());

export const openSelectCarrierDialog = createAction('[New Quote Final Costs] openSelectCarrierDialog');

export const toggleTecExShippingFee = createAction('[New Quote Final Costs] toggleTecExShippingFee', props<{ isEnabled: boolean }>());

export const toggleLiabilityCoverFee = createAction('[New Quote Final Costs] toggleLiabilityCoverFee', props<{ isEnabled: boolean }>());

export const updateShippingFees = createAction(
  '[New Quote Final Costs] updateShippingFees',
  props<{ tecExShippingServiceFeeEnabled: boolean; liabilityCoverFeeEnabled: boolean }>()
);

export const updateFreightCosts = createAction('[New Quote Final Costs] updateFreightCosts', props<{ freightCosts: FreightCosts }>());

export const downloadCostEstimate = createAction('[New Quote Final Costs] downloadCostEstimate');

export const cancelQuote = createAction('[New Quote Final Cost] cancelQuote');

export const leave = createAction('[New Quote Final Costs] leave');
