import { ShipmentOrderThirdParty } from '../interfaces/shipment-order-third-party.interface';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const mapUserToThirdParty = (contact: any): ShipmentOrderThirdParty => ({
  affiliateType: contact.AffiliateType,
  thirdPartyCompany: contact.ThirdPartyCompany,
  name: contact.AfuserName,
  thirdPartyEmail: contact.ThirdPartyEmail,
  id: contact.id,
});
