import { ActiveInactive, ProfileAddress, RegistrationAddress, UserDefaultsResponse } from '@tecex-api/data';
import { AddressType } from '../../../enums/address-type.enum';
import { AddressBook } from '../../../interfaces/address/address-book.intefrace';
import { AddressCardAddressVM } from '../../../interfaces/address/address.vm';

export const mapAddressBook = (userDefaults: UserDefaultsResponse): AddressBook => ({
  pickupAddresses: userDefaults.PickupAddress.filter((address) => address.AddressStatus === ActiveInactive.ACTIVE).map((address) =>
    mapProfileAddress(AddressType.Pickup, address)
  ),
  deliveryAddresses: userDefaults.FinalDestinationsAddress.filter((address) => address.AddressStatus === ActiveInactive.ACTIVE).map(
    (address) => mapProfileAddress(AddressType.Delivery, address)
  ),
  registrationAddresses: userDefaults.RegistrationAddress.filter((address) => address.AddressStatus === ActiveInactive.ACTIVE).map(
    (address) => mapRegistrationAddress(AddressType.Exporter, address)
  ),
});

const mapProfileAddress = (addressType: AddressType, address: ProfileAddress): AddressCardAddressVM => ({
  id: address.Id,
  tag: address.PickupaddressName,
  companyName: address.CompanyName,
  contactPerson: address.ContactFullName,
  email: address.ContactEmail,
  phone: address.ContactPhoneNumber,
  additionalPhone: address.AdditionalContactNumber,
  country: address.Country,
  state: address.Province,
  city: address.City,
  streetAddress: address.Address,
  streetAddressTwo: address.Address2,
  streetAddressThree: address.Address3,
  zip: address.PostalCode,
  comment: address.Comments,
  isDefault: addressType === AddressType.Pickup ? address.Default : false,
  isActive: address.AddressStatus === ActiveInactive.ACTIVE,
  pickupPreference: address.PickupPreference,
  altContactPerson: address.Alt_Contact_Full_Name,
  altEmail: address.Alt_Contact_Email,
  altPhone: address.Alt_Contact_Phone_Number,
  altAdditionalPhone: address.Alt_AdditionalContactNumber,
  taxNumber: address.Tax_Name_Number,
});

const mapRegistrationAddress = (addressType: AddressType, address: RegistrationAddress): AddressCardAddressVM => ({
  id: address.Id,
  tag: address.RegistrationaddressName,
  companyName: address.CompanyName,
  contactPerson: address.ContactFullName,
  email: address.ContactEmail,
  phone: address.ContactPhoneNumber,
  additionalPhone: address.AdditionalContactNumber,
  country: address.Country,
  state: address.Province,
  city: address.City,
  streetAddress: address.Address,
  streetAddressTwo: address.Address2,
  streetAddressThree: address.Address3,
  zip: address.PostalCode,
  comment: address.Comments,
  isDefault: addressType === AddressType.Exporter ? address.Default : false,
  isActive: address.AddressStatus === ActiveInactive.ACTIVE,
  pickupPreference: address.PickupPreference,
});
