import { TaskDetails } from '@tecex-api/data';
import isNil from 'lodash/isNil';
import { WeightUnit } from '../../../enums/weight-unit.enum';
import { mapShipmentStatus } from '../../../helpers/map-shipment-status.helper';
import { mapTaskStates } from '../../../helpers/map-task-states.helper';
import { mapTrackingState } from '../../../helpers/map-tracking-state.helper';
import { TaskVM } from '../types/task.vm';

export const mapTaskShipmentOrder = (taskDetails: TaskDetails): TaskVM['shipmentOrder'] => {
  if (isNil(taskDetails.SOInfo?.[0]?.ShipmentOrderID)) {
    return void 0;
  }

  return {
    id: taskDetails.SOInfo?.[0]?.LinkedShipment ? taskDetails.SOInfo?.[0]?.LinkedShipment : taskDetails.SOInfo?.[0]?.ShipmentOrderID,
    name: taskDetails.SOInfo?.[0]?.NCPQuoteReference,
    reference: taskDetails.SOInfo?.[0]?.ClientReference,
    status: mapShipmentStatus(taskDetails.SOInfo?.[0]?.NCPShippingStatus),
    shipmentTaskStates: {
      customsCompliance: mapTaskStates(taskDetails.SOInfo?.[0]?.CustomsCompliance),
      shippingDocuments: mapTaskStates(taskDetails.SOInfo?.[0]?.ShippingDocuments),
      pickUpCoordination: mapTaskStates(taskDetails.SOInfo?.[0]?.PickupCoordination),
      invoicePayment: mapTaskStates(taskDetails.SOInfo?.[0]?.InvoicePayment),
    },
    trackingState: mapTrackingState(taskDetails.SOInfo?.[0]?.ShippingStatus),
    acceptanceDate: taskDetails.SOInfo?.[0]?.Cost_Estimate_AcceptanceDate,
    amount: taskDetails.SOInfo?.[0]?.ShipmentValueUSD,
    numberOfLocations: taskDetails.SOInfo?.[0]?.FinalDeliveriesNew,
    estimatedWeight: taskDetails.SOInfo?.[0]?.ChargeableWeight,
    estimetedWeightUnit: WeightUnit.Kg,
    numberOfLineItems: taskDetails.SOInfo?.[0]?.ofLineItems,
    preferredFreightMethod: taskDetails.SOInfo?.[0]?.PreferredFreightmethod,
    buyerAccount: taskDetails.SOInfo?.[0]?.BuyerAccount,
  };
};
