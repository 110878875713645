import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgIf, AsyncPipe } from '@angular/common';
import { AnnouncmentService } from '../../services/announcment.service';

@Component({
  selector: 'app-announcment-bar',
  templateUrl: './announcment-bar.component.html',
  styleUrls: ['./announcment-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, SvgIconComponent, AsyncPipe],
})
export class AnnouncmentBarComponent {
  public message$ = this.announcmentService.getMessage$();

  constructor(private readonly announcmentService: AnnouncmentService) {}

  public onCloseClicked(): void {
    this.announcmentService.closeAnnouncment();
  }
}
