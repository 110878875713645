import { ValidatorFn } from '@angular/forms';

export const validateWeekendDate: () => ValidatorFn =
  () =>
  ({ value }) => {
    if (value === null) {
      return null;
    }
    return value.getDay() === 0 || value.getDay() === 6 ? { weekendDate: true } : null;
  };
