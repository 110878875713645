import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Validators, FormsModule, ReactiveFormsModule, FormGroup, FormArray } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgSwitch, NgSwitchCase, NgIf, NgForOf, CurrencyPipe, NgClass } from '@angular/common';
import { FormBuilder, FormControl } from '@ngneat/reactive-forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DIALOG_DATA } from '../../../dialog/dialog.tokens';
import { DialogData } from '../../../dialog/interfaces/dialog-data.interface';
import { TooltipDirective } from '../../../tooltip/tooltip.directive';
import { SelectionComponent } from '../../../selection/selection.component';
import { ShipmentSummaryComponent } from '../shipment-summary/shipment-summary.component';
import { MessageButtonComponent } from '../../../message-button/message-button.component';
import { LineItemsColumn } from '../../../../enums/line-items-column.enum';
import { ChildRecordsDialogPayload } from './child-records-dialog-payload.interface';
import { DynamicFormQuestionComponent } from './dynamic-form/dynamic-form-question.component';
import { ChildRecordsDialogService } from './child-records-dialog.service';

@Component({
  selector: 'app-child-records',
  templateUrl: './child-records-dialog.component.html',
  styleUrls: ['./child-records-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase,
    MessageButtonComponent,
    NgIf,
    ShipmentSummaryComponent,
    SelectionComponent,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatButtonModule,
    TooltipDirective,
    TranslateModule,
    NgForOf,
    CurrencyPipe,
    MatFormFieldModule,
    MatSelectModule,
    NgClass,
    DynamicFormQuestionComponent,
  ],
})
export class ChildRecordsDialogComponent implements OnInit {
  public initialRecords: any[];
  form: FormGroup;
  recordsHeaders = [];
  isValid = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly childRecordService: ChildRecordsDialogService,
    @Inject(DIALOG_DATA) public readonly data: DialogData<ChildRecordsDialogPayload, boolean>
  ) {
    this.initialRecords = this.data.payload.response.records;
  }

  get records() {
    return this.form.controls['records'] as FormArray;
  }

  ngOnInit() {
    // Set headers
    this.initialRecords[0].forEach((field) => {
      this.recordsHeaders.push({ label: field.fieldLabel });
    });

    // Initialise form and recordsArray
    this.form = this.formBuilder.group({
      records: this.formBuilder.array([]),
    });

    this.form.statusChanges.pipe().subscribe(() => {
      this.isValid = this.form.valid;
    });

    // Create formGroup for each record
    this.initialRecords.forEach((record) => {
      const group: any = {};
      record.forEach((question) => {
        group[question.fieldApiName] =
          question.isEditable && question.type === 'select'
            ? new FormControl({ value: question.value, viewValue: question.value }, Validators.required)
            : question.isEditable && question.type !== 'lookup'
            ? new FormControl(question.value, Validators.required)
            : question.type === 'lookup'
            ? new FormControl(question.referenceName)
            : new FormControl(question.value);
      });
      this.records.push(this.formBuilder.group(group));
    });
  }

  public onCancelClick(): void {
    this.data.dialogRef.close();
  }

  public onSubmitClick(): void {
    if (this.data.payload.response.isEditable) {
      this.childRecordService.updateChildRecords$(this.records, this.data.payload.response).subscribe((resp) => {
        this.data.dialogRef.close(true);
      });
    } else {
      this.data.dialogRef.close(true);
    }
  }

  public onMessageClick(): void {
    this.data.payload.onMsgClickHandler();
  }

  protected readonly LineItemsColumn = LineItemsColumn;
}
