import { LoadableStateReducerHelper } from '@global/helpers/loadable-state-reducer.helper';
import { Loadable } from '@global/interfaces/loadable.interface';
import { MessageThread } from '@global/interfaces/messages/message-thread.interface';
import { SendbirdChannelInfo } from '@global/modules/message-thread/services/sendbird-message.service';
import { ShipmentDetailsShipmentVM } from '@modules/shipments/interfaces/shipment-details-shipment.vm';
import { createReducer, on } from '@ngrx/store';
import * as ThirdPartyActions from '../actions/third-party.actions';
import { ThirdPartyDetailsTabIndex } from '../third-party-details-tab-index.enum';
import { ThirdPartyTaskVM } from '../third-party-tasks.vm';

export const thirdPartyKey = 'third-party';

export interface State {
  messageThreads: Loadable<MessageThread[]>;
  tasks: Loadable<ThirdPartyTaskVM[]>;
  selectedShipmentId: string;
  selectedTaskShipmentAccountId: string;
  shipment: Loadable<ShipmentDetailsShipmentVM>;
  selectedTab: ThirdPartyDetailsTabIndex;
  SendbirdUnreadChannels: Loadable<SendbirdChannelInfo[]>;
  selectedTasks: string;
  countOfComplianceAndTracking: Loadable<any>;
  countOfAllMessages: Loadable<any>;
  selectSOsAccountId: string;
}
export const initialState: State = {
  messageThreads: {
    isLoading: false,
    data: [],
  },
  tasks: {
    isLoading: false,
    data: [],
  },
  selectedShipmentId: undefined,
  selectedTaskShipmentAccountId: undefined,
  shipment: {
    isLoading: false,
    data: undefined,
  },
  selectedTab: ThirdPartyDetailsTabIndex.Messages,
  SendbirdUnreadChannels: {
    data: [],
    isLoading: false,
  },
  selectedTasks: undefined,
  countOfComplianceAndTracking: {
    data: {},
    isLoading: false,
  },
  countOfAllMessages: {
    data: {},
    isLoading: false,
  },
  selectSOsAccountId: undefined,
};

export const reducer = createReducer(
  initialState,

  on(ThirdPartyActions.getMessageThreads, (state) => ({
    ...state,
    messageThreads: LoadableStateReducerHelper.startLoad(state.messageThreads),
  })),

  on(ThirdPartyActions.getMessageThreadsSuccess, (state, { messageThreads }) => ({
    ...state,
    messageThreads: LoadableStateReducerHelper.loadSuccess(messageThreads),
  })),

  on(ThirdPartyActions.getMessageThreadsError, (state, { error }) => ({
    ...state,
    messageThreads: LoadableStateReducerHelper.loadError(state.messageThreads, error),
  })),

  on(ThirdPartyActions.removeMessage, (state, { messageId }) => ({
    ...state,
    messageThreads: {
      ...state.messageThreads,
      data: state.messageThreads.data.filter((item) => item.id !== messageId),
    },
  })),

  on(
    ThirdPartyActions.selectShipment,
    (state, { shipmentId }): State => ({
      ...state,
      selectedShipmentId: shipmentId,
    })
  ),

  // For getting the selected shipment wise accountID :
  on(
    ThirdPartyActions.selectShipment,
    (state, { accountIdFromSOs }): State => ({
      ...state,
      selectSOsAccountId: accountIdFromSOs,
    })
  ),

  on(
    ThirdPartyActions.selectShipmentTabIndex,
    (state, { selectedTabIndex }): State => ({
      ...state,
      selectedTab: selectedTabIndex,
    })
  ),

  on(
    ThirdPartyActions.openDropDown,
    (state, { selectedTaskAccountId }): State => ({
      ...state,
      selectedTaskShipmentAccountId: selectedTaskAccountId,
    })
  ),
  on(ThirdPartyActions.loadShipment, (state) => ({
    ...state,
    shipment: LoadableStateReducerHelper.startLoad(state.shipment),
  })),

  on(ThirdPartyActions.loadShipmentSuccess, (state, { shipment }) => ({
    ...state,
    shipment: LoadableStateReducerHelper.loadSuccess(shipment),
  })),

  on(ThirdPartyActions.loadShipmentError, (state, { error }) => ({
    ...state,
    shipment: LoadableStateReducerHelper.loadError(state.shipment, error),
  })),

  // on(ThirdPartyActions.getTasks, (state) => ({
  //   ...state,
  //   tasks: LoadableStateReducerHelper.startLoad(state.tasks),
  // })),

  on(ThirdPartyActions.getTasksSuccess, (state, { tasks }) => ({
    ...state,
    tasks: LoadableStateReducerHelper.loadSuccess(tasks),
  })),

  on(ThirdPartyActions.getTasksError, (state, { error }) => ({
    ...state,
    tasks: LoadableStateReducerHelper.loadError(state.tasks, error),
  })),

  // For un-read channel list : Start
  on(ThirdPartyActions.loadSendBirdUnreadChannels, (state) => ({
    ...state,
    SendbirdUnreadChannels: LoadableStateReducerHelper.startLoad(state.SendbirdUnreadChannels),
  })),

  on(ThirdPartyActions.loadSendBirdUnreadChannelsSuccess, (state, { unreadChannels }) => ({
    ...state,
    SendbirdUnreadChannels: LoadableStateReducerHelper.loadSuccess(unreadChannels),
  })),
  // For un-read channel list : End

  // For getting the count of unread messages :
  on(ThirdPartyActions.loadAllMessagesSuccess, (state, { totalCountOfMessages }) => ({
    ...state,
    countOfAllMessages: LoadableStateReducerHelper.loadSuccess(totalCountOfMessages),
  })),

  // For getting the compliance and tracking counts : Start
  on(ThirdPartyActions.countOfComplianceAndTracking, (state) => ({
    ...state,
    countOfComplianceAndTracking: LoadableStateReducerHelper.startLoad(state.countOfComplianceAndTracking),
  })),

  on(ThirdPartyActions.countOfComplianceAndTrackingSuccess, (state, { countObject }) => ({
    ...state,
    countOfComplianceAndTracking: LoadableStateReducerHelper.loadSuccess(countObject),
  })),

  on(ThirdPartyActions.countOfComplianceAndTrackingError, (state, { error }) => ({
    ...state,
    countOfComplianceAndTracking: LoadableStateReducerHelper.loadError(state.countOfComplianceAndTracking, error),
  })),
  // For getting the compliance and tracking counts : End

  on(
    ThirdPartyActions.selectShipmentTasks,
    (state, { selectedTasks }): State => ({
      ...state,
      selectedTasks,
    })
  ),

  // For getting the compliance and tracking counts : Start
  on(ThirdPartyActions.countOfComplianceAndTracking, (state) => ({
    ...state,
    countOfComplianceAndTracking: LoadableStateReducerHelper.startLoad(state.countOfComplianceAndTracking),
  })),

  on(ThirdPartyActions.countOfComplianceAndTrackingSuccess, (state, { countObject }) => ({
    ...state,
    countOfComplianceAndTracking: LoadableStateReducerHelper.loadSuccess(countObject),
  })),

  on(ThirdPartyActions.countOfComplianceAndTrackingError, (state, { error }) => ({
    ...state,
    countOfComplianceAndTracking: LoadableStateReducerHelper.loadError(state.countOfComplianceAndTracking, error),
  })),
  // For getting the compliance and tracking counts : End

  on(ThirdPartyActions.updateMessageThreadReadFlag, (state, { sendBirdChannelURL }) => ({
    ...state,
    SendbirdUnreadChannels: {
      ...state.SendbirdUnreadChannels,
      data: state.SendbirdUnreadChannels.data.map((item) => {
        if (item.sendbirdChannelUrl === sendBirdChannelURL) {
          return { ...item, isUnread: false };
        }
        return item;
      }),
    },
  })),

  on(ThirdPartyActions.leave, (): State => initialState)
);
