import { NewQuoteDefaults, NewRollOutDetailDefaults } from '@global/interfaces/new-quote-defaults.interface';
import { LineItem } from '@global/modules/common-quote/interfaces/line-item.interface';
import { MessageButtonUserVM } from '@global/modules/message-button/user.vm';
import { createAction, props } from '@ngrx/store';
import { QuoteBasicsForm } from '../../quote/interfaces/quote-basics-form.interface';

export const loadExistingQuote = createAction('[New Quote] LoadExistingQuote', props<{ quoteId: string }>());
export const loadExistingQuoteSuccess = createAction(
  '[New Quote] LoadExistingQuoteSuccess',
  props<{ basics: QuoteBasicsForm; lineItems: LineItem[]; defaults: Partial<NewQuoteDefaults> }>()
);
export const loadExistingQuoteError = createAction('[New Quote] LoadExistingQuoteError', props<{ error: string }>());

export const loadExistingRollout = createAction('[New Quote] LoadExistingRollout', props<{ rolloutId: string }>());
export const loadExistingRolloutSuccess = createAction(
  '[New Quote] LoadExistingRolloutSuccess',
  props<{ basics: QuoteBasicsForm; lineItems: LineItem[] }>()
);
export const loadExistingRolloutError = createAction('[New Quote] LoadExistingRolloutError', props<{ error: string }>());

export const loadExistingRolloutToAddNewShipments = createAction(
  '[New Quote] LoadExistingRolloutToAddNewShipments',
  props<{ rolloutId: string }>()
);
export const loadExistingRolloutToAddNewShipmentsSuccess = createAction(
  '[New Quote] LoadExistingRolloutToAddNewShipmentsSuccess',
  props<{ basics: Partial<QuoteBasicsForm>; defaults: Partial<NewQuoteDefaults>; rollOutDetail: any }>()
);

export const leave = createAction('[New Quote] Leave');

export const startNew = createAction('[New Quote] startNew');
export const reuseQuote = createAction('[New Quote] reuseQuote', props<{ id: string }>());

export const loadExistingRollOutDetailSuccess = createAction(
  '[New Quote] loadExistingRollOutDetailSuccess',
  props<{ existingRollOutDetail: Partial<NewRollOutDetailDefaults> }>()
);

export const loadExistingRODetailFullSuccess = createAction(
  '[New Quote] loadExistingRODetailFullSuccess',
  props<{ loadExistingRoDetail: any }>()
);

export const newMessage = createAction('[New Quote] NewMessage', props<{ teamMember: MessageButtonUserVM; shipmentDetail?: any }>());

export const loadExistingRolloutAndQuote = createAction(
  '[New Quote] LoadExistingRolloutAndQuote',
  props<{ rolloutId: string; quoteId: string }>()
);
export const loadExistingRolloutAndQuoteSuccess = createAction(
  '[New Quote] LoadExistingRolloutAndQuoteSuccess',
  props<{ basics: QuoteBasicsForm; lineItems: LineItem[]; defaults: Partial<NewQuoteDefaults> }>()
);
export const loadExistingRolloutAndQuoteError = createAction('[New Quote] LoadExistingRolloutAndQuoteError', props<{ error: string }>());
export const requestQuoteApproval = createAction('[New Quote] requestQuoteApproval');
