import { RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as commonFromMessages from './common-messages.reducer';

export const messagesFeatureKey = 'commonMessages';

export interface MessagesState {
  [commonFromMessages.messagesKey]: commonFromMessages.State;
}

export interface State {
  router: RouterReducerState<any>;
}

export interface AppState extends State {
  [messagesFeatureKey]: MessagesState;
}

export const reducers: ActionReducerMap<MessagesState> = {
  [commonFromMessages.messagesKey]: commonFromMessages.reducer,
};

export const selectMessagesFeatureState = createFeatureSelector<MessagesState>(messagesFeatureKey);

export const selectMessagesState = createSelector(selectMessagesFeatureState, (state) => state[commonFromMessages.messagesKey]);

// Selector for the getting the data of shipment-order participant :
export const selectListOfShipmentOrderParticipant = createSelector(selectMessagesState, (state) => state.shipmentOrderParticipant.data);

// Selector for the getting the data of account participant :
export const selectListOfAccountParticipant = createSelector(selectMessagesState, (state) => state.accountParticipant.data);

// Selector for the getting the data of Detail message channel :
export const selectDetailOfMessageCreationCase = createSelector(selectMessagesState, (state) => state.messageChannelCreation.data);

// Selector for the getting the response of updated message case :
export const selectResponseOfUpdateMessageCase = createSelector(selectMessagesState, (state) => state.updateMessageCase.data);

// Selector for the getting the response of deleted message case :
export const selectResponseOfDeleteMessageCase = createSelector(selectMessagesState, (state) => state.deleteMessageCase.data);

export const selectShipmentOrderPeopleDataLoadingState = createSelector(
  selectMessagesState,
  (state) => state.shipmentOrderParticipant.isLoading
);

export const selectShipmentOrderPeople = createSelector(selectMessagesState, (state) => state.shipmentOrderParticipant);

export const selectAccountPeopleDataLoadingState = createSelector(selectMessagesState, (state) => state.accountParticipant.isLoading);

// Selector for the getting awstokens
export const selectAwstoken = createSelector(selectMessagesState, (state) => state.awsToken.data);

// Selector for the channel info detail :
export const selectChannelInfoDetail = createSelector(selectMessagesState, (state) => state.channelInfoDetail.data);

// Selector for the channel info detail loading state :
export const selectChannelInfoDetailLoadingState = createSelector(selectMessagesState, (state) => state.channelInfoDetail.isLoading);
