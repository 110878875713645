import { ActiveInactive, CreateAddress, CreateAddressForExporter } from '@tecex-api/data';
import { AddressCardAddressVM } from '../../../interfaces/address/address.vm';

export const mapAddressCreatePayload = (address: AddressCardAddressVM): CreateAddress => ({
  DefaultAddress: address.isDefault,
  CompanyName: address.companyName,
  City: address.city,
  All_Countries: address.country,
  Name: address.tag,
  Contact_Email: address.email,
  Contact_Full_Name: address.contactPerson,
  Province: address.state,
  Address1: address.streetAddress,
  Address2: address.streetAddressTwo,
  Address3: address.streetAddressThree,
  Postal_Code: address.zip,
  Contact_Phone_Number: address.phone,
  AdditionalNumber: address.additionalPhone,
  AddressStatus: address.isActive ? ActiveInactive.ACTIVE : ActiveInactive.INACTIVE,
  PickupPreference: address.pickupPreference,
  Alt_Contact_Phone_Number: address.altPhone,
  Alt_Contact_Full_Name: address.altContactPerson,
  Alt_Contact_Email: address.altEmail,
  Alt_AdditionalContactNumber: address.altAdditionalPhone,
  Tax_Name_Number: address.taxNumber,
});

export const mapAddressCreateForExporterPayload = (address: AddressCardAddressVM): CreateAddressForExporter => ({
  DefaultAddress: address.isDefault,
  CompanyName: address.companyName,
  City: address.city,
  All_Countries: address.country,
  Name: address.tag,
  Contact_Email: address.email,
  Contact_Full_Name: address.contactPerson,
  Province: address.state,
  Address1: address.streetAddress,
  Address2: address.streetAddressTwo,
  Address3: address.streetAddressThree,
  Postal_Code: address.zip,
  Contact_Phone_Number: address.phone,
  AdditionalNumber: address.additionalPhone,
  AddressStatus: address.isActive ? ActiveInactive.ACTIVE : ActiveInactive.INACTIVE,
  PickupPreference: address.pickupPreference,
  taxNumber: address.taxNumber,
  Comments: address.comment,
});
