<div
  *ngIf="type !== 'radio'; else radioSelect"
  class="selection-container"
  [class.error]="hasError$ | async"
  [class.disabled]="isDisabled"
  [class.highlighted]="isHighlighted && (hasError$ | async) === false"
>
  <div
    *ngFor="let item of items"
    class="text-tiny-bold select"
    [class.color-text-tertiary]="item !== selectedItem"
    [class.active]="item === selectedItem"
    (click)="onItemClick(item)"
  >
    <svg-icon key="check" class="icon"></svg-icon>
    <span class="label">{{ getLabel(item) }}</span>
  </div>
</div>
<ng-template #radioSelect>
  <mat-radio-group aria-labelledby="radio-group-label" class="radio-group">
    <mat-radio-button class="radio-button" *ngFor="let item of items" [value]="item" (click)="onItemClick(item)">
      {{ getLabel(item) }}
    </mat-radio-button>
  </mat-radio-group>
</ng-template>
