<div class="address-card mb-3" [class.selected]="isSelected">
  <div class="inner-box">
    <div class="d-flex">
      <ng-container *ngIf="isSelectable">
        <mat-radio-button [checked]="isSelected" (change)="onRadioChange()"></mat-radio-button>
      </ng-container>
      <div *ngIf="!isSelectable">
        <span class="dot"></span>
      </div>
      <div class="pl-2 pl-sm-3" [class.column-display]="layout === 'column'" [class.row-display]="layout === 'row'">
        <div class="p-1">
          <div class="text-reduced-bold title" [class.selected]="isSelected">{{ party.AfuserName }}</div>
          <div class="text-reduced-regular addressed">{{ party.ThirdPartyCompany }}</div>
        </div>
        <div class="text-micro-regular address-data p-1">
          <div>{{ party.ThirdPartyEmail }}</div>
        </div>
      </div>
      <div class="icon-container">
        <svg-icon *ngIf="canDelete" key="bin" class="action-icon color-error" (click)="onDeleteClick()"></svg-icon>
      </div>
    </div>
    <div *ngIf="party.PreferredThirdParty" class="default-address">{{ 'QUOTE.THIRD_PARTY_DIALOG.PREFERED_THIRD_PARTY' | translate }}</div>
  </div>
</div>
