import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { MatRadioModule } from '@angular/material/radio';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-third-party-list',
  templateUrl: './third-party-list.component.html',
  styleUrls: ['./third-party-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatRadioModule, SvgIconComponent, TranslateModule],
})
export class ThirdPartyListComponent {
  @Input() public party: any;
  @Input() public isSelected = false;
  @Input() public isSelectable = true;
  @Input() public canEdit = true;
  @Input() public canDelete = false;
  @Input() public layout: 'row' | 'column' = 'column';
  @Output() public selectPartyWithRadio = new EventEmitter<any>();
  @Output() public deleteParty = new EventEmitter<any>();

  public onRadioChange(): void {
    this.selectPartyWithRadio.emit(this.party);
  }

  public onDeleteClick(): void {
    this.deleteParty.emit();
  }
}
