<div class="horizontal-line" *ngIf="newMessagePushedId === sendbirdmessage?.createdAt && unreadMessageCount === 0">
  <span class="line-text"> Last read</span>
</div>

<div class="horizontal-line" *ngIf="lastUnreadMessage === sendbirdmessage?.createdAt && unreadMessageCount > 0">
  <span class="line-text"> Last read</span>
</div>

<ng-container #messge *ngIf="sendbirdmessage?.customType === 'awsFile' || sendbirdmessage?.messageType === 'user'">
  <div class="message-container py-4" [class.own]="sendbirdmessage?.sender?.userId === userId">
    <img class="profile-picture picture" [src]="message?.user?.pictureUrl | profilePicture" />
    <div
      #htmlString
      class="message d-flex flex-column p-3"
      [ngStyle]="{ cursor: sendbirdmessage?.messageType === 'file' ? 'pointer' : 'text' }"
      (click)="getImage($event, downloadImageTemplate)"
    >
      <!-- TODO: There is no way now to find out if it is a TecEx message or not -->
      <ng-container *ngIf="sendbirdmessage?.messageType !== 'file' && sendbirdmessage?.messageType !== 'admin'">
        <div
          class="body text-tiny-regular"
          [innerHTML]="body"
          [ngStyle]="{ cursor: sendbirdmessage?.message.includes('img id') ? 'pointer' : 'text' }"
        ></div>
      </ng-container>

      <ng-container *ngIf="sendbirdmessage?.customType === 'awsFile'">
        <div class="attachments">
          <ng-container *ngIf="sendbirdmessage.fileInfoList">
            <!-- Loop through fileInfoList if it exists -->
            <div *ngFor="let fileInfo of sendbirdmessage.fileInfoList" class="text-reduced-bold d-flex align-items-center attachment">
              <div [appTooltip]="!isUserJoined ? ('MESSAGES.CANNOT_DOWNLOAD' | translate) : ''" appTooltipStrategy="hover">
                <a
                  [ngClass]="{ 'word-break-file-name': fileInfo.fileName.length > 128, 'not-in-channel': !isUserJoined }"
                  (click)="onS3fileMessageDownloadClick(fileInfo.plainUrl)"
                >
                  <svg-icon key="attachment" class="mr-1"></svg-icon> {{ fileInfo.fileName }}
                </a>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="!sendbirdmessage.fileInfoList">
            <div [appTooltip]="!isUserJoined ? ('MESSAGES.CANNOT_DOWNLOAD' | translate) : ''" appTooltipStrategy="hover">
              <a
                class="text-reduced-bold d-flex align-items-center attachment"
                [ngClass]="{ 'word-break-file-name': sendbirdmessage.name.length > 128, 'not-in-channel': !isUserJoined }"
                (click)="onS3fileMessageDownloadClick(sendbirdmessage.plainUrl)"
              >
                <svg-icon key="attachment" class="mr-1"></svg-icon> {{ sendbirdmessage.name }}
              </a>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <div class="footer d-flex justify-content-between mt-3">
        <div class="attachments">
          <ng-container *ngIf="sendbirdmessage?.messageType === 'file'">
            <img
              *ngIf="sendbirdmessage?.thumbnails && sendbirdmessage.thumbnails.length > 1"
              src="{{ sendbirdmessage?.thumbnails[1]?.url }}"
            />
          </ng-container>
        </div>
        <div
          class="date text-micro-regular d-flex align-items-end asd"
          [class.color-background-04]="sendbirdmessage?.sender?.userId === userId"
        >
          {{ sendbirdmessage?.createdAt | sendbirdMessageDate }}
        </div>
      </div>
    </div>
    <div class="author px-3 text-tiny-regular color-text-tertiary">
      {{ sendbirdmessage?.sender?.nickname }}
    </div>
  </div>
</ng-container>

<ng-container #messge *ngIf="sendbirdmessage?.messageType === 'admin'">
  <div class="message-container py-4">
    <div #htmlString class="message d-flex flex-column p-3" [ngStyle]="{ cursor: 'text', 'background-color': '#F9E8C9' }">
      <ng-container>
        <svg-icon key="bulb"></svg-icon>
        <div class="body text-tiny-regular" [innerHTML]="body"></div>
      </ng-container>

      <div class="footer d-flex justify-content-end mt-3">
        <div class="date text-micro-regular d-flex align-items-end asd">
          {{ sendbirdmessage?.createdAt | sendbirdMessageDate }}
        </div>
      </div>
    </div>
    <div class="author px-3 text-tiny-regular color-text-tertiary">Admin Message</div>
  </div>
</ng-container>

<ng-template #downloadImageTemplate>
  <div class="popup-image">
    <div class="popup-image-action-main">
      <a href="{{ popupImage[0].url }}" class="btn btn-border popup-image-action-btn" download="image.jpg">
        <svg-icon
          mat-dialog-close
          _ngcontent-ihg-c63=""
          key="download"
          _nghost-ihg-c59=""
          aria-label="dwonload-icon"
          class="svg-icon-download"
          ng-reflect-key="download"
          role="img"
          aria-hidden="true"
          ><svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            fit=""
            height="100%"
            width="100%"
            preserveAspectRatio="xMidYMid meet"
            focusable="false"
          >
            <path d="M5 5l14 14M19 5L5 19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
        </svg-icon>
      </a>
      <div class="popup-image-action-btn cross-btn-custom-cursor">
        <svg-icon
          mat-dialog-close
          _ngcontent-ihg-c63=""
          key="close"
          _nghost-ihg-c59=""
          aria-label="close-icon"
          class="svg-icon-close"
          ng-reflect-key="close"
          role="img"
          aria-hidden="true"
          ><svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            fit=""
            height="100%"
            width="100%"
            preserveAspectRatio="xMidYMid meet"
            focusable="false"
          >
            <path d="M5 5l14 14M19 5L5 19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
        </svg-icon>
      </div>
    </div>
    <img class="img-fluid popup-image-img" src="{{ popupImage[0].url }}" />
  </div>
</ng-template>
