import { Injectable } from '@angular/core';
import { GetUsersResponse, GetUsersResponseItem, MiscDataService } from '@tecex-api/data';
import isNil from 'lodash/isNil';
import uniqBy from 'lodash/uniqBy';
import { Observable, of } from 'rxjs';
import { first, map, pluck, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TeamMemberRole } from '../enums/team-member-role.enum';
import { mapTeamMemberRole } from '../helpers/map-team-member-role.helper';
import { mapUserToParticipant } from '../helpers/map-user-to-participant.helper';
import { mapUserToThirdParty } from '../helpers/map-user-to-third-party.helper';
import { ShipmentOrderPerson } from '../interfaces/shipment-order-person.interface';
import { TeamMemberLists } from '../interfaces/team-member-lists.interface';
import { MessageButtonUserVM } from '../modules/message-button/user.vm';
import { TeamMemberListType } from '../modules/message-thread/enums/team-member-list-type.enum';
import { CommonMessagesService } from '../modules/common-messages/services/common-messages.service';
import { MessagesRoles } from '../modules/common-messages/enums/messages-roles.enum';
import { AuthService } from './auth.service';

interface MappedUsers {
  defaults: GetUsersResponseItem[];
  taggable: GetUsersResponseItem[];
  mentionable: GetUsersResponseItem[];
}

const ALL_USERS_RESPONSE_KEY = 'all';

@Injectable({
  providedIn: 'root',
})
export class TeamMemberService {
  private readonly cache = new Map<string, MappedUsers>();
  private readonly cachedGetUsersResponse = new Map<string, GetUsersResponse>();

  constructor(
    private readonly authService: AuthService,
    private readonly miscDataService: MiscDataService,
    private readonly router: Router,
    private readonly commonMessagesService: CommonMessagesService
  ) {}

  public getTeamMembers$(shipmentOrderId: string, listType?: TeamMemberListType): Observable<TeamMemberLists> {
    return this.getMappedUsers$(shipmentOrderId, listType).pipe(
      map(({ taggable, mentionable }) => ({
        taggable: taggable.map((element) => mapUserToParticipant(element)),
        mentionable: mentionable.map((element) => mapUserToParticipant(element)),
      }))
    );
  }

  public getDefaultTeamMember$(shipmentOrderId?: string, listType?: TeamMemberListType): Observable<MessageButtonUserVM> {
    return this.getMappedUsers$(shipmentOrderId, listType).pipe(
      switchMap(({ defaults: [user] }) => {
        if (!user) {
          return this.authService
            .getUser$()
            .pipe(first(), listType === TeamMemberListType.Invoice ? pluck('financialController') : pluck('accountManager'));
        }

        return of({
          id: user.UserID,
          name: user.Username,
          profilePicture: user.FullPhotoUrl,
        });
      })
    );
  }

  public getTeamMemberRole$(shipmentOrderId?: string, listType?: TeamMemberListType): Observable<MessageButtonUserVM[]> {
    const roles = this.commonMessagesService.assignMessageRole();
    return this.getSOorAccUsers$(shipmentOrderId, listType).pipe(
      switchMap((allUsers) => {
        let filteredData = allUsers.filter((obj) => roles.includes(obj.Role));
        function sortByRoleOrder(person1, person2) {
          const roleIndex1 = roles.indexOf(person1.Role);
          const roleIndex2 = roles.indexOf(person2.Role);
          return roleIndex1 - roleIndex2;
        }

        // Sort the array using the custom sorting function
        filteredData.sort(sortByRoleOrder);
        const hasOSAndSM =
          filteredData.some((person) => person.Role === MessagesRoles.OS) &&
          filteredData.some((person) => person.Role === MessagesRoles.SM);
        if (roles.includes(MessagesRoles.OS) && roles.includes(MessagesRoles.SM) && hasOSAndSM) {
          filteredData = filteredData.filter((person) => person.Role !== MessagesRoles.SM);
        }
        const hasICEAndLICE =
          filteredData.some((person) => person.Role === MessagesRoles.ICE) &&
          filteredData.some((person) => person.Role === MessagesRoles.LICE);
        if (roles.includes(MessagesRoles.ICE) && roles.includes(MessagesRoles.LICE) && hasICEAndLICE) {
          filteredData = filteredData.filter((person) => person.Role !== MessagesRoles.LICE);
        }
        if (filteredData && filteredData.length > 0) {
          const mappedData = filteredData.map((user) => ({
            id: user.UserID,
            name: user.Username,
            profilePicture: user.FullPhotoUrl,
            email: user.Useremail,
          }));
          return of(mappedData);
        } else {
          return of([
            {
              id: '',
              name: 'TecEx',
              profilePicture: null,
              email: '',
            },
          ]);
        }
      })
    );
  }

  public getSOorAccUsers$(shipmentOrderId: string, listType: TeamMemberListType = TeamMemberListType.General): Observable<any> {
    return this.getUsers$(shipmentOrderId).pipe(
      map((response) => {
        if (listType === TeamMemberListType.General || listType === TeamMemberListType.Invoice) {
          return response.ACTecexPeople;
        }
        return response.SOTecexPeople;
      })
    );
  }

  public getThirdPartyUserIds(shipmentOrderId?: string): any {
    return this.getUsers$(shipmentOrderId).pipe(
      map((response) => {
        return response.ClientUsers.map(({ UserID }) => UserID);
      })
    );
  }

  public getThirdPartyIds(shipmentOrderId?: string): any {
    return this.getUsers$(shipmentOrderId).pipe(
      map((response) => {
        return response.ClientUsers.map(({ ContactId }) => ContactId);
      })
    );
  }

  public getAllThirdParties$(shipmentOrderId?: string, listType?: TeamMemberListType): Observable<any> {
    return this.getMappedUsers$(shipmentOrderId, listType);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public getAllThirdPartyPeople(shipmentOrderId: string) {
    return this.getMappedUsers$(shipmentOrderId, TeamMemberListType.ThirdParty).pipe(
      map(({ defaults }) => ({
        defaults: defaults.map((element) => mapUserToThirdParty(element)),
      }))
    );
  }

  public getShipmentOrderPeople$(shipmentOrderId: string): Observable<ShipmentOrderPerson[]> {
    return this.authService.getUser$().pipe(
      first(),
      switchMap((user) =>
        this.miscDataService
          .getUsers({
            Accesstoken: user.accessToken,
            AccountID: user.accountId,
            SOID: shipmentOrderId,
          })
          .pipe(
            map((response) =>
              response.SOTecexPeople.map((person) => ({
                id: person.UserID,
                firstName: person.FirstName,
                lastName: person.LastName,
                photoUrl: person.FullPhotoUrl,
                isOutOfOffice: person.OutOfOfficeIsEnabled,
                outOfOfficeText: person.OutOfOfficeMessage,
                standByPersonId: person.StandbyUserID,
                standByPersonName: person.StandbyUserName,
                role: mapTeamMemberRole(person),
                userName: person.Username,
              }))
            )
          )
      )
    );
  }

  /**
   * Fetch users and cache results
   *
   * @param shipmentOrderId - When not porvided all users will be fetched
   */
  public getUsers$(shipmentOrderId?: string): Observable<GetUsersResponse> {
    if (shipmentOrderId && this.cachedGetUsersResponse.has(shipmentOrderId)) {
      return of(this.cachedGetUsersResponse.get(shipmentOrderId));
    } else if (!shipmentOrderId && this.cachedGetUsersResponse.has(ALL_USERS_RESPONSE_KEY)) {
      return of(this.cachedGetUsersResponse.get(ALL_USERS_RESPONSE_KEY));
    }

    return this.authService.getUser$().pipe(
      first(),
      switchMap((user) =>
        this.miscDataService.getUsers({
          Accesstoken: user.accessToken,
          AccountID: user.accountId,
          SOID: shipmentOrderId,
        })
      ),
      tap((resp) =>
        isNil(shipmentOrderId)
          ? this.cachedGetUsersResponse.set(ALL_USERS_RESPONSE_KEY, resp)
          : this.cachedGetUsersResponse.set(shipmentOrderId, resp)
      )
    );
  }

  /**
   * ¯\_(ツ)_/¯
   */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  private getMappedUsers$(shipmentOrderId: string, listType: TeamMemberListType = TeamMemberListType.General): Observable<MappedUsers> {
    const cacheKey = `${shipmentOrderId}.${listType}`;

    if (this.cache.has(cacheKey)) {
      return of(this.cache.get(cacheKey));
    }

    return this.getUsers$(shipmentOrderId).pipe(
      map((response) => {
        // tslint:disable-next-line: cyclomatic-complexity
        const users = [...(response.ClientUsers || []), ...(response.ACTecexPeople || []), ...(response.AllTecexPeople || [])];
        const shipmentOrderUsers = response.SOTecexPeople || [];
        switch (listType) {
          case TeamMemberListType.General: {
            const defaults = shipmentOrderUsers
              ? shipmentOrderUsers.filter((element) => this.isInsideSalesManager(element))
              : users.filter((element) => this.isInsideSalesManager(element));

            const taggable = [
              ...defaults,
              ...users.filter((element) => this.isOperationsManager(element)),
              ...users.filter((element) => this.isAccountManager(element)),
              ...users.filter((element) => this.isLeadAccountManager(element)),
              ...users.filter((element) => this.isBranchManager(element)),
              ...response.ClientUsers,
            ];

            const mentionable = [...taggable, ...users, ...shipmentOrderUsers];
            return {
              defaults,
              taggable,
              mentionable,
            };
          }
          case TeamMemberListType.ShipmentOrder: {
            const defaults = this.router.url.includes('quotes')
              ? shipmentOrderUsers.filter((element) => this.isInsideSalesManager(element))
              : shipmentOrderUsers.filter((element) => this.isAccountManager(element));

            const taggable = [
              ...defaults,
              ...shipmentOrderUsers.filter((element) => this.isLeadAccountManager(element)),
              ...shipmentOrderUsers.filter((element) => this.isInsideSalesManager(element)),
              ...response.ClientUsers,
              ...shipmentOrderUsers.filter((element) => this.isBranchManager(element)),
              ...shipmentOrderUsers.filter((element) => this.isFinancialController(element)),
              ...shipmentOrderUsers.filter((element) => this.isOperationsManager(element)),
              ...users.filter((element) => this.isFinancialManager(element)),
              ...users.filter((element) => this.isInsideSalesManager(element)),
              ...users.filter((element) => this.isAccountManager(element)),
              ...users.filter((element) => this.isBranchManager(element)),
            ];
            const mentionable = [...taggable, ...users, ...shipmentOrderUsers];

            return {
              defaults,
              taggable,
              mentionable,
            };
          }
          //TODO make below conditional on users vs shipentorderusers
          case TeamMemberListType.Invoice: {
            const defaults = shipmentOrderUsers
              ? shipmentOrderUsers.filter((element) => this.isFinancialController(element))
              : users.filter((element) => this.isFinancialController(element));

            const taggable = [
              ...defaults,
              ...users.filter((element) => this.isFinancialManager(element)),
              ...shipmentOrderUsers.filter((element) => this.isFinancialController(element)),
              ...shipmentOrderUsers.filter((element) => this.isFinancialManager(element)),
              ...response.ClientUsers,
            ];

            const mentionable = [...taggable, ...users, ...shipmentOrderUsers];

            return {
              defaults,
              taggable,
              mentionable,
            };
          }
          case TeamMemberListType.Onboarding: {
            const defaults = users.filter((element) => this.isBranchManager(element));

            const taggable = [
              ...defaults,
              ...users.filter((element) => this.isOperationsManager(element)),
              ...users.filter((element) => this.isAccountManager(element)),
              ...users.filter((element) => this.isLeadAccountManager(element)),
              ...users.filter((element) => this.isBranchManager(element)),
              ...response.ClientUsers,
            ];

            const mentionable = [...taggable, ...users, ...shipmentOrderUsers];

            return {
              defaults,
              taggable,
              mentionable,
            };
          }
          case TeamMemberListType.ThirdParty: {
            // Needs to be reworked with API define ThirdPartyPeople
            let defaults: any;
            const list = Object.entries(response);
            list.forEach((item) => {
              if (item[0] === 'ThirdPartyPeople') {
                defaults = item[1];
              }
            });
            return {
              defaults,
            };
          }
        }
      }),
      map(({ defaults, taggable, mentionable }) => ({
        defaults,
        taggable: uniqBy(taggable, (item) => item.UserID),
        mentionable: uniqBy(mentionable, (item) => item.UserID),
      })),
      tap((result) => this.cache.set(cacheKey, result))
    );
  }

  private isInsideSalesManager(user: GetUsersResponseItem): boolean {
    return mapTeamMemberRole(user) === TeamMemberRole.InsideSalesManager;
  }

  private isOperationsManager(user: GetUsersResponseItem): boolean {
    return mapTeamMemberRole(user) === TeamMemberRole.OperationsManager;
  }

  private isAccountManager(user: GetUsersResponseItem): boolean {
    return mapTeamMemberRole(user) === TeamMemberRole.AccountManager;
  }

  private isLeadAccountManager(user: GetUsersResponseItem): boolean {
    return mapTeamMemberRole(user) === TeamMemberRole.LeadAccountManager;
  }

  private isBranchManager(user: GetUsersResponseItem): boolean {
    return mapTeamMemberRole(user) === TeamMemberRole.BranchManager;
  }

  private isFinancialController(user: GetUsersResponseItem): boolean {
    return mapTeamMemberRole(user) === TeamMemberRole.FinancialController;
  }

  private isFinancialManager(user: GetUsersResponseItem): boolean {
    return mapTeamMemberRole(user) === TeamMemberRole.FinancialManager;
  }
}
