import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatRippleModule } from '@angular/material/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgIf } from '@angular/common';
import { ProfilePicturePipe } from '../../../../pipes/profile-picture.pipe';
import { MessagesRoles } from '../../../common-messages/enums/messages-roles.enum';

@Component({
  selector: 'app-sendbird-message-participant-item',
  templateUrl: './sendbird-message-participant-item.component.html',
  styleUrls: ['./sendbird-message-participant-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, SvgIconComponent, MatRippleModule, ProfilePicturePipe, TranslateModule],
})
export class SendbirdMessageParticipantItemComponent {
  @Input() public teamMember: any;
  @Input() public added: boolean;
  @Input() public removable = false;
  @Input() public keyword: string;
  @Input() public participantSection: string;
  @Input() public existingSendBirdChannelFlag: boolean;

  @Output() public add = new EventEmitter<void>();
  @Output() public remove = new EventEmitter<void>();

  public isSearched = false;

  public get searchedName(): string {
    return this.getSearchedTextWithBoldKeywords(
      `${this.teamMember.firstName} ${this.teamMember.lastName ? this.teamMember.lastName : ''}`,
      this.keyword,
      this.teamMember
    );
  }

  public onAddClick(): void {
    this.add.next();
  }

  public onRemoveClick(): void {
    this.remove.next();
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  private getSearchedTextWithBoldKeywords(searchText: string, keyword: string, participantDetail: any): string {
    const keywordLowerCase = keyword.toLocaleLowerCase();
    const keywordLength = keywordLowerCase.length;

    const parts: { value: string; searched: boolean; roleOfParticipantDetail?: string }[] = [];
    let searchedText = searchText;
    let searchedTextLowerCase = searchedText.toLocaleLowerCase();
    while (searchedTextLowerCase.includes(keywordLowerCase)) {
      const keywordIndex = searchedTextLowerCase.indexOf(keywordLowerCase);
      parts.push(
        { value: searchedText.slice(0, Math.max(0, keywordIndex)), searched: false },
        // eslint-disable-next-line unicorn/prefer-string-slice
        { value: searchedText.substring(keywordIndex, keywordIndex + keywordLength), searched: true }
      );
      searchedText = searchedText.slice(Math.max(0, keywordIndex + keywordLength));
      searchedTextLowerCase = searchedTextLowerCase.slice(Math.max(0, keywordIndex + keywordLength));
    }

    parts.push({ value: searchedText, searched: false }, { value: '', searched: false });

    if (parts.findIndex((x) => x.value === '') !== 0 && parts.filter((x) => x.value === '').length > 1) {
      parts.pop();
      // eslint-disable-next-line sonarjs/no-duplicated-branches
    } else if (parts.findIndex((x) => x.value === '') === 0 && parts.filter((x) => x.value === '').length > 2) {
      parts.pop();
    }

    const participantName = parts.reduce(
      (acc, part) =>
        part.searched
          ? `${acc}<span class="text-normal-bold">${part.value}</span>`
          : `${
              acc + part.value !== '' && (participantDetail.Role || this.teamMember.ThirdPartyCompany) && part.value === ''
                ? // eslint-disable-next-line sonarjs/no-nested-template-literals
                  `${acc + part.value}`
                : acc + part.value
            }`,
      ''
    );
    this.isSearched = true;

    return `${participantName}${
      participantDetail.Role || this.teamMember.ThirdPartyCompany
        ? // eslint-disable-next-line sonarjs/no-nested-template-literals
          `<span class="company-third-party-name"> (${
            participantDetail.Role ? participantDetail.Role : this.teamMember.ThirdPartyCompany
          }) </span>`
        : ''
    }`;
  }

  // get the team member name :
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public teamMemberName(teamMember: any): void {
    let teamMemberName;

    if (teamMember.FirstName && teamMember.LastName) {
      teamMemberName = `${teamMember.FirstName} ${teamMember.LastName}`;
    } else if (teamMember.Name) {
      teamMemberName = teamMember.Name;
    } else if (teamMember.firstName && teamMember.lastName) {
      teamMemberName = `${teamMember.firstName} ${teamMember.lastName}`;
    } else if (teamMember.nickname) {
      teamMemberName = teamMember.nickname;
    } else if (teamMember.AffiliateUserName) {
      teamMemberName = teamMember.AffiliateUserName;
    } else if (teamMember.firstName) {
      teamMemberName = teamMember.firstName;
    }

    return teamMemberName;
  }

  // For getting the roles detail :
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-member-accessibility
  getRoles(teamMember: any) {
    let teamMemberRole;

    if (teamMember.Role) {
      if (teamMember.Role.includes(MessagesRoles.ICE) && !teamMember.Role.includes('Lead')) {
        teamMember.Role = teamMember.Role.replace(MessagesRoles.ICE, MessagesRoles.SCC);
      } else if (teamMember.Role === MessagesRoles.LICE || teamMember.Role === MessagesRoles.TICETL) {
        teamMember.Role = MessagesRoles.SCL;
      }
      teamMemberRole = teamMember.Role;
    } else if (teamMember.roleOfParticipant) {
      teamMemberRole = teamMember.roleOfParticipant;
    } else if (teamMember.userRole) {
      if (teamMember.userRole.includes(MessagesRoles.ICE) && !teamMember.userRole.includes('Lead')) {
        teamMember.userRole = teamMember.userRole.replace(MessagesRoles.ICE, MessagesRoles.SCC);
      } else if (teamMember.userRole === MessagesRoles.LICE || teamMember.userRole === MessagesRoles.TICETL) {
        teamMember.userRole = MessagesRoles.SCL;
      }
      teamMemberRole = teamMember.userRole;
    }

    return teamMemberRole;
  }

  // For getting the company detail :
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-member-accessibility
  getCompanyName(teamMember: any) {
    let teamMemberCompanyName;

    if (teamMember.ThirdPartyCompany) {
      teamMemberCompanyName = teamMember.ThirdPartyCompany;
    } else if (teamMember.userCompany) {
      teamMemberCompanyName = teamMember.userCompany;
    }

    return teamMemberCompanyName;
  }

  createAccOutOfOfficeMessage(participant: any) {
    const startDate = new Date(Date.parse(participant.OutOfOfficeStartDate)).toDateString().slice(4, 10);
    //  .substring(4, 10);
    const endDate = new Date(Date.parse(participant.OutOfOfficeEndDate)).toDateString().slice(4, 10);
    //.substring(4, 10);
    return `Out of Office: ${startDate} - ${endDate}`;
  }
}
