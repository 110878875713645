import { MessageThreadType } from '@global/enums/message-thread-type.enum';
import { MessageThread } from '@global/interfaces/messages/message-thread.interface';
import { MessageButtonUserVM } from '@global/modules/message-button/user.vm';
import { ShipmentDetailsShipmentVM } from '@modules/shipments/interfaces/shipment-details-shipment.vm';
import { createAction, props } from '@ngrx/store';
import { ThirdPartyDetailsTabIndex } from '../third-party-details-tab-index.enum';
import { ThirdPartyTaskVM } from '../third-party-tasks.vm';

export const enter = createAction('[ThirdParty] enter');

export const getMessageThreads = createAction('[ThirdParty] getMessageThreads');
export const getMessageThreadsSuccess = createAction('[ThirdParty] getMessageThreadsSuccess', props<{ messageThreads: MessageThread[] }>());
export const getMessageThreadsError = createAction('[ThirdParty] getMessageThreadsError', props<{ error: string }>());

export const removeMessage = createAction('[ThirdParty] removeMessage', props<{ messageId: string }>());

export const openMessage = createAction('[ThirdParty] openMessage', props<{ id: string; messageType: MessageThreadType }>());

export const messageToAccountManager = createAction('[ThirdParty] messageToAccountManager', props<{ teamMember: MessageButtonUserVM }>());

export const selectShipment = createAction('[ThirdParty] selectShipment', props<{ shipmentId: string; accountIdFromSOs?: string }>());
export const selectShipmentTabIndex = createAction(
  '[ThirdParty] selectShipmentTabIndex',
  props<{ selectedTabIndex: ThirdPartyDetailsTabIndex }>()
);

export const loadShipment = createAction('[ThirdParty] loadShipment', props<{ shipmentId: string; accountIdFromSOs?: string }>());
export const loadShipmentSuccess = createAction('[ThirdParty] loadShipmentSuccess', props<{ shipment: ShipmentDetailsShipmentVM }>());
export const loadShipmentError = createAction('[ThirdParty] loadShipmentError', props<{ error: string }>());

export const getTasks = createAction('[ThirdParty] getTasks', props<{ shipmentId?: string; accountIdFromSOs?: string }>());
export const getTasksSuccess = createAction('[ThirdParty] getTasksSuccess', props<{ tasks: ThirdPartyTaskVM[] }>());
export const getTasksError = createAction('[ThirdParty] getTasksError', props<{ error: string }>());

export const openTask = createAction('[ThirdParty] openTask', props<{ taskId: string; shipmentId?: string; accountIdFromSOs?: string }>());

export const openDropDown = createAction('[ThirdParty] openDropDown', props<{ selectedTaskAccountId: string }>());

// For un-read channel list : Start
export const loadSendBirdUnreadChannels = createAction('[ThirdParty] loadSendBirdUnreadChannels');
export const loadSendBirdUnreadChannelsSuccess = createAction(
  '[ThirdParty] loadSendBirdUnreadChannelsSuccess',
  props<{ unreadChannels: any }>()
);
export const getAllUnreadMessagesFromSOID = createAction('[ThirdParty] getAllUnreadMessagesFromSOID', props<{ shipmentId: string }>());
export const loadAllMessagesSuccess = createAction('[ThirdParty] loadAllMessagesSuccess', props<{ totalCountOfMessages: any }>());
// For un-read channel list : End

// For getting the compliance and tracking counts : Start
export const countOfComplianceAndTracking = createAction('[ThirdParty] countOfComplianceAndTracking');
export const countOfComplianceAndTrackingSuccess = createAction(
  '[ThirdParty] countOfComplianceAndTrackingSuccess',
  props<{ countObject: { complianceInProgressCount: number; trackingCount: number } }>()
);
export const countOfComplianceAndTrackingError = createAction('[ThirdParty] countOfComplianceAndTrackingError', props<{ error: string }>());
// For getting the compliance and tracking counts : End

export const updateMessageThreadReadFlag = createAction(
  '[ThirdParty] updateMessageThreadReadFlag',
  props<{ sendBirdChannelURL: string }>()
);
export const updateMessageThreadReadFlagSuccess = createAction(
  '[ThirdParty] updateMessageThreadReadFlagSuccess',
  props<{ sendBirdUpdatedState: any }>()
);

export const leave = createAction('[ThirdParty] leave');

export const selectShipmentTasks = createAction('[ThirdParty] selectShipmentTasks', props<{ selectedTasks: string }>());
