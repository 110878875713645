import { NgModule } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { GlobalModule } from '../../global.module';
import { SvgIconsModule } from '../svg-icons/svg-icons.module';
import { SelectionComponent } from './selection.component';

@NgModule({
  imports: [GlobalModule, SvgIconsModule, MatRadioModule, SelectionComponent],
  exports: [SelectionComponent],
})
export class SelectionModule {}
