import { ServiceType as ServiceTypePayload, ServiceTypeWithNull } from '@tecex-api/data';
import { ServiceType } from '../enums/service-type.enum';

export const mapServiceType = (serviceType: ServiceTypeWithNull | ServiceTypePayload, Eor_Ior?: boolean): ServiceType => {
  if (Eor_Ior) {
    return ServiceType.BOTH;
  } else {
    switch (serviceType) {
      case ServiceTypeWithNull.IOR:
      case ServiceTypePayload.IOR: {
        return ServiceType.IOR;
      }
      case ServiceTypeWithNull.EOR:
      case ServiceTypePayload.EOR: {
        return ServiceType.EOR;
      }
      case ServiceTypePayload.BOTH: {
        return ServiceType.IOR;
      }
      default: {
        return ServiceType.IOR;
      }
    }
  }
};
