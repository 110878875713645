import { LineItemsColumn } from '@global/enums/line-items-column.enum';
import { LineItem } from '@global/modules/common-quote/interfaces/line-item.interface';
import { AddPartsRequestParts } from '@tecex-api/data';

export const mapAddPartsPayload = (shipmentOrderId: string, lineItems: LineItem[]): AddPartsRequestParts[] =>
  lineItems.map((lineItem) => ({
    SOID: shipmentOrderId,
    PartNumber: lineItem[LineItemsColumn.ProductCode],
    PartDescription: lineItem[LineItemsColumn.Description],
    Quantity: lineItem[LineItemsColumn.Quantity],
    UnitPrice: lineItem[LineItemsColumn.UnitPrice],
  }));
