import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import { RecordType } from '@tecex-api/data';
import { Observable } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIf, NgClass, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { AuthService } from '../../../../../global/services/auth.service';
import { RouteSegment } from '../../../../enums/route-segment.enum';
import { MessageThreadShipment } from '../../../../interfaces/messages/message-thread-shipment.interface';
import { ValidatorHelperService } from '../../../../services/validator-helper.service';
import { FormControlInputDirective } from '../../../form-control/directives/form-control-input.directive';
import { FormControlComponent } from '../../../form-control/components/form-control/form-control.component';
@Component({
  selector: 'app-sendbird-subject',
  templateUrl: './sendbird-subject.component.html',
  styleUrls: ['./sendbird-subject.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    FormControlComponent,
    FormsModule,
    FormControlInputDirective,
    NgClass,
    ReactiveFormsModule,
    NgTemplateOutlet,
    RouterLink,
    AsyncPipe,
    TranslateModule,
  ],
})
export class SendbirdSubjectComponent implements OnInit {
  @Input() public sendbirdchannel: any;
  @Input() public subjectControl: FormControl<string>;
  @Input() public shipment?: MessageThreadShipment;
  @Input() public editable: boolean;
  @Input() public recordType: RecordType;
  @Input() public editableSubjectValue: boolean;

  @Output() public subjectChange = new EventEmitter<string>();

  public get sendBirdChannel(): string {
    return this.sendbirdchannel || this.subjectControl.value;
  }

  public subjectError$: Observable<string | null>;
  public isThirdParty = this.authService.thirdPartyFlag();

  constructor(
    private readonly validatorHelperService: ValidatorHelperService,
    private readonly authService: AuthService
  ) {}

  public ngOnInit(): void {
    this.subjectError$ = this.validatorHelperService.getError$(this.subjectControl);
  }

  public get shipmentRouterLink(): string[] {
    if (this.recordType === RecordType.COST_ESTIMATE) {
      return [RouteSegment.Root, RouteSegment.QuoteList, this.shipment.id ? this.shipment.id : this.shipment.shipmentOrderId];
    }
    return [RouteSegment.Root, RouteSegment.ShipmentsList, this.shipment.id ? this.shipment.id : this.shipment.shipmentOrderId];
  }

  // To obtain the values for hyperlinks for display :
  public getHyperLinkFromShipment(shipment: MessageThreadShipment): string {
    if (shipment.title) return shipment.title;
    else if (shipment.parentObject === 'Task') return shipment.parentName.split(' - ').slice(1).join(' - ');
    else if (shipment.parentObject === 'Invoice') return shipment.parentName.replaceAll(/-?\s?invoice\s\d+/gi, '').trim();

    return shipment.parentName;
  }
}
