import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CurrencyInputDirective } from './directives/currency-input.directive';
import { InputMinMaxDirective } from './directives/input-min-max.directive';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { PositiveNumbersOnlyInputDirective } from './directives/positive-numbers-only-input.directive';
import { RecaptchaDirective } from './directives/recaptcha.directive';
import { TextHighlightDirective } from './directives/text-highlight.directive';
import { TextareaAutosizeDirective } from './directives/textarea-autosize.directive';
import { GlobalModuleConfig } from './global-module-config.interface';
import { CaseDatePipe } from './pipes/case-date.pipe';
import { CostPipe } from './pipes/cost.pipe';
import { DownloadDocumentUrlPipe } from './pipes/download-document-url.pipe';
import { LengthUnitPipe } from './pipes/length-unit.pipe';
import { MessageDatePipe } from './pipes/message-date.pipe';
import { MessageThreadParticipantsPipe } from './pipes/message-thread-participants.pipe';
import { NewQuoteCreationDatePipe } from './pipes/new-quote-creation-date.pipe';
import { ProfilePicturePipe } from './pipes/profile-picture.pipe';
import { SendbirdMessageDatePipe } from './pipes/sendbird-message-date.pipe';
import { SendBirdMessageThreadParticipantsPipe } from './pipes/sendbird-message-thread-participants.pipe';
import { TypeofPipe } from './pipes/typeof-variable.pipe';
import { UnsignedPipe } from './pipes/unsigned.pipe';
import { WeightUnitPipe } from './pipes/weight-unit.pipe';
import { SERVE_PORT } from './tokens/app-serve-port.token';
import { ENVIRONMENT } from './tokens/environment.token';
import { PROFILE_PICTURE_FALLBACK } from './tokens/profile-picture-fallback.token';
@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule,
    WeightUnitPipe,
    LengthUnitPipe,
    CostPipe,
    MessageDatePipe,
    UnsignedPipe,
    ProfilePicturePipe,
    MessageThreadParticipantsPipe,
    CaseDatePipe,
    DownloadDocumentUrlPipe,
    CurrencyInputDirective,
    TextHighlightDirective,
    PhoneMaskDirective,
    TextareaAutosizeDirective,
    RecaptchaDirective,
    InputMinMaxDirective,
    PositiveNumbersOnlyInputDirective,
    SendbirdMessageDatePipe,
    SendBirdMessageThreadParticipantsPipe,
    TypeofPipe,
    NewQuoteCreationDatePipe,
  ],
  exports: [
    CommonModule,
    WeightUnitPipe,
    LengthUnitPipe,
    CostPipe,
    MessageDatePipe,
    UnsignedPipe,
    ProfilePicturePipe,
    MessageThreadParticipantsPipe,
    CaseDatePipe,
    DownloadDocumentUrlPipe,
    CurrencyInputDirective,
    TextHighlightDirective,
    PhoneMaskDirective,
    TextareaAutosizeDirective,
    RecaptchaDirective,
    InputMinMaxDirective,
    PositiveNumbersOnlyInputDirective,
    SendbirdMessageDatePipe,
    SendBirdMessageThreadParticipantsPipe,
    TypeofPipe,
    NewQuoteCreationDatePipe,
  ],
})
export class GlobalModule {
  public static forRoot(config: GlobalModuleConfig): ModuleWithProviders<GlobalModule> {
    return {
      ngModule: GlobalModule,
      providers: [
        { provide: ENVIRONMENT, useValue: config.environment },
        { provide: PROFILE_PICTURE_FALLBACK, useValue: config.profilePictureFallback },
        { provide: SERVE_PORT, useValue: config.servePort },
      ],
    };
  }
}
