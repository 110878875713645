import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import { NgIf } from '@angular/common';
import { InstructionBlockPayload } from '../../types/instruction-block-payload.interface';
import { BlockComponent } from '../block/block.component';

@Component({
  selector: 'app-instructions-block',
  templateUrl: './instructions-block.component.html',
  styleUrls: ['./instructions-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf],
})
export class InstructionsBlockComponent extends BlockComponent<InstructionBlockPayload, undefined> implements OnInit {
  public ngOnInit(): void {
    this.register.next(new FormControl(undefined));
  }
}
