import { ProfileRouteSegment } from '@global/enums/profile-route-segment.enum';
import { RouteSegment } from '@global/enums/route-segment.enum';
import { SvgIcons } from '@ngneat/svg-icon';

export const profileLink = {
  title: 'PROFILE_MENU.PROFILE_AND_NOTIFICATIONS',
  link: [RouteSegment.Root, RouteSegment.Profile, ProfileRouteSegment.ProfileAndNotifications],
  icon: 'profile' as SvgIcons,
};

export const logOutLink = {
  title: 'COMMON.LOG_OUT',
  link: [RouteSegment.Root, RouteSegment.Logout],
  icon: 'log-out' as SvgIcons,
};

export const productCatalogMenuItem = {
  title: 'PROFILE_MENU.PRODUCT_CATALOG',
  link: [RouteSegment.Root, RouteSegment.Profile, ProfileRouteSegment.ProductCatalog],
  icon: 'product-catalog' as SvgIcons,
};

export const QuoteDefaultsMenuItem = {
  title: 'PROFILE_MENU.QUOTE_DEFAULTS',
  link: [RouteSegment.Root, RouteSegment.Profile, ProfileRouteSegment.QuoteDefaults],
  icon: 'quote-details' as SvgIcons,
};

export const myTeamMenuItem = {
  title: 'PROFILE_MENU.MY_TEAM',
  link: [RouteSegment.Root, RouteSegment.Profile, ProfileRouteSegment.MyTeam],
  icon: 'team' as SvgIcons,
};

export const addressBookMenuItem = {
  title: 'PROFILE_MENU.ADDRESS_BOOK',
  link: [RouteSegment.Root, RouteSegment.Profile, ProfileRouteSegment.AddressBook],
  icon: 'address' as SvgIcons,
};

export const profileMenuItems = [
  myTeamMenuItem,
  productCatalogMenuItem,
  QuoteDefaultsMenuItem,
  addressBookMenuItem,
  profileLink,
  logOutLink,
];

export const settingsMenuItems = [myTeamMenuItem, productCatalogMenuItem, QuoteDefaultsMenuItem, addressBookMenuItem, profileLink];

// Remove / comment out the `addressBookMenuItem - Address Book` setting from third-party dashboard :
export const profileMenuForThirdPartyDashboardItems = [myTeamMenuItem, profileLink, logOutLink];
