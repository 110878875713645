import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-alert-bar',
  templateUrl: './alert-bar.component.html',
  styleUrls: ['./alert-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, SvgIconComponent],
})
export class AlertBarComponent {
  @Input() public message: string;
}
