import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MiscDataService, ProfileDataService } from '@tecex-api/data';
import { EMPTY, Observable } from 'rxjs';
import { catchError, finalize, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { MessageThreadType } from '../../../enums/message-thread-type.enum';
import { SourceApp } from '../../../enums/source-app.enum';
import { createMessageOrigin } from '../../../helpers/create-message-origin.helper';
import { AuthService } from '../../../services/auth.service';
import { ErrorNotificationService } from '../../../services/error-notification.service';
import { LoadingIndicatorService } from '../../loading-indicator/services/loading-indicator.service';
import { CaseMessageService } from '../../message-thread/services/case-message.service';
import { ToastMessageService } from '../../toast-message/toast-message.service';
import { TeamMemberDialogVM } from '../components/team-member-dialog/team-member-dialog.vm';
import { mapUsers } from '../helpers/map-users.helper';
import { MyTeamPageContactVM } from '../interfaces/my-team-page-contact.vm';
import { InputDataVM } from '../../../interfaces/input-data.vm';

@Injectable()
export class MyTeamPageService {
  constructor(
    private readonly authService: AuthService,
    private readonly profileDataService: ProfileDataService,
    private readonly caseMessageService: CaseMessageService,
    private readonly loadingIndicatorService: LoadingIndicatorService,
    private readonly errorNotificationService: ErrorNotificationService,
    private readonly toastMessageService: ToastMessageService,
    private readonly translateService: TranslateService,
    private readonly miscDataService: MiscDataService
  ) {}

  public getContacts$(): Observable<MyTeamPageContactVM[]> {
    return this.authService.getUser$().pipe(
      tap(() => this.loadingIndicatorService.open()),
      switchMap((user) =>
        this.miscDataService.getUsers({ Accesstoken: user.accessToken, AccountID: user.accountId }).pipe(
          map((response) => mapUsers(response, user.id)),
          map((contacts) => [...contacts].sort((c1, c2) => c1.name.localeCompare(c2.name))),
          finalize(() => this.loadingIndicatorService.dispose())
        )
      )
    );
  }

  public updateContact$(id: string, includeInInvoicingEmails: boolean): Observable<void> {
    return this.authService.getUser$().pipe(
      tap(() => this.loadingIndicatorService.open()),
      switchMap((user) =>
        this.profileDataService
          .updateContact({ Accesstoken: user.accessToken, ContactID: id, IncludeinInvoicingEmails: includeInInvoicingEmails })
          .pipe(
            // eslint-disable-next-line unicorn/no-useless-undefined
            mapTo(undefined),
            finalize(() => this.loadingIndicatorService.dispose())
          )
      )
    );
  }

  public getStandbyUsers$(): Observable<InputDataVM[]> {
    return this.authService.getUser$().pipe(
      tap(() => this.loadingIndicatorService.open()),
      switchMap((user) =>
        this.miscDataService.getUsers({ Accesstoken: user.accessToken, AccountID: user.accountId }).pipe(
          map((response) => {
            const removedUserList = response.ClientUsers.filter((clientUser) => clientUser.UserID !== user.id);
            return removedUserList
              .map((user) => ({ value: user.UserID, viewValue: user.Username }))
              .sort((a, b) => (a.viewValue > b.viewValue ? 1 : -1));
          }),
          finalize(() => this.loadingIndicatorService.dispose())
        )
      )
    );
  }

  public updateNCPPermission$(id: string, includeInInvoicingEmails: boolean, superUser: boolean, ncpPermission: string): Observable<void> {
    return this.authService.getUser$().pipe(
      tap(() => this.loadingIndicatorService.open()),
      switchMap((user) =>
        this.profileDataService
          .updateContact({
            Accesstoken: user.accessToken,
            ContactID: id,
            // static
            AppName: SourceApp.TecEx,
            NCPPermission: ncpPermission,
            SuperUser: superUser,
            IncludeinInvoicingEmails: includeInInvoicingEmails,
          })
          .pipe(
            // eslint-disable-next-line unicorn/no-useless-undefined
            mapTo(undefined),
            finalize(() => this.loadingIndicatorService.dispose())
          )
      )
    );
  }

  public requestNewTeamMember$(payload: TeamMemberDialogVM): Observable<void> {
    const ncpPermissionsList: string[] = [];
    if (payload.canCreateQuote) ncpPermissionsList.push('Quote Creation');
    if (payload.canAcceptQuote) ncpPermissionsList.push('Quote Acceptance');
    if (payload.canCompleteTask) ncpPermissionsList.push('Task Completion');
    if (payload.canAccessInvoices) ncpPermissionsList.push('Invoice Access');
    payload.ncpPermissionString = String(ncpPermissionsList).replaceAll(',', '; ');
    const requestMessageString = this.translateService.instant('MY_TEAM_PAGE.REQUEST_NEW_TEAM_MEMBER_BODY', payload);
    return this.authService.getUser$().pipe(
      tap(() => this.loadingIndicatorService.open()),
      switchMap((user) =>
        this.caseMessageService
          .createThread$({
            type: MessageThreadType.Case,
            participants: [{ id: user.accountManager.id, firstName: user.accountManager.name, lastName: '' }],
            photoUrl: user.accountManager.profilePicture,
            subject: this.translateService.instant('MY_TEAM_PAGE.REQUEST_NEW_TEAM_MEMBER_SUBJECT'),
            origin: createMessageOrigin('My Team page'),
            messages: [],
            description: requestMessageString,
          })
          .pipe(
            switchMap((messageThreadDetails) =>
              this.caseMessageService.sendMessage$(messageThreadDetails, [], {
                body: requestMessageString,
                subject: messageThreadDetails.subject,
                participants: [],
              })
            )
          )
      ),
      tap(() => this.toastMessageService.open(this.translateService.instant('MY_TEAM_PAGE.SUCCESSFULLY_REQUESTED_NEW_TEAM_MEMBER'))),
      finalize(() => this.loadingIndicatorService.dispose()),
      catchError((error) => {
        this.errorNotificationService.notifyAboutError(error, 'ERROR.FAILED_TO_REQUEST_NEW_TEAM_MEMBER');
        return EMPTY;
      })
    );
  }
}
