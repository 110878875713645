import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgFor } from '@angular/common';
import { MessageThreadMessage } from '../../../../interfaces/messages/message-thread-message.interface';
import { DownloadDocumentUrlPipe } from '../../../../pipes/download-document-url.pipe';
import { ProfilePicturePipe } from '../../../../pipes/profile-picture.pipe';
import { MessageDatePipe } from '../../../../pipes/message-date.pipe';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, SvgIconComponent, MessageDatePipe, ProfilePicturePipe, DownloadDocumentUrlPipe],
})
export class MessageComponent implements OnChanges {
  @Input() public message: MessageThreadMessage;

  public body: SafeHtml;

  constructor(private readonly domSanitizer: DomSanitizer) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.message) {
      this.body = this.domSanitizer.bypassSecurityTrustHtml(this.message.body);
    }
  }
}
