import { SvgIcons } from '@ngneat/svg-icon';
import { CurrencyCode } from '../enums/currency-code.enum';

export const poundsInKilogram = 2.205;
export const centimetersInInch = 2.54;
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const millisecondsInADay = 1000 * 3600 * 24;
export const minTaxCalculationModalTime = 10_000;
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const bytesPerMB = 1024 * 1024;
export const tecexContactEmail = 'info@tecex.com';
export const redirectUrlSessionStorageKey = 'REDIRECT_URL';
export const invoicePaymentReturnUrlMapSessionStorageKey = 'INVOICE_PAYMENT_RETURN_URL_MAP';
export const millisecondsInASecond = 1000;
export const secondsInAMinute = 60;
export const millisecondsInAMinute = secondsInAMinute * millisecondsInASecond;
export const newQuoteDestinationLimitForUnvettedAccount = 5;
export const newQuoteCreationLimitForUnvettedAccount = 10;
export const selectableCurrenciesForLineItems: CurrencyCode[] = [
  CurrencyCode.EUR,
  CurrencyCode.USD,
  CurrencyCode.GBP,
  CurrencyCode.CAD,
  CurrencyCode.AUD,
  CurrencyCode.JPY,
  CurrencyCode.CNY,
  CurrencyCode.AED,
  CurrencyCode.CZK,
];
export const helpMenuItem = {
  icon: 'question' as SvgIcons,
  title: 'NAVIGATION.HELP',
};
