import { Buyer } from '@tecex-api/data';
import { InputDataVM } from '../../../../interfaces/input-data.vm';
import { BuyerAccountVM } from './buyer-account.vm';

export const mapBuyersResponse = (response: Buyer[]): InputDataVM<string, string>[] =>
  response.map((buyer) => ({
    value: buyer.Buyer_Account__c,
    viewValue: buyer.Buyer_Account_Name__c,
  }));

export const mapBuyersListResponse = (response: Buyer[]): BuyerAccountVM[] =>
  response.map((buyer) => ({
    value: buyer.Buyer_Account__c,
    viewValue: buyer.Buyer_Account_Name__c,
    country: buyer.Buyer_Account__r.Country__c,
    streetAddress: buyer.Buyer_Account__r.Street_Address__c,
    city: buyer.Buyer_Account__r.City__c,
    postalCode: buyer.Buyer_Account__r.Postal_Code__c,
    vatNumber: buyer.Buyer_Account__r.VAT_Number__c,
    countryInEU: buyer.Buyer_Account__r.Country_Part_Of_EU__c,
  }));
