<div class="text-reduced-medium bar-container d-flex align-items-center space">
  <div class="d-flex align-items-center">
    <svg-icon key="out-of-office" class="iconBtn"></svg-icon>
    {{ 'PROFILE.OUT_OF_OFFICE.OUT_OF_OFFICE_BANNER' | translate: { email: user.email } }}
  </div>

  <div>
    <button class="secondary-button mr-3 mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base" (click)="onTurnOff()">
      {{ 'PROFILE.OUT_OF_OFFICE.TURN_OFF' | translate }}
    </button>

    <button class="secondary-button mr-3 mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base" (click)="onOpenSettings()">
      {{ 'PROFILE.OUT_OF_OFFICE.OOO_SETTINGS' | translate }}
    </button>
  </div>
</div>
