import { LoadableStateReducerHelper } from '@global/helpers/loadable-state-reducer.helper';
import { Loadable } from '@global/interfaces/loadable.interface';
import { createReducer, on } from '@ngrx/store';
import * as NewQuoteActions from '../actions/new-quote.actions';
import * as RolloutWaitForCalculationActions from '../actions/rollout-wait-for-calculation.actions';
import { RolloutWaitForCalculationData } from '../interfaces/rollout-quote-summary-data.interface';

export const rolloutWaitForCalculationFeatureKey = 'rolloutWaitForCalculation';

export interface State {
  rolloutQuote: Loadable<RolloutWaitForCalculationData>;
}

export const initialState: State = {
  rolloutQuote: { isLoading: false, data: undefined },
};

export const reducer = createReducer<State>(
  initialState,

  on(RolloutWaitForCalculationActions.loadRolloutQuote, (state) => ({
    ...state,
    rolloutQuote: LoadableStateReducerHelper.startLoad(state.rolloutQuote),
  })),
  on(RolloutWaitForCalculationActions.loadRolloutQuoteSuccess, (state, { quote }) => ({
    ...state,
    rolloutQuote: LoadableStateReducerHelper.loadSuccess(quote),
  })),
  on(NewQuoteActions.leave, NewQuoteActions.startNew, NewQuoteActions.reuseQuote, (): State => initialState)
);
