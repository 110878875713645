import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChange,
  SimpleChanges,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { FormControl } from '@ngneat/reactive-forms';
import { Store } from '@ngrx/store';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import uniqBy from 'lodash/uniqBy';
import { utc } from 'moment';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
// eslint-disable-next-line unused-imports/no-unused-imports
import { v4 as uuidv4 } from 'uuid';
import { TranslateModule } from '@ngx-translate/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { SourceApp } from '../../../../../global/enums/source-app.enum';
import { User } from '../../../../../global/interfaces/user.interface';
import { AuthService } from '../../../../../global/services/auth.service';
import { bytesPerMB } from '../../../../constants/global.constants';
import { RouteSegment } from '../../../../enums/route-segment.enum';
import { TeamMemberRole } from '../../../../enums/team-member-role.enum';
import { FileHelper } from '../../../../helpers/file.helper';
import { MessagePayload } from '../../../../interfaces/messages/message-payload.interface';
import { MessageThreadDetails } from '../../../../interfaces/messages/message-thread-details.interface';
import { MessageThreadMessage } from '../../../../interfaces/messages/message-thread-message.interface';
import { Participant } from '../../../../interfaces/participant.interface';
import { ShipmentOrderPerson } from '../../../../interfaces/shipment-order-person.interface';
import { TeamMemberLists } from '../../../../interfaces/team-member-lists.interface';
import { FileExtensionService } from '../../../../services/file-extension.service';
import { MessageDialogPayload } from '../../../message-dialog/types/message-dialog-payload.interface';
import * as commonMessageAction from '../../actions/common-messages.actions';
import * as classicCustomBuild from '../../ckClassicBuild/build/ckeditor';
import * as commonMessageReducer from '../../reducers';
import { MessagingAWSService } from '../../services/messaging-aws.service';
import * as Adapter from '../sendbird-message-thread/ckeditor.adapter';
import { MessageThreadParticipantsPipe } from '../../../../pipes/message-thread-participants.pipe';
import { ProfilePicturePipe } from '../../../../pipes/profile-picture.pipe';
import { LoadingIndicatorComponent } from '../../../loading-indicator/components/loading-indicator/loading-indicator.component';
import { MessageComponent } from '../message/message.component';
import { MessageParticipantsListComponent } from '../message-participants-list/message-participants-list.component';
import { SubjectComponent } from '../subject/subject.component';

@Component({
  selector: 'app-message-thread',
  templateUrl: './message-thread.component.html',
  styleUrls: ['./message-thread.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
  standalone: true,
  imports: [
    SubjectComponent,
    MatMenuModule,
    MessageParticipantsListComponent,
    MatButtonModule,
    NgIf,
    SvgIconComponent,
    NgFor,
    MessageComponent,
    CKEditorModule,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    LoadingIndicatorComponent,
    AsyncPipe,
    ProfilePicturePipe,
    MessageThreadParticipantsPipe,
    TranslateModule,
  ],
})
export class MessageThreadComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input() public messageThread: MessageThreadDetails;
  @Input() public sendingMessage: boolean;
  @Input() public teamMembers: TeamMemberLists;
  @Input() public shipmentOrderPeople: ShipmentOrderPerson[];
  @Input() public payloadOfDialog: MessageDialogPayload;
  @Input() public defaultParticipantSectionFlag: boolean;
  @Output() public send = new EventEmitter<MessagePayload>();
  @Output() public outSideClick = new EventEmitter<any>();
  @Output() public caseIdFromMessageThread = new EventEmitter<any>();
  @ViewChild('scrollableMessages') public scrollContainer: ElementRef;
  @ViewChild('messageInput') public messageInput: ElementRef<HTMLDivElement>;
  @ViewChild(MatMenuTrigger) public menuTrigger: MatMenuTrigger;

  public readonly RouteSegment = RouteSegment;
  public readonly messageControl = new FormControl<string>('');
  public readonly attachmentControl = new FormControl<FileList[]>();
  public readonly subjectControl = new FormControl<string>('', [Validators.required]);
  public readonly maxFileSizeInMb = 4;
  public readonly user$: Observable<User> = this.authService.getUser$();

  public attachments: File[];
  public filesExceededSizeLimit: File[];
  public isDraggingOver = false;
  public base64ImagesFromMessage: any = [];
  public newBody = '';
  public DetailOfMessageCreationCase$: Observable<any>;
  public nextButtonClickFlag: boolean;
  public listOfTaggedTeamMembers: any = [];
  public caseCreatedFlag = false;
  public listOfGuestUsers: any = [];
  public listOfUsersForAcc: any = [];

  /**
   * Only relevant when message thread does not exist yet but user added participants
   * The new participants will only be added when message thread is created
   */
  public newParticipants: Participant[] = [];

  private scrolled = false;
  private readonly destroyed$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public Editor: any = classicCustomBuild;

  // Import the config for CKEditor for tools :
  public config = {
    // include any other configuration you want
    extraPlugins: [Adapter],
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'underline',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'alignment',
      'indent',
      'outdent',
      '|',
      'imageUpload',
      'emoji',
    ],
    language: 'en',
    image: {
      toolbar: ['imageTextAlternative', 'imageStyle:inline', 'imageStyle:block', 'imageStyle:side', 'linkImage'],
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly fileExtensionService: FileExtensionService,
    private readonly store$: Store<commonMessageReducer.AppState>,
    public router: Router,
    private readonly renderer: Renderer2,
    private readonly messagingAWSService: MessagingAWSService,
    private readonly authService: AuthService
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target && !this.nextButtonClickFlag && this.listOfTaggedTeamMembers.length === 0) this.outSideClick.emit(true);
    });
  }

  public trackByMessageId: TrackByFunction<MessageThreadMessage> = (_: number, message: MessageThreadMessage) => message.id;

  public get hasAttachments(): boolean {
    return !isNil(this.attachments) && this.attachments.length > 0;
  }

  public get canSendMessage(): boolean {
    if (this.sendingMessage === true) {
      this.messageControl.setValue(null);
    }

    return !this.sendingMessage && (!isEmpty(this.messageControl.value) || this.hasAttachments || this.base64ImagesFromMessage.length > 0);
  }

  public get allParticipants(): Participant[] {
    if (this.isNewThread()) {
      return this.newParticipants;
    }

    return this.messageThread.participants.concat(this.newParticipants);
  }

  public get isLastMessageSentByCurrentUser(): boolean {
    const lastMessage = this.messageThread.messages.at(-1);
    return !isNil(lastMessage) && lastMessage.isOwn;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.sendingMessage) {
      this.messageControl.setDisable(this.sendingMessage);
      this.attachmentControl.setDisable(this.sendingMessage);
    }
    if (changes.messageThread) {
      this.handleMessageThreadChange(changes.messageThread);
      setTimeout(() => this.scrollToBottom());
    }
  }

  public ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.router.url.includes('dashboard')
      ? this.store$.dispatch(commonMessageAction.loadAccountParticipants())
      : this.store$.dispatch(commonMessageAction.loadShipmentOrderParticipants({ SOID: this.payloadOfDialog.shipment.id }));

    // tslint:disable-next-line: early-exit
    if (this.isNewThread() && this.messageThread.participants.length > 0) {
      this.newParticipants = this.messageThread.participants.map((participant) => ({
        ...participant,
        isNew: true,
      }));
    }
  }

  // when current user sends a new message we need to reset the input if the message was successfully sent
  // we can know that here when the last message is coming from current user and is different from the previous one
  private handleMessageThreadChange(change: SimpleChange): void {
    this.subjectControl.setValue(this.messageThread.subject);
    this.newParticipants = [];
    const previousLastMessageIndex: number | undefined = isNil(change.previousValue?.messages.length)
      ? undefined
      : change.previousValue.messages.length - 1;
    const previousLastMessage: MessageThreadMessage | undefined = isNil(previousLastMessageIndex)
      ? undefined
      : change.previousValue.messages[previousLastMessageIndex];
    const currentLastMessageIndex: number | undefined = isNil(change.currentValue?.messages.length)
      ? undefined
      : change.currentValue.messages.length - 1;
    const currentLastMessage: MessageThreadMessage | undefined = isNil(currentLastMessageIndex)
      ? undefined
      : change.currentValue.messages[currentLastMessageIndex];
    const currentUserSentMessage = currentLastMessage?.isOwn && currentLastMessage?.id !== previousLastMessage?.id;
    if (!currentUserSentMessage) {
      return;
    }
    this.messageControl.setValue('');
    this.attachmentControl.setValue([]);
    this.attachments = [];
    this.newParticipants = [];
  }

  public ngAfterViewInit(): void {
    if (!this.scrolled) {
      this.scrollToBottom();
    }

    // For open the menu by default :
    if (this.defaultParticipantSectionFlag) this.menuTrigger.openMenu();
  }

  private scrollToBottom(): void {
    this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
  }

  public get subject(): string {
    return this.subjectControl.value;
  }

  public handleFileInputChange(event: Event): void {
    const fileInput: HTMLInputElement = event.target as HTMLInputElement;
    this.handleFiles(fileInput.files);
    this.cdr.markForCheck();
  }

  public handleFiles(fileList: FileList | null): void {
    if (!fileList && fileList.length > 0) {
      return;
    }

    const [file] = [...fileList];
    this.filesExceededSizeLimit = file.size >= this.maxFileSizeInMb * bytesPerMB ? [file] : [];
    this.attachments = file.size < this.maxFileSizeInMb * bytesPerMB ? [file] : [];
    this.attachments = this.fileExtensionService.handleDisallowedExtensions(this.attachments);
  }

  public onDismissAttachmentClick(attachment: File): void {
    if (this.sendingMessage) {
      return;
    }
    this.attachments = this.attachments.filter((item) => item !== attachment);
  }

  public onThreadScroll(): void {
    this.scrolled = true;
  }

  public onTextAreaInput(): void {
    this.scrollToBottom();
  }

  public onSendMessageClick(): void {
    if (this.subjectControl.invalid) {
      this.subjectControl.markAllAsTouched();
      return;
    }

    const body = this.messageControl.value;
    // getting the data of message ck-editor :
    this.getMessageContent(body);

    const newAccountManagers: Participant[] =
      this.shipmentOrderPeople?.filter(
        (person) =>
          person.role === TeamMemberRole.AccountManager &&
          !this.messageThread.participants.some((participant) => participant.id === person.id)
      ) || [];
    const newParticipants = uniqBy(
      [...this.newParticipants, ...this.extractParticipants(body), ...newAccountManagers],
      (teamMember) => teamMember.id
    );

    if (!this.hasAttachments && this.base64ImagesFromMessage.length <= 0 && !isEmpty(this.newBody)) {
      return this.send.emit({
        subject: this.subject,
        body: body && body.includes('img') ? this.newBody : body,
        participants: newParticipants,
      });
    }

    if (this.newBody) this.newBody = null;

    forkJoin(
      this.attachments?.map((attachment) =>
        FileHelper.readFileAsBase64$(attachment).pipe(map((fileBody) => ({ name: attachment.name, body: fileBody })))
      )
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((attachments) => {
        this.send.emit({
          subject: this.subject,
          body,
          attachments,
          participants: newParticipants,
        });
      });

    this.base64ImagesFromMessage = [];
  }

  public onParticipantAdd(participant: Participant): void {
    this.newParticipants = this.newParticipants.concat({
      ...participant,
      isNew: true,
    });
  }

  public onParticipantRemove(participant: Participant): void {
    this.newParticipants = this.newParticipants.filter(({ id }) => id !== participant.id);
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private isNewThread(): boolean {
    return isNil(this.messageThread.id) || this.messageThread.messages.length === 0;
  }

  private extractParticipants(body: string): Participant[] {
    return this.teamMembers.mentionable.filter(
      (teamMember) =>
        body.includes(`@${teamMember.firstName} ${teamMember.lastName}`) &&
        !this.messageThread.participants.find((participant) => participant.id === teamMember.id)
    );
  }

  public removeInvalidFileClick(fileName: string): void {
    this.filesExceededSizeLimit = this.filesExceededSizeLimit.filter((file) => file.name !== fileName);
  }

  public onDragEnter(): void {
    this.isDraggingOver = true;
  }

  public onDragLeave(): void {
    this.isDraggingOver = false;
  }

  public onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDraggingOver = false;
    if (isNil(event.dataTransfer)) {
      return;
    }

    this.handleFiles(event.dataTransfer.files);
  }

  public dragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  // This is for the getting the data from input ( ck-editor ) :
  // eslint-disable-next-line unused-imports/no-unused-vars, sonarjs/cognitive-complexity
  private getMessageContent(messageControlValue: any): void {
    if (messageControlValue) {
      // getting the multiple with proper image src's :
      const images = document.querySelectorAll('img');
      if (images) {
        this.base64ImagesFromMessage = [];
        images.forEach((ele) => {
          if (!ele.src.includes('https') && !ele.src.includes('http')) {
            // getting the src from message :
            // eslint-disable-next-line prefer-template
            this.base64ImagesFromMessage.push({ name: uuidv4() + '.png', body: ele.src.replace(/^data:(.*,)?/, '') });
            this.messageControl.setValue(this.messageControl.value.replace(ele.src, ''));
            this.messageControl.setValue(this.messageControl.value.replace('<img src="">', ''));
            this.messageControl.setValue(this.messageControl.value.replace('<figure class="image"></figure>', ''));
          }
        });

        this.newBody = this.messageControl.value;

        const newAccountManagers: Participant[] =
          this.shipmentOrderPeople?.filter(
            (person) =>
              person.role === TeamMemberRole.AccountManager &&
              !this.messageThread.participants.some((participant) => participant.id === person.id)
          ) || [];
        const newParticipants = uniqBy(
          [...this.newParticipants, ...this.extractParticipants(this.newBody), ...newAccountManagers],
          (teamMember) => teamMember.id
        );

        if (this.base64ImagesFromMessage.length > 0) {
          this.base64ImagesFromMessage.forEach((ele: any) => {
            this.send.emit({
              subject: this.subject,
              body: '',
              attachments: [ele],
              participants: newParticipants,
            });
          });
        }

        this.base64ImagesFromMessage = [];
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-member-accessibility
  onReady(editor: any) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, sonarjs/cognitive-complexity
  public async createMessageChannelCaseClick(event: any, userDetail: any): Promise<void> {
    event.stopPropagation();
    this.nextButtonClickFlag = true;

    await this.detailOfMessageCreationCases(true);

    let count = 0;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.DetailOfMessageCreationCase$
      ? this.DetailOfMessageCreationCase$.pipe().subscribe((DetailOfMessageCreationCase) => {
          if (DetailOfMessageCreationCase.Id && count === 0) {
            this.createChannel(DetailOfMessageCreationCase, userDetail.orgId, userDetail);
            count++;
          }
        })
      : null;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity, @typescript-eslint/explicit-module-boundary-types
  public createChannel(responseOfCreatedChannel: any, orgId: string, userDetail: any): void {
    let parentObjectDynamicValue: string;
    let parentNameDynamicValue: string;
    let parentIdDynamicValue: string;
    let shipmentOrderIdDynamicValue: string;
    let directRefUrlDynamicValue: any;
    if (
      (this.router.url.includes('shipments-list') && !this.router.url.includes('task')) ||
      this.router.url.includes('quote') ||
      this.router.url.includes('tracking') ||
      this.router.url.includes('line-items') ||
      this.router.url.includes('new-quote/rollout')
    ) {
      parentObjectDynamicValue = 'Shipment Order';
      directRefUrlDynamicValue = window.location.href;
      parentIdDynamicValue = this.payloadOfDialog.shipment.id;
      shipmentOrderIdDynamicValue = this.payloadOfDialog.shipment.id;
      parentNameDynamicValue = `${this.payloadOfDialog.shipment.name} - ${this.payloadOfDialog.shipment.reference}`;
    } else if (
      this.router.url.includes('invoices') &&
      this.payloadOfDialog.id &&
      this.payloadOfDialog.invoiceName &&
      this.payloadOfDialog.shipment.id
    ) {
      parentObjectDynamicValue = 'Invoice';
      directRefUrlDynamicValue = window.location.href;
      shipmentOrderIdDynamicValue = this.payloadOfDialog.shipment.id;
      parentIdDynamicValue = this.payloadOfDialog.id;
      parentNameDynamicValue = `Invoice ${this.payloadOfDialog.invoiceName}`;
    } else if (
      (this.router.url.includes('shipments-list') && this.router.url.includes('task') && this.payloadOfDialog.shipment.id) ||
      (this.router.url.includes('dashboard') && this.router.url.includes('task') && this.payloadOfDialog.shipment.id)
    ) {
      parentObjectDynamicValue = 'Task';
      directRefUrlDynamicValue = window.location.href;
      shipmentOrderIdDynamicValue = this.payloadOfDialog.shipment.id;
      parentIdDynamicValue = this.payloadOfDialog.id;
      parentNameDynamicValue = `${this.subject} - ${this.payloadOfDialog.shipment.title} ${this.payloadOfDialog.shipment.reference}`;
    } else if (
      this.router.url.includes('message') ||
      (this.router.url.includes('dashboard') && !this.router.url.includes('task')) ||
      this.router.url.includes('invoices') ||
      this.router.url.includes('basics')
    ) {
      parentObjectDynamicValue = 'Account';
      parentNameDynamicValue = userDetail.accountName;
      parentIdDynamicValue = userDetail.accountId;
      shipmentOrderIdDynamicValue = null;
      directRefUrlDynamicValue = `${window.location.href.split(this.router.url)[0]}/messages/${responseOfCreatedChannel.Id}`;
    }

    let users = [];

    for (const elementOfTaggedMember of this.listOfTaggedTeamMembers) {
      let userTypeDynamicValue: any = '';
      let userRoleDynamicValue: any = '';
      let userCompanyDynamicValue: any = '';
      let affiliationTypeDynamicValue: any = '';

      // For ACTecexPeople, SOTecexPeople and AllTecexPeople :
      if (
        this.listOfUsersForAcc.AllTecexPeople?.find((x: any) => x.UserID === elementOfTaggedMember.id) ||
        this.listOfUsersForAcc.ACTecexPeople?.find((x: any) => x.UserID === elementOfTaggedMember.id) ||
        this.listOfUsersForAcc.SOTecexPeople?.find((x: any) => x.UserID === elementOfTaggedMember.id)
      ) {
        userTypeDynamicValue = 'Internal';
        userRoleDynamicValue = 'ACTecexPeople';
        userCompanyDynamicValue = SourceApp.TecEx;
      }
      // For client user and client contacts :
      else if (
        this.listOfUsersForAcc.ClientUsers?.find((x: any) => x.ContactId === elementOfTaggedMember.id) ||
        this.listOfUsersForAcc.ClientContacts?.find((x: any) => x.ContactId === elementOfTaggedMember.id)
      ) {
        userTypeDynamicValue = 'Client';
        userRoleDynamicValue = null;
        userCompanyDynamicValue = this.listOfUsersForAcc.ClientUsers?.find((x: any) => x.ContactId === elementOfTaggedMember.id).CompanyName
          ? this.listOfUsersForAcc.ClientUsers?.find((x: any) => x.ContactId === elementOfTaggedMember.id).CompanyName
          : this.listOfUsersForAcc.ClientContacts?.find((x: any) => x.ContactId === elementOfTaggedMember.id).CompanyName;
      }
      // For third-party participants :
      else if (this.listOfUsersForAcc.ThirdPartyPeople?.find((x: any) => x.Id === elementOfTaggedMember.id)) {
        userTypeDynamicValue = 'Affiliate';
        userRoleDynamicValue = null;
        userCompanyDynamicValue = this.listOfUsersForAcc.ThirdPartyPeople?.find((x: any) => x.Id === elementOfTaggedMember.id)
          .ThirdPartyCompany;
        affiliationTypeDynamicValue = this.listOfUsersForAcc.ThirdPartyPeople?.find((x: any) => x.Id === elementOfTaggedMember.id)
          .AffiliateType;
      }
      const objForUserList = {
        id: elementOfTaggedMember.id,
        email: elementOfTaggedMember.Useremail ? elementOfTaggedMember.Useremail : elementOfTaggedMember.userEmail,
        nickname: elementOfTaggedMember.nickName
          ? elementOfTaggedMember.nickName
          : `${elementOfTaggedMember.FirstName} ${elementOfTaggedMember.LastName}`,
        isMuted: elementOfTaggedMember.DefaultMuteStatus ? true : false,
        userType: userTypeDynamicValue ? userTypeDynamicValue : null,
        userRole: userRoleDynamicValue ? userRoleDynamicValue : null,
        userCompany: userCompanyDynamicValue ? userCompanyDynamicValue : null,
        affiliationType: affiliationTypeDynamicValue ? affiliationTypeDynamicValue : null,
        profileUrlWebApp: `${window.location.href.split(this.router.url)[0]}/profile/my-team`,
      };

      users.push(objForUserList);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    if (this.listOfGuestUsers.length > 0) users = users.concat(this.listOfGuestUsers);

    this.caseCreatedFlag = responseOfCreatedChannel.Id ? true : false;

    const channelCreateWithUserDetail: any = {
      orgId: `${orgId}`,
      orgMyName: 'tecex--merging',
      caseId: responseOfCreatedChannel.Id,
      accountId: userDetail.accountId,
      sendbirdChannelUrl: `${orgId}-${this.messageThread.id ? this.messageThread.id : responseOfCreatedChannel.Id}`,
      emailTicketId: `${'TX'}${responseOfCreatedChannel.CaseNumber}`,
      channelName: '-= DRAFT =-',
      clientName: userDetail.accountName,
      lastActivityDate: utc(),
      lastModifiedBy: userDetail.id,
      lastEmailSent: null,
      accountTzLocation: responseOfCreatedChannel.Account.Preferred_Time_Zone__c,
      channelStatus: 'open',
      reOpenEnabled: false,
      businessUnit: SourceApp.TecEx,

      // Dynamically manage via page wise :
      parentObject: parentObjectDynamicValue,
      parentName: parentNameDynamicValue,
      parentId: parentIdDynamicValue,
      shipmentOrderId: shipmentOrderIdDynamicValue,
      directRefUrl: directRefUrlDynamicValue,
      guestUserEnabled: this.router.url.includes('invoices') ? false : true,

      // add user dynamically :
      users,
    };

    this.caseIdFromMessageThread.emit({
      caseId: this.messageThread.id ? this.messageThread.id : responseOfCreatedChannel.Id,
      orgId: userDetail.orgId,
    });

    this.messagingAWSService.channelCreate(channelCreateWithUserDetail, orgId).subscribe((data: any) => {
      this.menuTrigger.closeMenu();
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, sonarjs/cognitive-complexity
  public async updateChannel(userDetail: any): Promise<void> {
    await this.detailOfMessageCreationCases(false);

    let count = 0;
    let responseOfCreatedChannel: any;

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.DetailOfMessageCreationCase$
      ? this.DetailOfMessageCreationCase$.subscribe((DetailOfMessageCreationCase) => {
          if (DetailOfMessageCreationCase.Id && count === 0) {
            responseOfCreatedChannel = DetailOfMessageCreationCase;
            count++;
          }
        })
      : null;

    if (this.messageThread.id || responseOfCreatedChannel.Id) {
      let users = [];

      for (const elementOfTaggedMember of this.listOfTaggedTeamMembers) {
        let userTypeDynamicValue: any = '';
        let userRoleDynamicValue: any = '';
        let userCompanyDynamicValue: any = '';
        let affiliationTypeDynamicValue: any = '';

        // For ACTecexPeople, SOTecexPeople and AllTecexPeople :
        if (
          this.listOfUsersForAcc.AllTecexPeople?.find((x: any) => x.UserID === elementOfTaggedMember.id) ||
          this.listOfUsersForAcc.ACTecexPeople?.find((x: any) => x.UserID === elementOfTaggedMember.id) ||
          this.listOfUsersForAcc.SOTecexPeople?.find((x: any) => x.UserID === elementOfTaggedMember.id)
        ) {
          userTypeDynamicValue = 'Internal';
          userRoleDynamicValue = 'ACTecexPeople';
          userCompanyDynamicValue = SourceApp.TecEx;
        }
        // For client user and client contacts :
        else if (
          this.listOfUsersForAcc.ClientUsers?.find((x: any) => x.ContactId === elementOfTaggedMember.id) ||
          this.listOfUsersForAcc.ClientContacts?.find((x: any) => x.ContactId === elementOfTaggedMember.id)
        ) {
          userTypeDynamicValue = 'Client';
          userRoleDynamicValue = null;
          userCompanyDynamicValue = this.listOfUsersForAcc.ClientUsers?.find((x: any) => x.ContactId === elementOfTaggedMember.id)
            .CompanyName
            ? this.listOfUsersForAcc.ClientUsers?.find((x: any) => x.ContactId === elementOfTaggedMember.id).CompanyName
            : this.listOfUsersForAcc.ClientContacts?.find((x: any) => x.ContactId === elementOfTaggedMember.id).CompanyName;
        }
        // For third-party participants :
        else if (this.listOfUsersForAcc.ThirdPartyPeople?.find((x: any) => x.Id === elementOfTaggedMember.id)) {
          userTypeDynamicValue = 'Affiliate';
          userRoleDynamicValue = null;
          userCompanyDynamicValue = this.listOfUsersForAcc.ThirdPartyPeople?.find((x: any) => x.Id === elementOfTaggedMember.id)
            .ThirdPartyCompany;
          affiliationTypeDynamicValue = this.listOfUsersForAcc.ThirdPartyPeople?.find((x: any) => x.Id === elementOfTaggedMember.id)
            .AffiliateType;
        }

        const objForUserList = {
          id: elementOfTaggedMember.id,
          email: elementOfTaggedMember.Useremail ? elementOfTaggedMember.Useremail : elementOfTaggedMember.userEmail,
          nickname: elementOfTaggedMember.nickName
            ? elementOfTaggedMember.nickName
            : `${elementOfTaggedMember.FirstName} ${elementOfTaggedMember.LastName}`,
          isMuted: elementOfTaggedMember.DefaultMuteStatus ? true : false,
          userType: userTypeDynamicValue,
          userRole: userRoleDynamicValue,
          userCompany: userCompanyDynamicValue,
          affiliationType: affiliationTypeDynamicValue,
          profileUrlWebApp: `${window.location.href.split(this.router.url)[0]}/profile/my-team`,
        };

        users.push(objForUserList);
      }

      this.caseIdFromMessageThread.emit({
        caseId: this.messageThread.id ? this.messageThread.id : responseOfCreatedChannel.Id,
        orgId: userDetail.orgId,
      });

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      if (this.listOfGuestUsers.length > 0) users = users.concat(this.listOfGuestUsers);

      const userListOfDetail: any = {
        // orgId-caseId = orgId and existing case id :
        sendbirdChannelUrl: `${userDetail.orgId}-${this.messageThread.id ? this.messageThread.id : responseOfCreatedChannel.Id}`,

        // add user dynamically :
        users,
      };

      this.messagingAWSService.updateChannelUserList(userListOfDetail, `${userDetail.orgId}`).subscribe((data: any) => {
        this.menuTrigger.closeMenu();
      });
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public detailOfMessageCreationCases(createMessageCaseFlag: boolean): void {
    // For Dashboard / Invoices Tab in General / Messages Tab (left side) / New Quote Creation :
    if (
      (this.router.url.includes('dashboard') && !this.router.url.includes('task')) ||
      (this.router.url.includes('message') && !this.router.url.includes('task')) ||
      (this.router.url.includes('invoices') &&
        !this.payloadOfDialog.id &&
        !this.payloadOfDialog.shipment &&
        !this.payloadOfDialog.shipment?.id) ||
      this.router.url.includes('basics')
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      createMessageCaseFlag ? this.store$.dispatch(commonMessageAction.createMessageChannelCase({})) : '';
      this.DetailOfMessageCreationCase$ = this.store$.select(commonMessageReducer.selectDetailOfMessageCreationCase);
    }
    // For Quote in Quotes Tab / Shipment Order tab / Tracking Tab / In new quote - line-items :
    else if (
      this.router.url.includes('quotes') ||
      (this.router.url.includes('shipments-list') &&
        this.payloadOfDialog.shipment &&
        (!this.payloadOfDialog.shipment?.id || !this.payloadOfDialog.id)) ||
      this.router.url.includes('tracking') ||
      this.router.url.includes('line-items')
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      createMessageCaseFlag
        ? this.store$.dispatch(commonMessageAction.createMessageChannelCase({ SOID: this.payloadOfDialog.shipment.id }))
        : '';
      this.DetailOfMessageCreationCase$ = this.store$.select(commonMessageReducer.selectDetailOfMessageCreationCase);
    }
    // For Specific Invoice on the Invoices Tab :
    else if (this.router.url.includes('invoices') && this.payloadOfDialog.id && this.payloadOfDialog.shipment.id) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      createMessageCaseFlag
        ? this.store$.dispatch(
            commonMessageAction.createMessageChannelCase({
              SOID: this.payloadOfDialog.shipment.id,
              InvoiceID: this.payloadOfDialog.id,
            })
          )
        : '';
      this.DetailOfMessageCreationCase$ = this.store$.select(commonMessageReducer.selectDetailOfMessageCreationCase);
    }
    // For Specific Shipment Order in the Shipment Orders tab / Task from the Dashboard Tab :
    else if (this.router.url.includes('shipments-list') && this.payloadOfDialog.shipment.id && this.payloadOfDialog.id) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      createMessageCaseFlag
        ? this.store$.dispatch(
            commonMessageAction.createMessageChannelCase({ SOID: this.payloadOfDialog.shipment.id, TaskID: this.payloadOfDialog.id })
          )
        : '';
      this.DetailOfMessageCreationCase$ = this.store$.select(commonMessageReducer.selectDetailOfMessageCreationCase);
    }
    // For Specific Task from the Dashboard Tab :
    // eslint-disable-next-line sonarjs/no-duplicated-branches
    else if (this.router.url.includes('dashboard') && this.payloadOfDialog.shipment.id && this.payloadOfDialog.id) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      createMessageCaseFlag
        ? this.store$.dispatch(
            commonMessageAction.createMessageChannelCase({ SOID: this.payloadOfDialog.shipment.id, TaskID: this.payloadOfDialog.id })
          )
        : '';
      this.DetailOfMessageCreationCase$ = this.store$.select(commonMessageReducer.selectDetailOfMessageCreationCase);
    }
  }

  public cancelButtonClick(): void {
    this.outSideClick.emit(true);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onTaggeedTeamMemberChanges(participants: any): void {
    this.listOfTaggedTeamMembers = participants;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onGuestUsersChanges(participants: any): void {
    this.listOfGuestUsers = participants;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onListOfACCUsers(listOfUsers: any): void {
    this.listOfUsersForAcc = listOfUsers;
  }
}
