<div class="dialog-container">
  <div class="dialog-content">
    <div class="d-flex justify-content-between">
      <h3 class="dialog-title mb-0" [innerHTML]="data.payload.title"></h3>
      <app-message-button [user]="data.payload.defaultTeamMember" (buttonClick)="onMessageClick()"></app-message-button>
    </div>

    <div class="text-reduced-regular color-text-secondary" [innerHTML]="data.payload.description"></div>

    <form [formGroup]="form" (ngSubmit)="onSubmitClick()">
      <div class="part-table">
        <table class="table">
          <thead>
            <tr>
              <th *ngFor="let header of recordsHeaders">{{ header.label }}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="records">
              <ng-container *ngFor="let recordForm of records.controls; let formIndex = index">
                <tr>
                  <td *ngFor="let field of initialRecords[formIndex]" [ngClass]="field.type === 'select' ? 'selectBar' : ''">
                    <app-question [field]="field" [form]="recordForm"></app-question>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </form>
  </div>

  <div class="dialog-footer">
    <div class="cross"></div>
    <div class="d-flex align-items-center justify-content-end w-100">
      <button mat-button type="button" class="secondary-button mr-3" (click)="onCancelClick()">
        {{ 'COMMON.CANCEL' | translate }}
      </button>
      <div
        class="d-inline-block"
        [appTooltip]="data.payload.isInactive && 'TASKS.INACTIVE_TOOLTIP' | translate"
        [appTooltipStrategy]="'hover'"
      >
        <button mat-button class="primary-button" (click)="onSubmitClick()" [disabled]="!isValid">
          {{ 'COMMON.SUBMIT' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
