import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PortalModule } from '@angular/cdk/portal';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { DialogCloseDirective } from '../../directives/dialog-close.directive';
import { DialogBaseContainerComponent } from '../dialog-base-container/dialog-base-container.component';

@Component({
  templateUrl: './dialog-app-nav-drawer-container.component.html',
  styleUrls: ['./dialog-app-nav-drawer-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('state', [
      state('void, exit', style({ opacity: 0, transform: `translateX(-100%)` })),
      transition(':enter', animate('150ms cubic-bezier(0, 0, 0.2, 1)', style({ transform: 'none', opacity: 1 }))),
      transition(':leave', animate('75ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: 0, transform: 'translateX(-100%)' }))),
    ]),
  ],
  standalone: true,
  imports: [NgIf, MatButtonModule, DialogCloseDirective, SvgIconComponent, PortalModule],
})
export class DialogAppNavDrawerContainerComponent extends DialogBaseContainerComponent {}
