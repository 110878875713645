<!--  HEADER -->
<div class="header">
  <div class="mb-2">
    <app-subject
      [subjectControl]="subjectControl"
      [shipment]="messageThread.shipment"
      [editable]="!messageThread.id"
      [recordType]="messageThread.recordType"
    ></app-subject>
  </div>
  <div class="participants-container d-flex align-items-center">
    <img class="profile-picture picture mr-2" [src]="messageThread.photoUrl | profilePicture" />
    <!-- TODO: Based on Zeplin, there should be a name here which is not coming from the participant list
     but from somewhere else: https://zpl.io/bJyqRd6 -->
    <div class="text-reduced-bold color-text-primary mr-2">{{ allParticipants | messageThreadParticipants }}</div>
    <a [matMenuTriggerFor]="menu" class="participants text-link sm-dark font-weight-bold">
      {{ 'MESSAGES.EDIT_PARTICIPANTS' | translate }}
    </a>

    <mat-menu #menu="matMenu" xPosition="before" backdropClass="context-menu_backdrop" class="mat-menu-custom-changes">
      <app-message-participants-list
        [teamMembers]="teamMembers.taggable"
        [participants]="allParticipants"
        [title]="'MESSAGES.ADD_PARTICIPANTS'"
        (add)="onParticipantAdd($event)"
        (remove)="onParticipantRemove($event)"
        (taggedTeamMembersList)="onTaggeedTeamMemberChanges($event)"
        (guestUsersList)="onGuestUsersChanges($event)"
        (listOfACCUsers)="onListOfACCUsers($event)"
      ></app-message-participants-list>

      <!-- Cancel and next / save button for participants section -->
      <div class="request-cancel-btn-wrapper">
        <div class="d-flex align-items-center justify-content-end mt-4">
          <button mat-button type="button" appDialogClose class="secondary-button mr-2" (click)="cancelButtonClick()">Cancel</button>
          <div class="d-inline-block" *ngIf="user$ | async as user">
            <ng-container *ngIf="caseCreatedFlag || (router.url.includes('messages') && messageThread.id); else createMessageChannelCase">
              <button
                mat-button
                type="button"
                class="primary-button"
                [disabled]="!listOfTaggedTeamMembers.length"
                (click)="
                  caseCreatedFlag || (router.url.includes('messages') && messageThread.id)
                    ? updateChannel(user)
                    : createMessageChannelCaseClick($event, user)
                "
              >
                {{ caseCreatedFlag || (router.url.includes('messages') && messageThread.id) ? 'Save' : 'Next' }}
              </button>
            </ng-container>
            <ng-template #createMessageChannelCase>
              <button
                mat-button
                type="button"
                class="primary-button"
                [disabled]="!listOfTaggedTeamMembers.length"
                (click)="createMessageChannelCaseClick($event, user)"
              >
                Next
              </button>
            </ng-template>
          </div>
        </div>
      </div>
    </mat-menu>
  </div>
</div>

<!--  CONTENT -->
<div class="content" (dragenter)="onDragEnter()" (drop)="onDrop($event)" (dragover)="dragOver($event)">
  <div *ngIf="isDraggingOver" (dragleave)="onDragLeave()" class="drop-overlay">
    <div>
      <svg-icon key="drag-and-drop" [fontSize]="50"></svg-icon>
      <span class="drop-text">{{ 'MESSAGES.DRAG_AND_DROP' | translate }}</span>
    </div>
  </div>
  <div #scrollableMessages class="messages-container" (scroll)="onThreadScroll()">
    <app-message *ngFor="let message of messageThread.messages; trackBy: trackByMessageId" [message]="message"> </app-message>
  </div>
</div>

<div class="p-4">
  <div
    *ngIf="isLastMessageSentByCurrentUser"
    class="ghost-message text-micro-regular"
    [innerHtml]="'MESSAGES.MESSAGE_SENT_HINT' | translate"
  ></div>
</div>

<!-- FOOTER -->
<div class="footer p-4">
  <div class="d-flex align-items-end">
    <div
      class="input-field d-flex flex-grow-1"
      [class.empty]="!messageControl.value && !hasAttachments"
      [class.disabled]="messageControl.disabled"
    >
      <div class="text-area-container">
        <span class="drop-text" *ngIf="isDraggingOver">{{ 'MESSAGES.DRAG_AND_DROP' | translate }}</span>
        <ckeditor
          type="inline"
          [editor]="Editor"
          (ready)="onReady($event)"
          (input)="onTextAreaInput()"
          id="editor"
          placeholder="MESSAGES.INPUT_PLACEHOLDER"
          class="message-input text-tiny-regular color-text-primary ck-editor-message-input"
          [ngClass]="attachments && attachments.length ? 'attachment-display' : 'message-input-width'"
          [formControl]="messageControl"
          [hidden]="isDraggingOver"
        ></ckeditor>
        <div *ngIf="!hasAttachments; else attachmentList" class="d-flex align-items-center">
          <button type="button" mat-icon-button class="attachment-button color-secondary" (click)="attachmentInput.click()">
            <svg-icon key="attachment"></svg-icon>
          </button>
        </div>
      </div>
      <ng-template #attachmentList>
        <div
          class="attachments d-flex align-items-center"
          [ngClass]="attachments && attachments.length ? 'attachment-display-align-item' : 'd'"
        >
          <div>
            <div
              *ngFor="let attachment of attachments; let index = index"
              class="d-flex align-items-center text-reduced-bold color-secondary"
            >
              <svg-icon key="attachment"></svg-icon>
              <span class="mx-1">{{ attachment.name }}</span>
              <button type="button" mat-icon-button class="close-icon" (click)="onDismissAttachmentClick(attachment)">
                <svg-icon key="close"></svg-icon>
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <button type="button" mat-icon-button class="send-message-button mb-2 ml-2" [disabled]="!canSendMessage" (click)="onSendMessageClick()">
      <app-loading-indicator *ngIf="sendingMessage; else sendButton" [diameter]="24"></app-loading-indicator>
      <ng-template #sendButton>
        <svg-icon key="send2"></svg-icon>
      </ng-template>
    </button>
  </div>
  <ng-container *ngIf="filesExceededSizeLimit?.length">
    <div
      *ngFor="let file of filesExceededSizeLimit"
      class="d-flex justify-content-end align-items-center text-micro-regular color-error mt-1 file-size-hint"
    >
      <div [innerHTML]="'ERROR.FILE_SIZE_LIMIT_EXCEEDED' | translate: { fileName: file.name, maxFileSize: maxFileSizeInMb }"></div>
      <button mat-icon-button type="button" class="text-reduced-bold" (click)="removeInvalidFileClick(file.name)">
        <svg-icon key="close"></svg-icon>
      </button>
    </div>
  </ng-container>
</div>

<input #attachmentInput type="file" hidden [formControl]="attachmentControl" (change)="handleFileInputChange($event)" data-hj-allow />
