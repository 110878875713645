import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControl } from '@ngneat/reactive-forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { PickupPreference } from '@tecex-api/data';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { ValidatorHelperService } from '../../../../services/validator-helper.service';
import { PickupPreferenceTranslationKeyPipe } from '../../../common-address/pipes/pickup-preference-translation-key.pipe';
import { BlockComponent } from '../block/block.component';
import { FormControlInputDirective } from '../../../form-control/directives/form-control-input.directive';
import { SelectComponent } from '../../../select/select.component';
import { FormControlLabelDirective } from '../../../form-control/directives/form-control-label.directive';
import { FormControlIconDirective } from '../../../form-control/directives/form-control-icon.directive';
import { FormControlComponent } from '../../../form-control/components/form-control/form-control.component';

@Component({
  selector: 'app-pickup-preference-block',
  templateUrl: './pickup-preference-block.component.html',
  styleUrls: ['./pickup-preference-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PickupPreferenceTranslationKeyPipe],
  standalone: true,
  imports: [
    FormControlComponent,
    FormControlIconDirective,
    FormControlLabelDirective,
    SelectComponent,
    FormControlInputDirective,
    FormsModule,
    ReactiveFormsModule,
    AsyncPipe,
    TranslateModule,
  ],
})
export class PickupPreferenceBlockComponent extends BlockComponent<undefined, PickupPreference> implements OnInit {
  public readonly pickupPreferenceControl = new FormControl(undefined, Validators.required);
  public readonly pickupPreferenceData = [
    {
      value: PickupPreference.TECEX_ARRANGES_PICKUP,
      viewValue: this.translateService.instant(this.pickupPreferenceTranslationKeyPipe.transform(PickupPreference.TECEX_ARRANGES_PICKUP)),
    },
    {
      value: PickupPreference.CLIENT_ARRANGES_PICKUP,
      viewValue: this.translateService.instant(this.pickupPreferenceTranslationKeyPipe.transform(PickupPreference.CLIENT_ARRANGES_PICKUP)),
    },
    {
      value: PickupPreference.DEDICATED_PICKUP___FED_EX,
      viewValue: this.translateService.instant(
        this.pickupPreferenceTranslationKeyPipe.transform(PickupPreference.DEDICATED_PICKUP___FED_EX)
      ),
    },
    {
      value: PickupPreference.DEDICATED_PICKUP___DHL,
      viewValue: this.translateService.instant(this.pickupPreferenceTranslationKeyPipe.transform(PickupPreference.DEDICATED_PICKUP___DHL)),
    },
  ];
  public readonly pickupPreferenceError$: Observable<string | null> = this.validatorHelperService.getError$(this.pickupPreferenceControl);

  constructor(
    private readonly validatorHelperService: ValidatorHelperService,
    private readonly translateService: TranslateService,
    private readonly pickupPreferenceTranslationKeyPipe: PickupPreferenceTranslationKeyPipe
  ) {
    super();
  }

  public ngOnInit(): void {
    this.register.next(this.pickupPreferenceControl);
  }
}
