import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgSwitch, NgSwitchCase, NgIf, NgForOf, CurrencyPipe, NgClass } from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { MatSelectModule } from '@angular/material/select';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DIALOG_DATA } from '../../../dialog/dialog.tokens';
import { DialogData } from '../../../dialog/interfaces/dialog-data.interface';
import { TooltipDirective } from '../../../tooltip/tooltip.directive';
import { SelectionComponent } from '../../../selection/selection.component';
import { ShipmentSummaryComponent } from '../shipment-summary/shipment-summary.component';
import { MessageButtonComponent } from '../../../message-button/message-button.component';
import { LineItemsColumn } from '../../../../enums/line-items-column.enum';
import { SerialNumbersDialogPayload, serialNumbersParts } from './serial-numbers-dialog-payload.interface';
import { SerialNumbersDialogService } from './serial-numbers-dialog.service';

@Component({
  selector: 'app-serial-numbers',
  templateUrl: './serial-numbers-dialog.component.html',
  styleUrls: ['./serial-numbers-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase,
    MessageButtonComponent,
    NgIf,
    ShipmentSummaryComponent,
    SelectionComponent,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatButtonModule,
    TooltipDirective,
    TranslateModule,
    NgForOf,
    CurrencyPipe,
    MatFormFieldModule,
    MatSelectModule,
    NgClass,
  ],
})
export class SerialNumbersDialogComponent implements OnInit {
  initialParts: serialNumbersParts[];
  private readonly destroy$ = new Subject<void>();
  partsForm: UntypedFormGroup = this.formBuilder.group({
    parts: this.formBuilder.array([]),
  });
  isValid = false;
  soId: string;

  constructor(
    @Inject(DIALOG_DATA) public readonly data: DialogData<SerialNumbersDialogPayload, boolean>,
    private readonly formBuilder: FormBuilder,
    private readonly serialNumbersDialogService: SerialNumbersDialogService
  ) {
    this.soId = data.payload.shipmentOrder.id;
    this.initialParts = data.payload.parts;
    this.partsForm.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.isValid = this.partsForm.valid;
    });
  }

  get parts(): FormArray<any> {
    return this.partsForm.get('parts') as FormArray<any>;
  }

  newPart(part): FormGroup<any> {
    return this.formBuilder.group({
      id: new FormControl(part.id),
      name: new FormControl(part.name),
      serialNumber: new FormControl(part.serialNumber, Validators.required),
      description: new FormControl(part.description),
      quantity: new FormControl(part.quantity),
      unitPrice: new FormControl(part.unitPrice),
    });
  }

  addInitialParts(part) {
    this.parts.push(this.newPart(part));
  }

  onSubmit() {}

  public ngOnInit(): void {
    this.initialParts.forEach((part) => this.addInitialParts(part));
  }

  public onMessageClick(): void {
    this.data.payload.onMsgClickHandler();
  }

  public onCancelClick(): void {
    this.data.dialogRef.close();
  }

  public onSubmitClick(): void {
    this.serialNumbersDialogService.updateParts$(this.partsForm.value.parts, this.soId).subscribe(() => this.data.dialogRef.close(true));
  }

  protected readonly LineItemsColumn = LineItemsColumn;
}
