import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { StateIndicatorCircleState } from '../state-indicator-circle/state.enum';
import { ProfilePicturePipe } from '../../pipes/profile-picture.pipe';
import { LoadingIndicatorComponent } from '../loading-indicator/components/loading-indicator/loading-indicator.component';
import { StateIndicatorCircleComponent } from '../state-indicator-circle/state-indicator-circle.component';
import { MessageButtonUserVM as UserVM } from './user.vm';

@Component({
  selector: 'app-message-button',
  templateUrl: './message-button.component.html',
  styleUrls: ['./message-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, StateIndicatorCircleComponent, LoadingIndicatorComponent, ProfilePicturePipe, TranslateModule],
})
export class MessageButtonComponent {
  @Input() public user: UserVM;
  @Output() public buttonClick = new EventEmitter();

  public state = StateIndicatorCircleState;

  public onClick(): void {
    this.buttonClick.emit();
  }
}
