import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import * as CryptoJS from 'crypto-js';
import { CONFIG_TOKEN } from '../../config/config.token';
import { GlobalConfig } from '../../config/global-config.interface';
import { redirectUrlSessionStorageKey } from '../../constants/global.constants';
import { RouteSegment } from '../../enums/route-segment.enum';
import { GlobalEnvironment } from '../../interfaces/global-environment.interface';
import { AnnouncmentService } from '../../modules/announcment/services/announcment.service';
import { AuthService } from '../../services/auth.service';
import { SERVE_PORT } from '../../tokens/app-serve-port.token';
import { ENVIRONMENT } from '../../tokens/environment.token';
import { LoginCallbackTokens } from '../../interfaces/login-callback-tokens.interface';

@Component({
  selector: 'app-login-callback',
  templateUrl: './login-callback.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, FormsModule, MatButtonModule],
})
export class LoginCallbackComponent implements OnInit {
  public readonly loginRedirectUrl = this.config.loginRedirectUrl;

  public showUi: boolean;

  public sfAccessToken: string;
  public userId: string;
  public accountId: string;
  public ncpAccessToken: string;
  public encryptedTokens: string;
  public tokens: LoginCallbackTokens;

  public readonly dashboardLink = [RouteSegment.Dashboard];

  public devHandle = '';

  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: GlobalConfig,
    @Inject(ENVIRONMENT) private readonly environment: GlobalEnvironment,
    @Inject(SERVE_PORT) private readonly servePort: number,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly announcmentService: AnnouncmentService
  ) {
    this.showUi = this.environment.debug && this.config.debugAuth;
  }

  public ngOnInit(): void {
    sessionStorage.removeItem('SelectedAccount');
    this.ncpAccessToken = atob(this.route.snapshot.queryParamMap.get('Act'));
    this.encryptedTokens = atob(this.route.snapshot.queryParamMap.get('Config'));

    //Start decryption process
    const tokensIv = CryptoJS.enc.Hex.parse(this.base64ToHex(this.encryptedTokens).slice(0, 32));
    const tokensCipherText = CryptoJS.enc.Hex.parse(this.base64ToHex(this.encryptedTokens).slice(32));
    const accessTokenKey = this.asciiToHex(this.ncpAccessToken.slice(0, 16));
    this.tokens = JSON.parse(this.decrypt_core_AES_CBC(accessTokenKey, tokensIv, tokensCipherText));
    this.sfAccessToken = this.tokens.sessionId;
    this.accountId = this.tokens.accountId;
    this.userId = this.tokens.userId;

    // Need to check if values exist before we apply base 64
    if (
      !this.sfAccessToken ||
      this.sfAccessToken === 'undefined' ||
      !this.userId ||
      this.userId === 'undefined' ||
      !this.accountId ||
      this.accountId === 'undefined' ||
      !this.ncpAccessToken ||
      this.ncpAccessToken === 'undefined'
    ) {
      //Force Logout User if error from SF
      this.authService.logout();
    }

    const autoRedirect = this.route.snapshot.queryParamMap.has('redirect');

    if (!this.showUi || autoRedirect) {
      this.continue();
    }
  }

  public onContinueClick(): void {
    this.continue();
  }

  public onContinueToDevContainerClick(): void {
    const queryString = this.route.snapshot.queryParamMap.keys
      .map((key) => `${key}=${this.route.snapshot.queryParamMap.get(key)}`)
      .join('&');

    window.location.href =
      'https://' + `${this.devHandle}` + '.app.tecexlabs.dev/' + `${RouteSegment.LoginCallback}?${queryString}&redirect`;
  }

  public onContinueToLocalhostClick(): void {
    const queryString = this.route.snapshot.queryParamMap.keys
      .map((key) => `${key}=${this.route.snapshot.queryParamMap.get(key)}`)
      .join('&');

    window.location.href = `http://localhost:${this.servePort}/${RouteSegment.LoginCallback}?${queryString}&redirect`;
  }

  private continue(): void {
    this.authService.setCredentials({ accessToken: this.sfAccessToken, userId: this.userId }, false);
    this.announcmentService
      .isUnderMaintenance$()
      .pipe(first())
      .subscribe((isUnderMaintenance) => {
        this.authService.setCredentials({ accessToken: this.sfAccessToken, userId: this.userId }, isUnderMaintenance);

        const redirectUrl = sessionStorage.getItem(redirectUrlSessionStorageKey);
        if (redirectUrl) {
          sessionStorage.removeItem(redirectUrlSessionStorageKey);
          this.router.navigateByUrl(redirectUrl);
        } else {
          this.router.navigate([RouteSegment.Root]);
        }
      });
  }

  public onIconLibraryClick(): void {
    const url = this.router.serializeUrl(this.router.createUrlTree([`${RouteSegment.IconLibrary}`]));

    window.open(url, '_blank');
  }

  public base64ToHex(str: string): any {
    const raw = atob(str);
    let result = '';
    for (let i = 0; i < raw.length; i++) {
      const hex = raw.codePointAt(i).toString(16);
      result += hex.length === 2 ? hex : `0${hex}`;
    }
    return result.toUpperCase();
  }

  public decrypt_core_AES_CBC(key: string, iv: string, ciphertext: string): any {
    const token = CryptoJS.enc.Hex.parse(key);
    const message = CryptoJS.AES.decrypt(
      {
        ciphertext,
      },
      token,
      {
        iv,
      }
    );
    return CryptoJS.enc.Utf8.stringify(message);
  }

  public asciiToHex(str: string): any {
    const arr1 = [];
    for (let n = 0, l = str.length; n < l; n++) {
      const hex = Number(str.codePointAt(n)).toString(16);
      arr1.push(hex);
    }
    return arr1.join('');
  }
}
