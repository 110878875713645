import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgSwitch, NgSwitchCase, AsyncPipe, DecimalPipe, DatePipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { RouteSegment } from '../../../../enums/route-segment.enum';
import { ShipmentStatus } from '../../../../enums/shipment-status.enum';
import { TaskVM } from '../../types/task.vm';
import { CostPipe } from '../../../../pipes/cost.pipe';
import { WeightUnitPipe } from '../../../../pipes/weight-unit.pipe';
import { TrackerComponent } from '../../../tracker/tracker.component';
import { ShipmentStateIndicatorCirclesComponent } from '../../../shipment-state-indicator-circles/shipment-state-indicator-circles.component';

@Component({
  selector: 'app-shipment-summary',
  templateUrl: './shipment-summary.component.html',
  styleUrls: ['./shipment-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLink,
    NgSwitch,
    NgSwitchCase,
    ShipmentStateIndicatorCirclesComponent,
    TrackerComponent,
    SvgIconComponent,
    AsyncPipe,
    DecimalPipe,
    DatePipe,
    WeightUnitPipe,
    CostPipe,
    TranslateModule,
  ],
})
export class ShipmentSummaryComponent {
  @Input() public shipmentOrder: TaskVM['shipmentOrder'];

  public readonly ShipmentStatus = ShipmentStatus;

  public get shipmentLink(): string[] {
    return [RouteSegment.Root, RouteSegment.ShipmentsList, this.shipmentOrder.id];
  }
}
