import { Injectable } from '@angular/core';
import { LengthUnit } from '@global/enums/length-unit.enum';
import { ServiceType } from '@global/enums/service-type.enum';
import { WeightUnit } from '@global/enums/weight-unit.enum';
import { calculateDaysFromNow } from '@global/helpers/calculate-days-from-now.helper';
import { LoadingIndicatorService } from '@global/modules/loading-indicator/services/loading-indicator.service';
import { AuthService } from '@global/services/auth.service';
import { Store } from '@ngrx/store';
import { QuoteDataService } from '@tecex-api/data';
import get from 'lodash/get';
import { combineLatest, Observable } from 'rxjs';
import { finalize, map, mapTo, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as fromNewQuote from '../../reducers/index';
import { NewQuoteFinalCostsPageVM } from './new-quote-final-costs-page.vm';

@Injectable()
export class NewQuoteFinalCostsPageService {
  constructor(
    private readonly store$: Store<fromNewQuote.AppState>,
    private readonly authService: AuthService,
    private readonly quoteDataService: QuoteDataService,
    private readonly loadingIndicatorService: LoadingIndicatorService
  ) {}

  public getVM$(): Observable<NewQuoteFinalCostsPageVM> {
    return combineLatest([
      this.store$.select(fromNewQuote.selectNewQuoteLineItemsState),
      this.store$.select(fromNewQuote.selectNewQuoteBasicsState),
      this.store$.select(fromNewQuote.selectNewQuoteFinalCostsState),
      this.authService.getUser$(),
    ]).pipe(
      map(([{ lineItems }, { quoteReference, shipmentOrderIds, values }, { finalCosts, state, freight }, user]) => ({
        quoteReference,
        state,
        hasLineItems: lineItems.length > 0,
        showCostRanges:
          lineItems.length === 0 && values.data.serviceType !== ServiceType.EOR && finalCosts.data?.costs.estimatedTaxDutyCost !== 0,
        hasPackages: values.data?.packages?.length !== 0,
        chargeableWeight: values.data?.estimatedWeight || 0,
        weightUnit: values.data?.estimatedWeightUnit || WeightUnit.Kg,
        lengthUnit: values.data?.lengthUnit || LengthUnit.Cm,
        isLoading: finalCosts.isLoading,
        costs: finalCosts.data?.costs,
        timeline: finalCosts.data?.timeline,
        tecExShippingServiceFeeEnabled: finalCosts.data?.tecExShippingServiceFeeEnabled,
        liabilityCoverFeeEnabled: finalCosts.data?.liabilityCoverFeeEnabled,
        shippingNotes: finalCosts.data?.shippingNotes,
        serviceType: finalCosts.data?.serviceType,
        shipmentValue: finalCosts.data?.shipmentValue,
        etaDisclaimer: finalCosts.data?.etaDisclaimer,
        freight,
        reasonForProForma: finalCosts.data?.reasonForProForma,
        expirationDays: calculateDaysFromNow(new Date(finalCosts.data?.expiryDate)),
        user,
        invoicingTerm: finalCosts.data?.invoicingTerm,
        invoiceTiming: finalCosts.data?.invoiceTiming,
        shipmentOrderIds,
        nlProduct: finalCosts.data?.nlProduct,
        liabilityAgreement: finalCosts.data?.liabilityAgreement,
        liabilityPossible: finalCosts.data?.liabilityPossible,
        liabilityArrangement: finalCosts.data?.liabilityArrangement,
        to: finalCosts.data?.to,
        accountName: finalCosts.data?.accountName,
        freightInsurance: finalCosts.data?.freightInsurance,
        shippingComplexity: finalCosts.data?.shippingComplexity,
        eorGoodsRestricted: finalCosts.data?.eorGoodsRestricted,
      }))
    );
  }

  public sendFinalCostsEmail$(): Observable<void> {
    return this.authService.getUser$().pipe(
      tap(() => this.loadingIndicatorService.open()),
      withLatestFrom(this.store$.select(fromNewQuote.selectNewQuoteBasicsState)),
      switchMap(([user, basics]) =>
        this.quoteDataService
          .sendFinalQuoteEmail({
            Accesstoken: user.accessToken,
            ID: get(basics.shipmentOrderIds, [0]),
            Email_Estimate_to: user.email,
            Shipment_Value_USD: basics.values.data.shipmentValue,
            request_EmailEstimate: true,
          })
          .pipe(
            // eslint-disable-next-line unicorn/no-useless-undefined
            mapTo(undefined),
            finalize(() => this.loadingIndicatorService.dispose())
          )
      )
    );
  }
}
