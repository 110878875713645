export enum MessagesRoles {
  ISM = 'Inside Sales Manager',
  OS = 'Onboarding Specialist',
  SM = 'Shipping Manager',
  ICE = 'ICE',
  LICE = 'Lead ICE',
  FC = 'Financial Controller',
  AM = 'Account Manager',
  TICETL = 'TecEx ICE Team Leader',
  SCC = 'Shipping & Compliance Coordinator',
  SCL = 'Shipping & Compliance Lead',
}
