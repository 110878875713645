<img class="bg-pattern" src="assets/images/background.svg" alt="background pattern" />

<div class="header">
  <div class="triangle"></div>
  <div class="cross"></div>
  <div class="logo-container d-flex"><svg-icon key="logo"></svg-icon></div>
</div>
<div class="card-container d-flex flex-column">
  <h5 class="color-blue text-center mx-1">{{ 'NO_MOBILE.ONLY_FOR_DESKTOP' | translate }}</h5>
  <div class="color-text-secondary font-weight-light mt-4 description">{{ 'NO_MOBILE.DOWNLOAD_OUR_APP' | translate }}</div>
  <div class="mt-4">
    <div class="option d-flex align-items-center">
      <svg-icon key="task2" class="icon color-primary mr-3"></svg-icon>
      <span class="font-weight-light">{{ 'ONBOARDING.UNLIMITED_QUOTES_MONTHLY' | translate }}</span>
    </div>
    <div class="option d-flex align-items-center">
      <svg-icon key="chat" class="icon color-primary mr-3"></svg-icon>
      <span class="font-weight-light">{{ 'ONBOARDING.COMMUNICATE_WITH_SERVICE_EXPERTS' | translate }}</span>
    </div>
    <div class="option d-flex align-items-center">
      <svg-icon key="plane-take-off" class="icon color-primary mr-3"></svg-icon>
      <span class="font-weight-light">{{ 'ONBOARDING.TRACK_SHIPMENTS' | translate }}</span>
    </div>
  </div>
  <div class="actions d-flex flex-row mt-2 align-items-center justify-content-center justify-content-sm-start">
    <div>
      <!-- TODO add real apple store url -->
      <a class="apple-store" href="https://apps.apple.com/us/app/google/id284815942?mt=8"></a>
    </div>
    <div class="ml-sm-3">
      <!-- TODO add real google play url -->
      <a href="http://play.google.com/store/?pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
        <img
          class="google-play"
          [alt]="'ONBOARDING.GET_IT_ON_GOOGLE_PLAY' | translate"
          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
        />
      </a>
    </div>
  </div>
</div>
