<div class="app-bar-container">
  <div class="container-fluid">
    <div class="app-bar row align-items-center">
      <div class="col-auto">
        <button mat-icon-button type="button" (click)="onOpenSideNavClick()">
          <svg-icon class="icon" key="menu"></svg-icon>
        </button>
      </div>
      <div class="col text-center">
        <a class="logo" [routerLink]="logo.link">
          <img [src]="logo.logo" [alt]="logo.alt" height="32" />
        </a>
      </div>
      <div class="col-auto">
        <a *ngIf="showNewQuoteButton && !thirdPartyFlag" mat-icon-button class="new-quote-icon-button" [routerLink]="newQuoteLink">
          <svg-icon key="add" class="icon"></svg-icon>
        </a>
      </div>
    </div>
  </div>
</div>

<div class="app-bar-spacer"></div>
