<div class="address-card mb-3" [class.selected]="isSelected">
  <div class="inner-box">
    <div class="d-flex">
      <ng-container *ngIf="isSelectable">
        <mat-checkbox *ngIf="isMultipleChoiceEnabled; else radioButton" [checked]="isSelected" (change)="onCheckboxChange()"></mat-checkbox>
        <ng-template #radioButton>
          <mat-radio-button [checked]="isSelected" (change)="onRadioChange()"></mat-radio-button>
        </ng-template>
      </ng-container>
      <div class="pl-2 pl-sm-3" [class.column-display]="layout === 'column'" [class.row-display]="layout === 'row'">
        <div class="p-1">
          <div class="text-reduced-bold title" [class.selected]="isSelected">{{ address.tag }}</div>
          <div class="text-reduced-regular addressed">{{ address.contactPerson }}</div>
        </div>
        <div class="text-micro-regular address-data p-1">
          <div>{{ address.streetAddress }}</div>
          <div>{{ address.city }}</div>
          <div>{{ address.state }}</div>
        </div>
        <div class="text-micro-regular address-data p-1">
          <div>{{ address.zip }}</div>
          <div>{{ address.country }}</div>
        </div>
        <div *ngIf="showPickupPreference" class="text-micro-regular color-text-secondary p-1">
          {{ address.pickupPreference | pickupPreferenceTranslationKey | translate }}
        </div>
      </div>
      <div class="icon-container">
        <svg-icon *ngIf="canEdit" key="pen" class="action-icon color-secondary" (click)="onEditClick()"></svg-icon>
        <svg-icon *ngIf="canDelete" key="close" class="action-icon color-error" (click)="onDeleteClick()"></svg-icon>
      </div>
    </div>
    <div *ngIf="address.isDefault" class="default-address">{{ 'SELECT_ADDRESS.DEFAULT_ADDRESS' | translate }}</div>
  </div>
</div>
