import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { NavigationItem } from './navigation-item.interface';

@Component({
  selector: 'app-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLinkActive, RouterLink, SvgIconComponent, TranslateModule],
})
export class NavigationItemComponent {
  @Input() public menuItem: NavigationItem;
}
