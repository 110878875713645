import { QuoteState } from '@global/enums/quote-list/quote-state.enum';
import { filterQuoteList } from '@modules/quote-list/helpers/filter-quote-list.helper';
import { LinkedQuoteVM } from '@modules/quote-list/interfaces/linked-quote.vm';
import { RolloutQuoteVM } from '@modules/quote-list/interfaces/rollout-quote.vm';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { QuoteType } from '@shared/enums/quote-type.enum';
import * as fromCountryValidation from '@shared/modules/country-validation/reducers';
import { State } from 'app/reducers';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import uniqBy from 'lodash/uniqBy';
import { handleQuoteSorting } from '../helpers/quote-list-sorting.helper';
import { QuoteDetailsQuote } from '../interfaces/quote.interface';
import * as fromQuoteDetails from './quote-details.reducer';
import * as fromQuoteList from './quote-list.reducer';

export const quoteListFeatureKey = 'quoteList';

export interface QuoteListState {
  [fromQuoteDetails.quoteDetailsKey]: fromQuoteDetails.State;
  [fromQuoteList.quoteListFeatureKey]: fromQuoteList.State;
}

export interface AppState extends State {
  [quoteListFeatureKey]: QuoteListState;
}

export interface QuoteListState {
  [fromQuoteList.quoteListFeatureKey]: fromQuoteList.State;
}

export const reducers: ActionReducerMap<QuoteListState> = {
  [fromQuoteDetails.quoteDetailsKey]: fromQuoteDetails.reducer,
  [fromQuoteList.quoteListFeatureKey]: fromQuoteList.reducer,
};

// QUOTE LIST SELECTORS
export const selectQuoteListFeatureState = createFeatureSelector<QuoteListState>(quoteListFeatureKey);

export const selectQuoteListState = createSelector(selectQuoteListFeatureState, (state) => state[fromQuoteList.quoteListFeatureKey]);

export const selectSortingPreferences = createSelector(selectQuoteListState, (state) => state.sortingPreferences);

export const selectQuoteList = createSelector(selectQuoteListState, (state) => state.quotes);
export const selectQuoteListData = createSelector(selectQuoteList, (quotes) => quotes.data);

export const selectOnlyOwnQuotes = createSelector(selectQuoteListState, (state) => state.onlyOwnQuotes);
export const selectKeyword = createSelector(selectQuoteListState, (state) => state.keyword);

// TODO: keyword filtering have been disabled in favour of BE filtering. (TUB-447) This can be removed in a follow-up ticket.
// export const selectFilteredQuoteItems = createSelector(selectQuoteListData, selectKeyword, (quotes, keyword) =>
//   isEmpty(keyword) ? quotes : searchInQuoteList(quotes, keyword)
// );
export const selectFilteredQuoteItems = selectQuoteListData;

export const selectCompleteQuotes = createSelector(selectFilteredQuoteItems, selectSortingPreferences, (items, sortingPreferences) => {
  const completeQuotes = items.filter((quote) => {
    const isGroup = quote.type === QuoteType.Rollout || quote.type === QuoteType.LinkedGroup;
    return isGroup
      ? (quote as RolloutQuoteVM | LinkedQuoteVM).quotes.some((subQuote) => subQuote.state === QuoteState.Complete)
      : quote.state === QuoteState.Complete;
  });
  return handleQuoteSorting([...completeQuotes], sortingPreferences.complete);
});

export const selectIncompleteQuotes = createSelector(selectFilteredQuoteItems, selectSortingPreferences, (items, sortingPreferences) => {
  const incompleteQuotes = items.filter((quote) => {
    const isRollout = quote.type === QuoteType.Rollout;
    return isRollout
      ? (quote as RolloutQuoteVM).quotes.some((subQuote) => subQuote.state === QuoteState.Incomplete)
      : quote.state === QuoteState.Incomplete;
  });
  return handleQuoteSorting([...incompleteQuotes], sortingPreferences.incomplete);
});

export const selectExpiredQuotes = createSelector(selectFilteredQuoteItems, selectSortingPreferences, (items, sortingPreferences) => {
  const expiredQuotes = items.filter((quote) => {
    const isRollout = quote.type === QuoteType.Rollout;
    return isRollout
      ? (quote as RolloutQuoteVM).quotes.some((subQuote) => subQuote.state === QuoteState.Expired)
      : quote.state === QuoteState.Expired;
  });
  return handleQuoteSorting([...expiredQuotes], sortingPreferences.expired);
});

export const selectFilteredQuotes = createSelector(selectQuoteListState, (state) => {
  if (!state.filters) {
    return state.quotes.data;
  }
  const filteredQuotes = filterQuoteList(state.quotes.data, state.filters);

  return handleQuoteSorting([...filteredQuotes], state.sortingPreferences.mixed);
});

export const selectAppliedFilters = createSelector(selectQuoteListState, (state) => state.filters);
export const selectNumberOfAppliedFilters = createSelector(selectQuoteListState, (state) => {
  if (!state.filters) {
    return 0;
  }
  return Object.values(state.filters).filter((filter) => !isEmpty(filter)).length;
});

export const selectQuoteListAssignees = createSelector(selectQuoteListData, (quoteList) =>
  uniqBy(
    quoteList
      .reduce((assignees, item) => {
        const newAssignees = item.type === QuoteType.Rollout ? item.quotes.map((subQuote) => subQuote.owner) : item.owner;
        return isNil(newAssignees) ? assignees : assignees.concat(newAssignees);
      }, [])
      .filter((assignee) => !isNil(assignee)),
    'id'
  )
);

export const selectQuoteListLoading = createSelector(selectQuoteListState, (state) => state.quotes.isLoading);

export const selectIsFirstLoad = createSelector(selectQuoteListState, (state) => state.isFirstLoad);

export const selectLoadingFilteredResults = createSelector(selectQuoteListState, (state) => state.loadingFilteredResults);

export const selectLastActivatedTab = createSelector(selectQuoteListState, (state) => state.lastActivatedTab);

// QUOTE DETAILS SELECTORS

export const selectQuoteDetailsFeatureState = createFeatureSelector<QuoteListState>(quoteListFeatureKey);

export const selectQuoteDetails = createSelector(selectQuoteDetailsFeatureState, (state) => state[fromQuoteDetails.quoteDetailsKey]);
export const selectQuoteDetailsLoading = createSelector<
  AppState & fromCountryValidation.AppState,
  [fromQuoteDetails.State, boolean],
  boolean
>(
  selectQuoteDetails,
  fromCountryValidation.selectCountryValidationRulesLoading,
  (state, countryValidationsLoading) => state.quote.isLoading || state.contacts.isLoading || countryValidationsLoading
);
export const selectQuoteDetailsContacts = createSelector(selectQuoteDetails, (state) => state.contacts.data);
export const selectQuoteDetailsParticipants = createSelector(selectQuoteDetails, (state) => state.participants.data);
export const selectQuoteDetailsQuote = createSelector(selectQuoteDetails, (state) => state.quote.data);
export const selectQuoteDetailsIsRollOut = createSelector(selectQuoteDetails, (state) => state.isRollOut);
export const selectQuoteOwner = createSelector(selectQuoteDetails, (state) =>
  state.isRollOut ? undefined : (state.quote.data as QuoteDetailsQuote)?.owner
);
export const selectQuoteCriticalError = createSelector(selectQuoteDetails, (state) => state.quote?.data?.reCriticalError);

export const selectRollOutDetailFromQuoteDetail = createSelector(selectQuoteDetails, (state) => state.rollOutDetail);
