import {
  ActiveInactive,
  OrderType,
  ProfileAddress,
  RelatedDefaultsResponse,
  ShortDimension,
  UserDefaultsResponse,
  WeightUnit as WeigthUnitTecex,
} from '@tecex-api/data';
import isNil from 'lodash/isNil';
import { CurrencyCode } from '../../../enums/currency-code.enum';
import { mapLengthUnit } from '../../../helpers/map-length-unit.helper';
import { mapServiceType } from '../../../helpers/map-service-type.helper';
import { mapWeightUnitWithNull } from '../../../helpers/map-weight-unit.helper';
import { AddressCardAddressVM } from '../../../interfaces/address/address.vm';
import { QuoteDefaultValues } from '../../../interfaces/quote-default-values.interface';
import { WeightUnit } from '../../../enums/weight-unit.enum';
import { LengthUnit } from '../../../enums/length-unit.enum';
import { ChargeableWeightDialogPackageVM } from '../../../interfaces/package.vm';

export const mapQuoteDefaultsResponse = (userDefaults: UserDefaultsResponse): Partial<QuoteDefaultValues> => {
  const defaults = userDefaults.ClientDefaultvalues?.find((defaultValue) => defaultValue.OrderType === OrderType.FINAL_QUOTE);
  if (!defaults) {
    return {};
  }

  const defaultPickUpAddresses = mapDefaultPickUpAddresses(userDefaults.PickupAddress);

  return {
    id: defaults.Id,
    serviceType: mapServiceType(defaults.ServiceType),
    shipFromCountry: defaults.ShipFromCountry,
    weightUnit: mapWeightUnitWithNull(defaults.PackageWeightUnits),
    dimensionUnit: mapLengthUnit(defaults.PackageDimensions),
    currency: defaults.Currency as CurrencyCode,
    liionBatteries: defaults.LiionBatteries,
    secondhandparts: defaults.Secondhandparts,
    tecExToHandleFreight: defaults.TecExtohandlefreight,
    defaultPickUpAddresses,
  };
};

const mapDefaultPickUpAddresses = (addresses?: ProfileAddress[]) => {
  if (isNil(addresses)) {
    return {};
  }

  // eslint-disable-next-line unicorn/prefer-object-from-entries
  return addresses.reduce<Record<string, AddressCardAddressVM>>((accumulator, address) => {
    if (address.AddressStatus !== ActiveInactive.ACTIVE || !address.Default) {
      return accumulator;
    }

    return {
      ...accumulator,
      [address.Country]: {
        isActive: true,
        id: address.Id,
        tag: address.PickupaddressName,
        contactPerson: address.ContactFullName,
        email: address.ContactEmail,
        phone: address.ContactPhoneNumber,
        additionalPhone: address.AdditionalContactNumber,
        companyName: address.CompanyName,
        streetAddress: address.Address,
        streetAddressTwo: address.Address2,
        streetAddressThree: address.Address3,
        city: address.City,
        state: address.Province,
        country: address.Country,
        zip: address.PostalCode,
        comment: address.Comments,
        isDefault: true,
        pickupPreference: address.PickupPreference,
      },
    };
  }, {});
};

export const mapRelatedDefaultsResponse = (userDefaults: RelatedDefaultsResponse): any => {
  const defaults = userDefaults.ClientDefaultvalues?.find((defaultValue) => defaultValue.OrderType === OrderType.FINAL_QUOTE);
  if (!defaults) {
    return {};
  }

  const defaultPickUpAddresses = userDefaults.PickupAddress ? mapDefaultPickUpAddresses(userDefaults.PickupAddress) : [];

  return {
    id: defaults.Id,
    serviceType: mapServiceType(defaults.ServiceType, defaults.EOR_IOR),
    shipFromCountry: defaults.ShipFromCountry,
    weightUnit: mapWeightUnitWithNull(defaults.PackageWeightUnits),
    dimensionUnit: mapLengthUnit(defaults.PackageDimensions),
    currency: defaults.Currency as CurrencyCode,
    liionBatteries: defaults.LiionBatteries,
    secondhandparts: defaults.Secondhandparts,
    tecExToHandleFreight: defaults.TecExtohandlefreight,
    defaultPickUpAddresses,
    packages: defaults.ShipmentOrderPackages ? mapRelatedDefaultsPackages(defaults.ShipmentOrderPackages) : [],
  };
};

export const mapRelatedDefaultsPackages = (packages): ChargeableWeightDialogPackageVM[] => {
  return packages.map((defaultPackage) => ({
    id: defaultPackage.Id,
    totalWeight: defaultPackage['Actual Weight'],
    breadth: defaultPackage.Breadth,
    height: defaultPackage.Height,
    length: defaultPackage.Length,
    packageCount: defaultPackage['Packages of Same Weight/Dimensions'],
    weight: defaultPackage['Actual Weight'],
    hasBatteries: defaultPackage['Lithium Batteries'],
    hasDangerousGoods: defaultPackage['Dangerous Goods'],
    weightUnit: defaultPackage['Weight Unit'] === WeigthUnitTecex.KGS ? WeightUnit.Kg : WeightUnit.Lbs,
    lengthUnit: defaultPackage['Dimension Unit'] === ShortDimension.CMS ? LengthUnit.Cm : LengthUnit.Inch,
  }));
};
