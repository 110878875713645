import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Userpilot } from 'userpilot';
import { combineLatest } from 'rxjs';
import { AuthService } from './auth.service';
import { TokenConfigService } from './token-config.service';

@Injectable({
  providedIn: 'root',
})
export class UserpilotService {
  private initialized = false;
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly tokenConfigService: TokenConfigService
  ) {
    const tokenUser$ = combineLatest([this.tokenConfigService.getTokens$(), this.authService.getUser$()]);

    tokenUser$.subscribe((response) => {
      if (!this.initialized) {
        Userpilot.initialize(response[0].userpilotToken);
      }
      this.initialized = true;
      Userpilot.identify(response[1].id, {
        name: response[1].name,
        email: response[1].email,
        company: {
          id: response[1].accountName,
          name: response[1].accountName,
        },
      });
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => (Userpilot as any).reload());
  }
}
