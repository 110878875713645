import { Inject, Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CasesDataService, CaseSource, ProfileDataService, SendMessageDataService } from '@tecex-api/data';
import { forkJoin, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';
import { CONFIG_TOKEN } from '../../../config/config.token';
import { GlobalConfig } from '../../../config/global-config.interface';
import { mapCaseDetails } from '../../../helpers/messages/map-case-details.helper';
import { MessageThreadDetails } from '../../../interfaces/messages/message-thread-details.interface';
import { Participant } from '../../../interfaces/participant.interface';
import { AuthService } from '../../../services/auth.service';
import { TeamMemberService } from '../../../services/team-member.service';
import { PROFILE_PICTURE_FALLBACK } from '../../../tokens/profile-picture-fallback.token';
import { ToastMessageService } from '../../toast-message/toast-message.service';
import { TokenConfigService } from '../../../services/token-config.service';
import { MessageService } from './message.service';

@Injectable()
export class CaseMessageService extends MessageService {
  constructor(
    ngZone: NgZone,
    authService: AuthService,
    profileDataService: ProfileDataService,
    casesDataService: CasesDataService,
    sendMessageDataService: SendMessageDataService,
    teamMemberService: TeamMemberService,
    toastMessageService: ToastMessageService,
    translateService: TranslateService,
    tokenConfigService: TokenConfigService,
    @Inject(CONFIG_TOKEN) config: GlobalConfig,
    @Inject(PROFILE_PICTURE_FALLBACK) private readonly profilePictureFallback: string
  ) {
    super(
      ngZone,
      authService,
      profileDataService,
      casesDataService,
      sendMessageDataService,
      teamMemberService,
      toastMessageService,
      translateService,
      tokenConfigService,
      config
    );
  }

  public createThread$(caseDetailsPayload: MessageThreadDetails): Observable<MessageThreadDetails> {
    return this.authService.getUser$().pipe(
      switchMap((user) =>
        this.casesDataService
          .createCase({
            Accesstoken: user.accessToken,
            AccountID: user.accountId,
            ContactID: user.contactId,
            ClientuserID: user.id,
            Subject: caseDetailsPayload.subject,
            SOID: caseDetailsPayload.shipment?.id,
            Attachment: [],
            RecordId: caseDetailsPayload.source?.recordId,
            PageOrigin: caseDetailsPayload.origin,
            Source: caseDetailsPayload.source?.type || CaseSource.NCP_GENERAL,
            Description: caseDetailsPayload.description,
          })
          .pipe(map((response) => ({ ...caseDetailsPayload, id: response.Success?.CaseID })))
      )
    );
  }

  public caseAccount$(caseId: string): Observable<any> {
    return this.casesDataService.getCaseAccount(caseId).pipe(map((response) => response));
  }

  public getThread$(id: string, initial = false): Observable<MessageThreadDetails> {
    if (initial) {
      // This one has a really bad effect on the load time,
      // so we decided to let it happen in the background.
      this.updateClientViewedTime$(id).subscribe();
    }

    return this.authService.getUser$().pipe(
      first(),
      switchMap((user) =>
        forkJoin([
          this.casesDataService.getCase({
            Accesstoken: user.accessToken,
            CaseID: id,
          }),
        ]).pipe(
          switchMap(([caseDetails]) =>
            this.getReplies$(caseDetails.Feedsoncase).pipe(
              map((feedItems) => ({
                ...caseDetails,
                Feedsoncase: feedItems,
              }))
            )
          ),
          switchMap((caseDetails) =>
            this.getUsersFromMessages$(caseDetails.Feedsoncase).pipe(
              map((users) => mapCaseDetails({ caseDetails, users }, user.id, this.profilePictureFallback))
            )
          )
        )
      )
    );
  }

  public addParticipants$(caseId: string, participants: Participant[]): Observable<void> {
    return this.authService.getUser$().pipe(
      switchMap((user) =>
        this.casesDataService
          .updateCase({
            Accesstoken: user.accessToken,
            AccountID: user.accountId,
            ContactID: user.contactId,
            CaseID: caseId,
            NotifyContacts: participants.map((participant) => ({
              NotifyContactID: participant.id,
            })),
          })
          .pipe(mapTo(undefined))
      )
    );
  }
}
