import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector, TemplateRef } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, EMPTY, fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { DialogAppNavDrawerContainerComponent } from './components/dialog-app-nav-drawer-container/dialog-app-nav-drawer-container.component';
import { DialogDefaultContainerComponent } from './components/dialog-default-container/dialog-default-container.component';
import { DialogOverlayComponent } from './components/dialog-overlay/dialog-overlay.component';
import { DialogSideContainerComponent } from './components/dialog-side-container/dialog-side-container.component';
import { DialogRef } from './dialog-ref';
import { DIALOG_DATA } from './dialog.tokens';
import { DialogShowMode } from './enums/dialog-show-mode.enum';
import { DialogConfig } from './interfaces/dialog-config.interface';
import { DialogData } from './interfaces/dialog-data.interface';

@Injectable()
export class DialogService {
  private backdropOverlayRef?: OverlayRef;
  private readonly openDialogRefs$ = new BehaviorSubject<DialogRef<any>[]>([]);
  private readonly DEFAULT_CONFIG: DialogConfig = {
    closeButton: true,
    closeOnBackdropClick: true,
    closeOnEscape: true,
    showMode: DialogShowMode.Default,
    closeOnNavigation: false,
    closeOnBrowserBackForward: true,
  };

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly overlay: Overlay,
    private readonly injector: Injector,
    private readonly router: Router
  ) {
    this.handleKeyboardEvents();
    if (this.DEFAULT_CONFIG.closeOnBrowserBackForward) {
      router.events.forEach((event) => {
        if (event instanceof NavigationStart && event.navigationTrigger === 'popstate') {
          this.closeAll();
        }
      });
    }
  }
  public open<Payload = undefined, Result = undefined>(
    templateOrComponentRef: TemplateRef<any> | ComponentType<any>,
    payload?: Payload,
    config?: DialogConfig
  ): DialogRef<Result> {
    if (!this.backdropOverlayRef) {
      this.backdropOverlayRef = this.createBackdropOverlayRef();
      this.backdropOverlayRef.attach(new ComponentPortal(DialogOverlayComponent));
    }

    if (config?.preventScrollOffsetSaving) {
      this.document.documentElement.classList.add('cdk-global-scrollblock-prevent-offset');
    }

    // Hide currently visible dialog if any.
    const topOpenDialogRef = this.openDialogRefs$.value.at(-1);
    if (topOpenDialogRef) {
      topOpenDialogRef.hide();
    }

    const dialogRef = new DialogRef<Result>({ ...this.DEFAULT_CONFIG, ...config });
    const configWithDefaults: DialogConfig = { ...this.DEFAULT_CONFIG, injector: this.injector, ...config };
    const injector = this.createInjector(dialogRef, payload, configWithDefaults);

    const dialogOverlayRef = this.createDialogOverlayRef(config);
    const dialogContainer = dialogOverlayRef.attach(
      new ComponentPortal(this.getContainerComponent(configWithDefaults.showMode), undefined, injector)
    );

    if (templateOrComponentRef instanceof TemplateRef) {
      dialogContainer.instance.attachTemplatePortal(new TemplatePortal(templateOrComponentRef, undefined, injector));
    } else {
      dialogContainer.instance.attachComponentPortal(new ComponentPortal(templateOrComponentRef, undefined, injector));
    }

    this.openDialogRefs$.next(this.openDialogRefs$.getValue().concat(dialogRef));

    if (configWithDefaults.closeOnNavigation) {
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          filter(() => this.router.getCurrentNavigation().extras?.state?.closeDialogs ?? true),
          take(1),
          takeUntil(dialogRef.afterClosed$())
        )
        .subscribe(() => dialogRef.close());
    }

    dialogRef.afterClosed$().subscribe(() => {
      dialogOverlayRef.detach();
      this.openDialogRefs$.next(this.openDialogRefs$.getValue().filter((openDialogRef) => openDialogRef !== dialogRef));

      // tslint:disable-next-line: early-exit
      if (this.openDialogRefs$.value.length === 0) {
        this.backdropOverlayRef.detach();
        this.backdropOverlayRef = undefined;
        this.document.documentElement.classList.remove('cdk-global-scrollblock-prevent-offset');
      } else if (topOpenDialogRef) {
        topOpenDialogRef.show();
      }
    });

    if (configWithDefaults.closeOnBackdropClick) {
      dialogOverlayRef
        .backdropClick()
        .pipe(takeUntil(dialogRef.afterClosed$()))
        .subscribe(() => dialogRef.close());
    }

    return dialogRef;
  }

  public hasOpenDialog$(): Observable<boolean> {
    return this.openDialogRefs$.pipe(
      map((openDialogRefs) => openDialogRefs.length > 0),
      distinctUntilChanged()
    );
  }

  private getContainerComponent(showMode?: DialogShowMode): ComponentType<any> {
    switch (showMode) {
      case DialogShowMode.Side: {
        return DialogSideContainerComponent;
      }
      case DialogShowMode.AppNavDrawer: {
        return DialogAppNavDrawerContainerComponent;
      }
      case DialogShowMode.Default:
      default: {
        return DialogDefaultContainerComponent;
      }
    }
  }

  private handleKeyboardEvents(): void {
    this.openDialogRefs$
      .pipe(
        switchMap((openDialogRefs) => {
          if (openDialogRefs.length === 0) {
            return EMPTY;
          }

          const topDialogConfig = openDialogRefs.at(-1).config;

          if (!topDialogConfig.closeButton || !topDialogConfig.closeOnEscape) {
            return EMPTY;
          }

          return fromEvent<KeyboardEvent>(document, 'keyup').pipe(filter((event) => event.key === 'Escape'));
        })
      )
      .subscribe(() => {
        const topDialogRef = this.openDialogRefs$.getValue()[this.openDialogRefs$.getValue().length - 1];
        topDialogRef.close();
      });
  }

  private createBackdropOverlayRef(): OverlayRef {
    return this.overlay.create({
      // It's handled by BlockScrollService.
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop',
    });
  }

  private createDialogOverlayRef(config?: DialogConfig): OverlayRef {
    const baseOverlayConfig = {
      // It's handled by BlockScrollService.
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      hasBackdrop: true,
      backdropClass: '',
    };

    const showMode = config?.showMode || DialogShowMode.Default;
    switch (showMode) {
      case DialogShowMode.Default: {
        return this.overlay.create({
          ...baseOverlayConfig,
          positionStrategy: this.overlay.position().global().centerVertically().centerHorizontally(),
        });
      }
      case DialogShowMode.Side: {
        return this.overlay.create({
          ...baseOverlayConfig,
          maxHeight: '100vh',
          positionStrategy: this.overlay.position().global().right(),
        });
      }
      case DialogShowMode.AppNavDrawer: {
        return this.overlay.create({
          ...baseOverlayConfig,
          maxHeight: '100vh',
          maxWidth: 'calc(100vw - 80px)',
          positionStrategy: this.overlay.position().global().left(),
        });
      }
    }
  }

  private createInjector<Payload, Result>(dialogRef: DialogRef<Result>, payload: Payload, config: DialogConfig): Injector {
    const data: DialogData<Payload, Result> = { dialogRef, config, payload };
    return Injector.create({ providers: [{ provide: DIALOG_DATA, useValue: data }], parent: config.injector });
  }

  public closeAll() {
    this.openDialogRefs$.value.forEach((dialog) => {
      dialog.close();
    });
  }
}
