// @ts-nocheck
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgIf, AsyncPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { DatepickerBlockPayload } from '../../types/datepicker-block-payload.interface';
import { DatepickerBlockResult } from '../../types/datepicker-block-result.interface';
import { BlockComponent } from '../block/block.component';
import { FormControlHintDirective } from '../../../form-control/directives/form-control-hint.directive';
import { FormControlInputSuffixDirective } from '../../../form-control/directives/form-control-input-suffix.directive';
import { FormControlInputDirective } from '../../../form-control/directives/form-control-input.directive';
import { FormControlLabelDirective } from '../../../form-control/directives/form-control-label.directive';
import { FormControlComponent } from '../../../form-control/components/form-control/form-control.component';
import { ValidatorHelperService } from '../../../../services/validator-helper.service';
import { validateWeekendDate } from '../../../../validators/weekend.validator';
import { validateWorkHours } from '../../../../validators/work-hours.validator';

@Component({
  selector: 'app-datepicker-block',
  templateUrl: './datepicker-block.component.html',
  styleUrls: ['./datepicker-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormControlComponent,
    NgIf,
    FormControlLabelDirective,
    MatDatepickerModule,
    FormsModule,
    FormControlInputDirective,
    ReactiveFormsModule,
    FormControlInputSuffixDirective,
    SvgIconComponent,
    FormControlHintDirective,
    NgxMaterialTimepickerModule,
    AsyncPipe,
  ],
})
export class DatepickerBlockComponent extends BlockComponent<DatepickerBlockPayload, DatepickerBlockResult> implements OnInit {
  constructor(
    private readonly validatorHelperService: ValidatorHelperService,
    private readonly formBuilder: UntypedFormBuilder
  ) {
    super();
  }

  public form = this.formBuilder.group({
    date: this.formBuilder.control(null, [Validators.required, validateWeekendDate()]),
    time: this.formBuilder.control(null, [Validators.required, validateWorkHours()]),
  });

  public get dateControl(): FormControl<Date> {
    return this.form.controls.date as UntypedFormControl;
  }

  public get timeControl(): FormControl<string> {
    return this.form.controls.time as UntypedFormControl;
  }

  public ngOnInit(): void {
    this.register.emit(this.form);
  }

  public readonly dateError$: Observable<string | null> = this.validatorHelperService.getError$(this.dateControl);
  public readonly timeError$: Observable<string | null> = this.validatorHelperService.getError$(this.timeControl);
}
