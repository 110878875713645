import { QuoteState } from '@global/enums/quote-list/quote-state.enum';
import { QuoteListSideFilterDialogVM } from '@modules/quote-list/components/quote-list-side-filter-dialog/quote-list-side-filter-dialog.vm';
import { LinkedQuoteVM } from '@modules/quote-list/interfaces/linked-quote.vm';
import { QuoteTableSorting } from '@modules/quote-list/interfaces/quote-table-sorting.interface';
import { RolloutQuoteVM } from '@modules/quote-list/interfaces/rollout-quote.vm';
import { TecexQuoteVM } from '@modules/quote-list/interfaces/tecex-quote.vm';
import { createAction, props } from '@ngrx/store';
import { QuoteListTabQuery } from '@shared/enums/quote-list-tab-query.enum';

export const enter = createAction('[Quote List] enter');

export const load = createAction('[Quote List] load');
export const loadSuccess = createAction('[Quote List] loadSuccess', props<{ data: (TecexQuoteVM | RolloutQuoteVM | LinkedQuoteVM)[] }>());
export const loadError = createAction('[Quote List] loadError', props<{ error: string }>());

export const toggleQuote = createAction('[Quote List] toggleQuote', props<{ ids: { parentId?: string; childrenIds: string[] } }>());
export const addLineItems = createAction('[Quote List] addLineItems', props<{ quote: TecexQuoteVM | RolloutQuoteVM }>());
export const reuseQuote = createAction('[Quote List] reuseQuote', props<{ id: string }>());
export const reuseRollout = createAction('[Quote List] reuseRollout', props<{ id: string }>());

export const accept = createAction('[Quote List] accept', props<{ ids: string[] }>());

export const updateFilters = createAction('[Quote List] updateFilters', props<{ filters: QuoteListSideFilterDialogVM }>());
export const clearAdvancedFilters = createAction('[Quote List] clearAdvancedFilters');

export const updateKeyword = createAction('[Quote List] updateKeyword', props<{ keyword: string }>());
export const updateOwnershipFilter = createAction('[Quote List] updateOwnershipFilter', props<{ onlyOwnQuotes: boolean }>());

export const setLastActivatedTab = createAction('[Quote List] setLastActivatedTab', props<{ tab: QuoteListTabQuery }>());

export const leave = createAction('[Quote List] leave');

export const changeSorting = createAction('[Quote List] changeSorting', props<{ [key in QuoteState]?: QuoteTableSorting }>());
export const clearSorting = createAction('[Quote List] clearSorting');
