import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { AsyncPipe, DatePipe, NgFor, NgIf } from '@angular/common';
import { FormBuilder } from '@ngneat/reactive-forms';
import { Observable } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { UpdateUserDetailsRequest } from '@tecex-api/data';
import moment from 'moment';
import { MessageCardComponent } from '../message-card/message-card.component';
import { MessageCardTitleDirective } from '../message-card/directives/message-card-title.directive';
import { MessageCardDescriptionDirective } from '../message-card/directives/message-card-description.directive';
import { ValidatorHelperService } from '../../services/validator-helper.service';
import { FormControlComponent } from '../form-control/components/form-control/form-control.component';
import { FormControlHintDirective } from '../form-control/directives/form-control-hint.directive';
import { FormControlIconDirective } from '../form-control/directives/form-control-icon.directive';
import { FormControlInputDirective } from '../form-control/directives/form-control-input.directive';
import { FormControlLabelDirective } from '../form-control/directives/form-control-label.directive';
import { FormControlInputSuffixDirective } from '../form-control/directives/form-control-input-suffix.directive';
import { SelectComponent } from '../select/select.component';
import { MyTeamPageService } from '../common-profile/services/my-team-page.service';
import { InputDataVM } from '../../interfaces/input-data.vm';
import { User } from '../../interfaces/user.interface';
import { AuthService } from '../../services/auth.service';
import { DialogData } from '../dialog/interfaces/dialog-data.interface';
import { DIALOG_DATA } from '../dialog/dialog.tokens';
import { TooltipDirective } from '../tooltip/tooltip.directive';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { FormControlTooltipDirective } from '../form-control/directives/form-control-tooltip.directive';
import { OutOfOfficeSettingsService } from './out-of-office-settings.service';
const MAX_LENGTH = 255;

@Component({
  selector: 'app-out-of-office-settings',
  templateUrl: './out-of-office-settings.component.html',
  styleUrls: ['./out-of-office-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatRadioModule,
    NgFor,
    NgIf,
    FormsModule,
    MatButtonModule,
    TranslateModule,
    MessageCardComponent,
    MessageCardTitleDirective,
    MessageCardDescriptionDirective,
    AsyncPipe,
    FormControlComponent,
    FormControlHintDirective,
    FormControlIconDirective,
    FormControlInputDirective,
    FormControlLabelDirective,
    MatCheckboxModule,
    SvgIconComponent,
    ReactiveFormsModule,
    FormControlInputSuffixDirective,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    SelectComponent,
    TooltipDirective,
    DropdownComponent,
    FormControlTooltipDirective,
  ],
  providers: [DatePipe],
})
export class OutOfOfficeSettingsComponent implements OnInit {
  public form = this.formBuilder.group({
    startDate: this.formBuilder.control(null, [Validators.required]),
    startTime: this.formBuilder.control('00:00 AM', [Validators.required]),
    endDate: this.formBuilder.control(null, [Validators.required]),
    endTime: this.formBuilder.control('00:00 AM', [Validators.required]),
    message: this.formBuilder.control('', [Validators.required, Validators.maxLength(MAX_LENGTH)]),
    standbyUser: this.formBuilder.control('', [Validators.required]),
  });

  public readonly startDateControl = this.form.controls.startDate as UntypedFormControl;
  public readonly startTimeControl = this.form.controls.startTime as UntypedFormControl;
  public readonly endDateControl = this.form.controls.endDate as UntypedFormControl;
  public readonly endTimeControl = this.form.controls.endTime as UntypedFormControl;
  public readonly messageControl = this.form.controls.message as UntypedFormControl;
  public readonly standbyUserControl = this.form.controls.standbyUser as UntypedFormControl;

  public readonly startDateError$: Observable<string | null> = this.validatorHelperService.getError$(this.startDateControl);
  public readonly startTimeError$: Observable<string | null> = this.validatorHelperService.getError$(this.startTimeControl);
  public readonly endDateError$: Observable<string | null> = this.validatorHelperService.getError$(this.endDateControl);
  public readonly endTimeError$: Observable<string | null> = this.validatorHelperService.getError$(this.endTimeControl);
  public readonly messageError$: Observable<string | null> = this.validatorHelperService.getError$(this.messageControl);
  public readonly standbyUserError$: Observable<string | null> = this.validatorHelperService.getError$(this.standbyUserControl);

  public tableData$: Observable<InputDataVM[]> = this.myTeamPageService.getStandbyUsers$();
  public user$: Observable<User> = this.authService.getUser$();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly validatorHelperService: ValidatorHelperService,
    private readonly myTeamPageService: MyTeamPageService,
    private readonly outOfOfficeSettingsService: OutOfOfficeSettingsService,
    private readonly authService: AuthService,
    @Inject(DIALOG_DATA)
    private readonly data: DialogData<any, any>
  ) {}

  ngOnInit() {
    this.user$.subscribe((user) => {
      if (user.outOfOffice) {
        this.form.setValue({
          startDate: moment(user.oooStartDate).local().format(),
          startTime: moment(user.oooStartDate).local().format('HH:mm'),
          endDate: moment(user.oooEndDate).local().format(),
          endTime: moment(user.oooEndDate).local().format('HH:mm'),
          message: user.oooMessage ? user.oooMessage : '',
          standbyUser: user.standByUser ? user.standByUser : '',
        });
      }
    });
  }

  public setOutOfOffice() {
    const payload: UpdateUserDetailsRequest = {
      OOOStartDate: moment(
        `${moment(this.startDateControl.value).format('YYYY-MM-DD')}' '${this.startTimeControl.value}`,
        'YYYY-MM-DD hh:mm a'
      )
        .utc()
        .format(),
      OOOEndDate: moment(`${moment(this.endDateControl.value).format('YYYY-MM-DD')}' '${this.endTimeControl.value}`, 'YYYY-MM-DD hh:mm a')
        .utc()
        .format(),
      OOOMessage: this.messageControl.value,
      StandbyUser: this.standbyUserControl.value,
    };
    this.data.dialogRef.close({ payload });
  }

  public resetOutOfOffice() {
    const payload: UpdateUserDetailsRequest = {
      OOOStartDate: '',
      OOOEndDate: '',
      OOOMessage: '',
      StandbyUser: '',
    };
    this.data.dialogRef.close({ payload });
  }
}
