import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from '@ngneat/svg-icon';

@Component({
  selector: 'app-no-mobile-view',
  templateUrl: './no-mobile-view.component.html',
  styleUrls: ['./no-mobile-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SvgIconComponent, TranslateModule],
})
export class NoMobileViewComponent {}
