import {
  ShipmentOrderRelations,
  ShipmentOrderRelationsShipmentOrderPackages,
  ShortDimension,
  WeightUnit as WeigthUnitTecex,
} from '@tecex-api/data';
import get from 'lodash/get';
import { LengthUnit } from '../enums/length-unit.enum';
import { WeightUnit } from '../enums/weight-unit.enum';
import { ChargeableWeightDialogPackageVM } from '../interfaces/package.vm';

export const mapShipmentOrderPackages = (shipmentOrderRelations: ShipmentOrderRelations): ChargeableWeightDialogPackageVM[] =>
  get<ShipmentOrderRelations, 'ShipmentOrderPackages', ShipmentOrderRelationsShipmentOrderPackages[]>(
    shipmentOrderRelations,
    'ShipmentOrderPackages',
    []
  ).map((shipmentPackage: ShipmentOrderRelationsShipmentOrderPackages) => ({
    id: shipmentPackage.Id,
    totalWeight: shipmentPackage.ChargeableWeightKGs,
    breadth: shipmentPackage.Breadth,
    height: shipmentPackage.Height,
    length: shipmentPackage.Length,
    packageCount: shipmentPackage.PackagesofSameWeightDimensions,
    weight: shipmentPackage.ActualWeight,
    hasBatteries: shipmentPackage.LithiumBatteries || shipmentPackage.Containsbatteries,
    hasDangerousGoods: shipmentPackage.DangerousGoods,
    weightUnit: shipmentPackage.WeightUnit === WeigthUnitTecex.KGS ? WeightUnit.Kg : WeightUnit.Lbs,
    lengthUnit: shipmentPackage.DimensionUnit === ShortDimension.CMS ? LengthUnit.Cm : LengthUnit.Inch,
  }));

export const mapShipmentOrderPackagesRO = (
  ShipmentOrderPackages: Array<ShipmentOrderRelationsShipmentOrderPackages>
): ChargeableWeightDialogPackageVM[] =>
  ShipmentOrderPackages.map((shipmentPackage: ShipmentOrderRelationsShipmentOrderPackages) => ({
    id: shipmentPackage.Id,
    totalWeight: shipmentPackage.ChargeableWeightKGs,
    breadth: shipmentPackage.Breadth,
    height: shipmentPackage.Height,
    length: shipmentPackage.Length,
    packageCount: shipmentPackage.PackagesofSameWeightDimensions,
    weight: shipmentPackage.ActualWeight,
    hasBatteries: shipmentPackage.LithiumBatteries || shipmentPackage.Containsbatteries,
    hasDangerousGoods: shipmentPackage.DangerousGoods,
    weightUnit: shipmentPackage.WeightUnit === WeigthUnitTecex.KGS ? WeightUnit.Kg : WeightUnit.Lbs,
    lengthUnit: shipmentPackage.DimensionUnit === ShortDimension.CMS ? LengthUnit.Cm : LengthUnit.Inch,
  }));
