<div class="dialog-container">
  <div class="dialog-content">
    <div class="dialog-title">{{ 'PROFILE.OUT_OF_OFFICE.TITLE' | translate }}</div>
    <div class="pl-1 mb-4">
      <app-message-card type="info">
        <div *appMessageCardTitle>{{ 'PROFILE.OUT_OF_OFFICE.CLEAR_OOO_TITLE' | translate }}</div>
        <div *appMessageCardDescription>{{ 'PROFILE.OUT_OF_OFFICE.CLEAR_OOO_BODY' | translate }}</div>
      </app-message-card>
    </div>

    <div class="d-flex">
      <div class="dateTime">
        <app-form-control [error]="startDateError$ | async">
          <span appFormControlLabel>{{ 'PROFILE.OUT_OF_OFFICE.START_DATE' | translate }}</span>
          <input
            appFormControlInput
            type="text"
            [matDatepicker]="startDatePicker"
            [formControl]="startDateControl"
            class="form-control-input"
            data-hj-allow
          />
          <mat-datepicker-toggle appFormControlInputSuffix [for]="startDatePicker">
            <svg-icon key="date" matDatepickerToggleIcon class="date-icon"></svg-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </app-form-control>

        <app-form-control [error]="startTimeError$ | async">
          <input
            appFormControlInput
            class="form-control-input"
            type="text"
            [formControl]="startTimeControl"
            [ngxTimepicker]="startTimePicker"
            [disableClick]="true"
            data-hj-allow
          />
          <ngx-material-timepicker-toggle appFormControlInputSuffix [for]="startTimePicker">
            <svg-icon key="time" ngxMaterialTimepickerToggleIcon></svg-icon>
          </ngx-material-timepicker-toggle>
          <ngx-material-timepicker #startTimePicker [appendToInput]="true"></ngx-material-timepicker>
        </app-form-control>
      </div>
    </div>

    <div class="dateTime">
      <app-form-control [error]="endDateError$ | async">
        <span appFormControlLabel>{{ 'PROFILE.OUT_OF_OFFICE.END_DATE' | translate }}</span>
        <input
          appFormControlInput
          type="text"
          [matDatepicker]="endDatePicker"
          [formControl]="endDateControl"
          class="form-control-input"
          data-hj-allow
        />
        <mat-datepicker-toggle appFormControlInputSuffix [for]="endDatePicker">
          <svg-icon key="date" matDatepickerToggleIcon class="date-icon"></svg-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </app-form-control>

      <app-form-control [error]="endTimeError$ | async">
        <input
          appFormControlInput
          class="form-control-input"
          type="text"
          [formControl]="endTimeControl"
          [ngxTimepicker]="endTimePicker"
          [disableClick]="true"
          data-hj-allow
        />
        <ngx-material-timepicker-toggle appFormControlInputSuffix [for]="endTimePicker">
          <svg-icon key="time" ngxMaterialTimepickerToggleIcon></svg-icon>
        </ngx-material-timepicker-toggle>
        <ngx-material-timepicker #endTimePicker [appendToInput]="true"></ngx-material-timepicker>
      </app-form-control>
    </div>

    <app-form-control class="col-10 px-0" [error]="messageError$ | async">
      <span appFormControlLabel>{{ 'PROFILE.OUT_OF_OFFICE.OOO_MESSAGE' | translate }}</span>
      <input [formControl]="messageControl" type="text" class="form-control-input" appFormControlInput />
    </app-form-control>

    <app-form-control *ngIf="tableData$ | async as tableData" class="col-10 px-0 mt-40" [error]="standbyUserError$ | async">
      <span appFormControlLabel class="d-flex"
        >{{ 'PROFILE.OUT_OF_OFFICE.STANDBY_USER' | translate }}
        <div class="ml-1">
          <svg-icon
            class="text-link md-dark font-weight-bold"
            key="info"
            appTooltipStrategy="hover"
            [appTooltip]="'PROFILE.OUT_OF_OFFICE.STANDBY_USER_INFO' | translate"
          ></svg-icon>
        </div>
      </span>
      <app-select [formControl]="standbyUserControl" [data]="tableData" appFormControlInput class="form-control-input"></app-select>
    </app-form-control>
  </div>
  <div class="dialog-footer">
    <div class="cross"></div>
    <div class="d-flex justify-content-center align-items-center button-container py-3 btn-save">
      <button mat-button class="secondary-button" (click)="resetOutOfOffice()">
        {{ 'PROFILE.OUT_OF_OFFICE.TURN_OFF' | translate }}
      </button>
      <button mat-button class="primary-button ml-4" [disabled]="form.invalid" (click)="setOutOfOffice()">
        {{ 'PROFILE.OUT_OF_OFFICE.SET_OOO' | translate }}
      </button>
    </div>
  </div>
</div>
