import { ValidatorFn } from '@angular/forms';

// tslint:disable:no-null-keyword

export const integerValidator: ValidatorFn = ({ value }) => {
  if (value == null || value === undefined || value.length === 0) {
    return null;
  }

  if (Number.isInteger(value)) {
    return null;
  }

  return { integer: true };
};
